/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';

export default class FlowsFinancingPayLaterApplicationSuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;

  @action
  goToTransfers() {
    this.segment.track('pay-later-signed_usage_clicked');
    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        origin: 'pay-later-application.success',
      },
    });
  }
}
