export default {
  "container": "cXJ",
  "form-container": "cXs",
  "title": "cXF",
  "title-border": "cXg",
  "tabs-container": "cXD",
  "preview-container": "cXV",
  "email-preview-container": "cXL",
  "close-button": "cXT btn btn--icon-only btn--tertiary btn--large"
};
