export default {
  "invitations-password-form": "clo",
  "mobile-mode": "cln",
  "logo": "cli",
  "header": "clA",
  "controls": "clY",
  "footer": "clh",
  "submit-cta": "clf",
  "subtitle": "clK"
};
