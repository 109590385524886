export default {
  "wrapper": "oj",
  "legend": "oW",
  "operation-code": "ou",
  "table": "nS",
  "footer": "nc",
  "footer-detraction": "nq",
  "footer-total-g": "nZ",
  "footer-total-h": "nR",
  "footer-net-g-h": "nQ"
};
