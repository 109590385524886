/* import __COLOCATED_TEMPLATE__ from './integer-text-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

const removeTrailingSpaces = value => value?.replace(/\s/g, '');

const UTILITY_KEYS = [
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Tab',
  'Delete',
  'Home',
  'End',
  'Enter',
  'Escape',
];

export default class FormFieldsIntegerTextField extends Component {
  get currentValue() {
    if (this.args.value) {
      let value = typeof this.args.value === 'number' ? String(this.args.value) : this.args.value;

      // double tap on space key produces a dot in input, to be on the safe side
      return value.replace(/\./g, '');
    } else {
      // in case the default value is zero (or any falsy value), we return null
      return null;
    }
  }

  get hasError() {
    if (typeof this.args.hasError === 'boolean') {
      return this.args.hasError;
    }

    let { errorMessage, errorLevel } = this.args;
    let hasMessage = false;

    // @message can potentially be a SafeString object when using the `htmlSafe=true` option in `intl.t()`
    // eg: `@message={{t "my.key" htmlSafe=true}}`
    if (errorMessage) {
      hasMessage =
        typeof errorMessage === 'string'
          ? errorMessage.length > 0
          : errorMessage.toString().length > 0;
    }

    return errorLevel === 'error' && hasMessage;
  }

  @action
  onKeyDown(event) {
    let isIntegerKey = /^[0-9]$/.test(event.key);
    let isUtilityKey = UTILITY_KEYS.includes(event.key);

    if (!isIntegerKey && !isUtilityKey) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  @action
  onInput(event) {
    let sanitizedValue = removeTrailingSpaces(event.target.value);

    this.args.onUpdate(sanitizedValue || null);
    return true;
  }
}
