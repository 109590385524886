/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import provinces from 'qonto/constants/it-provinces';
import taxRegimes from 'qonto/constants/it-tax-regimes';

export default class EinvoiceActivationFormComponent extends Component {
  @service intl;

  @tracked displayValidations = false;

  confirmTask = dropTask(async () => {
    await this.args.eInvoiceActivation.validate();
    this.displayValidations = true;
    if (this.args.eInvoiceActivation.validations.isValid) {
      await this.args.handleConfirmTask.perform();
    }
  });

  get data() {
    return {
      title: this.intl.t('receivable-invoices.italy.activation.title'),
      description: this.intl.t('receivable-invoices.italy.activation.subtitle'),
    };
  }

  get isReadonlyLegalTvaNumber() {
    let { legalTvaNumber: legalTvaNumberChanges } =
      this.args.eInvoiceActivation.changedAttributes();
    return !isEmpty(this.args.eInvoiceActivation.legalTvaNumber) && isEmpty(legalTvaNumberChanges);
  }

  get isReadonlyFiscalCode() {
    let { fiscalCode: fiscalCodeChanges } = this.args.eInvoiceActivation.changedAttributes();
    return !isEmpty(this.args.eInvoiceActivation.fiscalCode) && isEmpty(fiscalCodeChanges);
  }

  get selectedTaxRegimeOption() {
    return this.taxRegimeOptions.find(
      option => option.key === this.args.eInvoiceActivation.taxRegime
    );
  }

  get selectedProvinceOption() {
    return this.provinceOptions.find(
      option => option.key === this.args.eInvoiceActivation.provinceCode
    );
  }

  @cached
  get taxRegimeOptions() {
    return Object.entries(taxRegimes).map(([key, value]) => {
      return { key, value: `${key} - ${value}` };
    });
  }

  @action updateTaxRegimeOption({ key }) {
    this.args.eInvoiceActivation.taxRegime = key;
  }

  @cached
  get provinceOptions() {
    return provinces.map(key => ({
      key,
      value: `${key} - ${this.intl.t(`italy.provinces.${key.toLowerCase()}`)}`,
    }));
  }

  @action updateProvinceOption({ key }) {
    this.args.eInvoiceActivation.provinceCode = key;
  }

  @action handleClose() {
    this.args.close();
  }
}
