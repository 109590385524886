export default {
  "learn-more": "ccS",
  "discover-badge": "ccc",
  "role-selection-item": "ccq",
  "unavailable": "ccZ",
  "role-headline": "ccR",
  "illustration": "ccQ",
  "role-description": "cce",
  "description-item": "ccB",
  "description-icon": "ccE",
  "description-text": "ccd",
  "current-role": "ccI"
};
