export default {
  "close-button": "cFF",
  "content-wrapper": "cFg",
  "main": "cFD",
  "description": "cFV",
  "calculation-item": "cFL",
  "total-km": "cFT",
  "calculation-details": "cFz",
  "btn": "cFa"
};
