export default {
  "step-container": "je",
  "content": "jB",
  "detail": "jE",
  "secondary": "jd",
  "reference": "jI",
  "fees-label": "jt",
  "fees-tooltip": "jM",
  "separator": "jP",
  "mt-32": "jl"
};
