/* import __COLOCATED_TEMPLATE__ from './additional-settings.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { PAYMENT_PURPOSE_TYPES } from 'qonto/constants/transfers';
import { ErrorInfo } from 'qonto/utils/error-info';
import { getPaymentPurposeType } from 'qonto/utils/transfers';

export default class FlowsFxTransferAdditionalSetingsComponent extends Component {
  @service intl;
  @service segment;
  @service toastFlashMessages;
  @service store;
  @service sentry;
  @service abilities;
  @service organizationManager;

  @tracked enableValidation = false;
  @tracked enableNotifyEmailValidation = false;

  constructor() {
    super(...arguments);

    if (this.paymentPurposeType) {
      // for validation
      this.fxTransfer.paymentPurposeType = this.paymentPurposeType;
    }
  }

  get fxTransfer() {
    return this.args.context.fxTransfer;
  }

  get hasUploadTaskRunning() {
    return this.fxTransfer.attachmentsFiles.some(file => file.task?.isRunning);
  }

  get isTransferToChina() {
    return this.fxTransfer.country === 'CN';
  }

  get paymentPurposeCodes() {
    return this.args.context.paymentPurposeCodes;
  }

  get paymentPurposeType() {
    return getPaymentPurposeType(this.paymentPurposeCodes);
  }

  get isPaymentPurposeRequired() {
    return this.paymentPurposeType !== PAYMENT_PURPOSE_TYPES.NONE;
  }

  @action
  onChangePaymentPurpose(type, purpose) {
    if (type === PAYMENT_PURPOSE_TYPES.STRUCTURED) {
      this.fxTransfer.fxPaymentPurpose = purpose.code;
    } else if (type === PAYMENT_PURPOSE_TYPES.UNSTRUCTURED) {
      this.fxTransfer.fxPaymentPurpose = purpose;
    }
  }

  onClickNextTask = dropTask(async fxTransfer => {
    await fxTransfer.validate();

    this.enableValidation = true;
    this.enableNotifyEmailValidation = true;

    let { attachments, fxPaymentPurpose, email } = fxTransfer.validations.attrs;
    if (
      attachments.isInvalid ||
      fxPaymentPurpose.isInvalid ||
      (fxTransfer.notifyByEmail && email.isInvalid)
    ) {
      return;
    }

    if (!fxTransfer.notifyByEmail) {
      fxTransfer.email = fxTransfer.originalBeneficiaryEmail;
    }

    if (this.abilities.can('read budget')) {
      try {
        let results = await this.store.adapterFor('budget').search({
          initiatorId: this.organizationManager.membership.id,
          scheduledDate: fxTransfer.scheduledDate,
        });
        if (results.length) {
          this.args.context.budgetName = results[0].budget.name;
        }
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }

    try {
      this.args.context.confirmationResult = await fxTransfer.confirmFx();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      // don't display toast for validation errors
      if (fxTransfer.isValid) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }

      return;
    }

    let hasLabels = Boolean(fxTransfer.labels.length);
    let hasNote = Boolean(fxTransfer.note);
    let hasEmail = Boolean(fxTransfer.email);
    let { origin } = this.args.context;

    this.segment.track('transfer-swift_additional-information_submitted', {
      ...(hasLabels && { labels: hasLabels }),
      ...(hasNote && { note: hasNote }),
      ...(hasEmail && { email: hasEmail }),
      ...(origin && { origin }),
    });

    this.args.transitionToNext();
  });
}
