export default {
  "card": "GD",
  "asset": "GV",
  "container": "GL",
  "subtitle": "GT",
  "header": "Gz",
  "header-name": "Ga",
  "option-list": "GH",
  "feature": "GO",
  "highlighted": "Gm"
};
