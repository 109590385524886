/* import __COLOCATED_TEMPLATE__ from './value-proposition.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class PayLaterApplicationValuePropositionComponent extends Component {
  @service deviceManager;
  @service intl;
  @service sentry;
  @service segment;
  @service financing;
  @service localeManager;
  @service toastFlashMessages;
  @service theme;

  get assetsLocale() {
    return this.localeManager.locale || 'en';
  }

  get assetsTheme() {
    return this.theme.isCurrentThemeDark ? 'dark' : 'light';
  }

  get imagePath() {
    if (variation('feature--boolean-pay-later-flexible-installments')) {
      return `/illustrations/financing/pay-later/value-prop-flexible-installments/value-prop-${this.assetsLocale}.webp`;
    }

    return `/illustrations/financing/pay-later/value-prop/value-prop-${this.assetsTheme}-${this.assetsLocale}.webp`;
  }

  get videoPath() {
    if (variation('feature--boolean-pay-later-flexible-installments')) {
      return `/videos/financing/pay-later/value-prop-flexible-installments/value-prop-${this.assetsLocale}.webm`;
    }

    return `/videos/financing/pay-later/value-prop/value-prop-${this.assetsTheme}-${this.assetsLocale}.webm`;
  }

  requestPayLaterSignatureTask = dropTask(async () => {
    try {
      let { url } = await this.financing.requestPayLaterSignature();
      this.args.context.signature.url = url;
      this.args.transitionToNext();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(
        this.intl.t('financing.pay-later.application-flow.value-proposition.error-toast')
      );
    }

    this.segment.track('pay-later-value_sign-contract_clicked');
  });
}
