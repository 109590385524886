export default {
  "wrapper": "cPL",
  "content": "cPT",
  "back": "cPz btn btn--tertiary",
  "heading": "cPa",
  "heading-content": "cPH",
  "logo": "cPO",
  "description": "cPm body-2",
  "cta": "cPp"
};
