/* import __COLOCATED_TEMPLATE__ from './benefits-list.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BENEFITS_LIST_WARNINGS } from 'qonto/constants/subscriptions';

export default class FlowsSubscriptionChangeBenefitsListComponent extends Component {
  @service intl;
  @service segment;
  @service modals;
  @service subscriptionManager;

  constructor() {
    super(...arguments);
    this.segment.track('change_plan_lose_benefits_displayed');
  }

  @action
  transitionToNext() {
    this.segment.track('change_plan_lose_benefits_clicked');

    if (this.args.context.errors?.length) {
      this.openBlockerModal();
    } else {
      this.args.transitionToNext();
    }
  }

  @action
  onKeepCurrentPlan() {
    this.segment.track('change_plan_keep_benefits_clicked');
    this.args.transitionToPrevious();
  }

  openBlockerModal() {
    let { errors, subscription } = this.args.context;
    let userLimitReachError = errors.find(err => err.code === 'user_limit_reached');
    let membershipsToRevokeCount =
      userLimitReachError?.additional_data?.memberships_to_revoke_count;

    this.modals.open('subscription/blockers-modal', {
      membershipsToRevokeCount,
      isFullScreenModal: true,
      errors,
      subscription,
    });

    this.segment.track('plans_blockers_displayed', {
      current_plan: this.args.context.currentPricePlanCode,
      target_plan: this.args.context.subscription.pricePlan.get('code'),
      blockers: errors.map(it => it.code),
    });
  }

  get warnings() {
    return this.args.context.warnings
      .map(warning => this.warningTranslations[warning.code])
      .filter(Boolean);
  }

  get warningTranslations() {
    let oldPlan = this.subscriptionManager.currentPricePlan;
    let newPlan = this.args.context.subscription.pricePlan;
    let sepaPrice = newPlan.get('optionsObject').sepaOutboundTransfer?.value;

    return {
      [BENEFITS_LIST_WARNINGS.ADVERTISING_CARDS_DISALLOWED]: {
        icon: 'feature_physical_card-m',
        text: this.intl.t('subscription.change.confirmation.warnings.advertising'),
      },
      [BENEFITS_LIST_WARNINGS.FEATURE_BOOKKEEPING_DISABLED]: {
        icon: 'feature_label-m',
        text: this.intl.t('subscription.change.confirmation.warnings.bookkeeping'),
      },
      [BENEFITS_LIST_WARNINGS.FLASH_CARDS_DISALLOWED]: {
        icon: 'feature_flash_card-m',
        text: this.intl.t('subscription.change.confirmation.warnings.flash'),
      },
      [BENEFITS_LIST_WARNINGS.MANAGER_ROLE_DISALLOWED]: {
        icon: 'feature_user-m',
        text: this.intl.t('subscription.change.confirmation.warnings.manager'),
      },
      [BENEFITS_LIST_WARNINGS.INCLUDED_TRANSFERS_DECREASE]: {
        icon: 'feature_transfer_recurring-m',
        text: this.intl.t('subscription.change.confirmation.warnings.transfers-decrease', {
          count1: oldPlan.sepaOutLimit,
          count2: newPlan.get('sepaOutLimit'),
          transferPrice: this.intl.formatNumber(sepaPrice, {
            displayUndefined: true,
            currency: this.args.context.estimatedPrice.currency,
            style: 'currency',
          }),
        }),
      },
      [BENEFITS_LIST_WARNINGS.INCLUDED_CHECKS_DECREASE]: {
        icon: 'feature_check-m',
        text: this.intl.t('subscription.change.confirmation.warnings.checks-decrease', {
          count1: oldPlan.checkLimit,
          count2: newPlan.get('checkLimit'),
        }),
      },
      [BENEFITS_LIST_WARNINGS.FEATURE_REQUESTS_DISABLED]: {
        icon: 'feature_request-m',
        text: this.intl.t('subscription.change.confirmation.warnings.pending-requests'),
      },
      [BENEFITS_LIST_WARNINGS.FEATURE_SUPPLIER_INVOICES_PENDING_DISABLED]: {
        icon: 'feature_supplier_invoice-m',
        text: this.intl.t(
          'subscription.change.confirmation.warnings.supplier-invoices-pending-requests'
        ),
      },
      [BENEFITS_LIST_WARNINGS.CONNECT_EBICS_INTEGRATIONS_DISABLED]: {
        icon: 'feature_connect-m',
        text: this.intl.t('subscription.change.confirmation.warnings.connect.ebics'),
      },
      [BENEFITS_LIST_WARNINGS.CONNECT_PLUS_INTEGRATIONS_DISABLED]: {
        icon: 'feature_connect-m',
        text: this.intl.t('subscription.change.confirmation.warnings.connect.api-integrations', {
          htmlSafe: true,
        }),
      },
    };
  }
}
