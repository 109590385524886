/* import __COLOCATED_TEMPLATE__ from './choose-card-digital.hbs'; */
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { Badge } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';

import { CARD_LEVELS, CARD_LEVELS_TRACKING, ORIGIN } from 'qonto/constants/cards';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsCardsChooseVirtualCard extends Component {
  badge = Badge;

  @service cardsManager;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service organizationManager;
  @service subscriptionManager;
  @service segment;
  @service sentry;

  CARD_LEVELS = CARD_LEVELS;

  get activeTrialRemainingDays() {
    return this.args.context.currentSubscription?.activeTrialRemainingDays;
  }

  get currentPricePlanRecurrence() {
    return this.args.context.currentSubscription?.recurrence;
  }

  get hasInitialTrial() {
    return this.args.context.currentSubscription?.hasInitialTrial;
  }

  get hasInitialTrialUpsell() {
    return this.args.context.currentSubscription?.hasInitialTrialUpsell;
  }

  chooseVirtualTask = dropTask(async () => {
    await this.chooseCardTask.perform(CARD_LEVELS.VIRTUAL).catch(ignoreCancelation);
  });

  chooseAdvertisingTask = dropTask(async () => {
    await this.chooseCardTask.perform(CARD_LEVELS.ADVERTISING).catch(ignoreCancelation);
  });

  chooseFlashTask = dropTask(async () => {
    await this.chooseCardTask.perform(CARD_LEVELS.FLASH).catch(ignoreCancelation);
  });

  chooseCardTask = dropTask(async cardLevel => {
    let {
      bankAccount,
      card,
      cardsLimits,
      estimates,
      flowTrackingOrigin,
      hasMultipleActiveAccounts,
      origin,
    } = this.args.context;

    try {
      // when coming back from the subscription change flow, we have to re-fetch the card costs which where initially missing (like the Instant card costs)
      // (this cannot be handled in the beforeFlow hook of the flow, because it's not re-triggered when coming back to a primary flow)
      if (!estimates[cardLevel]) {
        let { organization } = this.organizationManager;
        let cardsCosts = await this.cardsManager.fetchCardsCostsTask.perform(organization.id);
        estimates = cardsCosts.estimates;
        set(this.args.context, 'estimates', estimates);
      }

      if (bankAccount.authorizedBalance < estimates[cardLevel].amount) {
        let singleMessage = this.intl.t('cards.new.choose-type.balance-warning.single');
        let multiMessage = this.intl.t('cards.new.choose-type.balance-warning.multi', {
          accountName: bankAccount.name,
        });

        let toastMessage = hasMultipleActiveAccounts ? multiMessage : singleMessage;
        this.toastFlashMessages.toastError(toastMessage);
        return null;
      }

      let limits = cardsLimits[cardLevel];

      if (cardLevel === CARD_LEVELS.FLASH) {
        card.setProperties({
          cardLevel,
          paymentLifespanLimit: Math.max(...limits.payment_lifespan_limit),
          preExpiresAt: dayjs().endOf('day').toDate(),
        });
      } else {
        card.setProperties({
          cardLevel,
          paymentMonthlyLimit: limits.payment_monthly_limit_maximum,
        });
      }

      this.segment.track('cards_order_virtual_type_chosen', {
        card_type: CARD_LEVELS_TRACKING[cardLevel],
        origin: flowTrackingOrigin,
      });

      if (hasMultipleActiveAccounts) {
        return this.args.pushForwardFlow(`card-new-${cardLevel}`, 'bank-account');
      }

      let stepId = 'holder';

      if (origin === ORIGIN.MEMBER_INVITATION) {
        switch (cardLevel) {
          case CARD_LEVELS.VIRTUAL:
            stepId = 'restrictions';
            break;
          case CARD_LEVELS.FLASH:
            stepId = 'payment-lifespan-limit';
            break;
          case CARD_LEVELS.ADVERTISING:
            stepId = 'payment-limits';
            break;
        }
      }

      this.args.pushForwardFlow(`card-new-${cardLevel}`, stepId);
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });

  @action
  flashUpsell() {
    let { pricePlan } = this.args.context;
    let { organization } = this.organizationManager;

    this.segment.track('upsell_flashcard_clicked', {
      price_plan: pricePlan.code,
      country: organization.legalCountry,
    });

    this.args.context.upsellTrigger = 'flashCard';
    this.args.pushFlow('subscription-change', 'discover-teams');
  }

  flashTrialTask = dropTask(async () => {
    let { code: recommendedPricePlanCode } =
      await this.subscriptionManager.upgradeRecommendation('flashCard');

    this.args.transitionToFlow({
      flowName: 'subscription-change',
      stepId: 'plans',
      queryParams: {
        plan: this.args.context.currentSubscription ? recommendedPricePlanCode : null,
        recurrence: this.currentPricePlanRecurrence,
        isInitialTrialUpsell: true,
      },
    });
  });

  @action
  handleOpenOnlineAdFeaturesModal() {
    this.modals.open('card/modals/online-ad-features', {
      isFullScreenModal: true,
    });
    this.segment.track('cards_order_advertising_card_more_info');
  }
}
