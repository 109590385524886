/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import Component from '@glimmer/component';
import { SubscriptionHeader, CheckoutHeader } from '@repo/domain-kit';
import { service } from '@ember/service';
import { type ContextArg, type EstimatedPrice, type EstimationWarning } from './dataContext.type';

export default class FlowsAddonChangeConfirmComponent extends Component {
  @service intl!: any;
  @service networkManager!: any;
  @service sentry!: any;
  @service subscriptionManager!: any;

  subscriptionHeader = SubscriptionHeader;
  checkoutHeader = CheckoutHeader;

  get addon(): EstimatedPrice {
    return (this.args as ContextArg).context.estimatedPrices?.find(
      (ep: EstimatedPrice) => ep.product_kind === 'addon'
    )!;
  }

  get pricePlan(): EstimatedPrice | undefined {
    return (this.args as ContextArg).context.estimatedPrices?.find(
      (ep: EstimatedPrice) => ep.product_kind === 'core'
    );
  }

  get warnings(): EstimationWarning[] {
    return (this.args as ContextArg).context?.warnings || [];
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription.hasInitialTrial;
  }

  get initialTrialDate() {
    return this.intl.formatDate(this.subscriptionManager.currentSubscription.activeTrial.end_date, {
      month: 'short',
      day: '2-digit',
    });
  }
}
