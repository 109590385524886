export default {
  "mt-16": "cFI",
  "title": "cFt",
  "edit-organization-form": "cFM",
  "beside-block": "cFP",
  "info-circle": "cFl",
  "hollow": "cFX",
  "filled": "cFC",
  "radio": "cFk",
  "cancel-button": "cFJ",
  "error-message": "cFs"
};
