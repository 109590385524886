export default {
  "body": "csM",
  "members": "csP",
  "members-results": "csl",
  "members-list": "csX",
  "members-title": "csC caption-bold",
  "member": "csk",
  "empty-illustration": "csJ",
  "empty-title": "css title-3",
  "empty-description": "csF body-2",
  "invitable-members": "csg",
  "details": "csD",
  "pagination-footer": "csV"
};
