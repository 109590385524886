export default {
  "members-loading": "csU",
  "members-loading--ff": "csj",
  "header": "csW",
  "header-search-bar": "csu",
  "body": "cFS",
  "members": "cFc",
  "placeholder-container": "cFq",
  "icon": "cFZ",
  "details": "cFR",
  "member-loading": "cFQ",
  "member-loading--no-ff": "cFe",
  "body-members": "cFB",
  "body-details": "cFE"
};
