/* import __COLOCATED_TEMPLATE__ from './frequency.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { RECURRENCE } from 'qonto/constants/invoice-subscriptions';

export default class InvoiceSubscriptionsFrequency extends Component {
  @service intl;

  get frequency() {
    let { value, recurrence } = this.args.frequency || {};

    if (value > 1) {
      return this.intl.t('recurring-invoices.list.table.row.cell.custom-frequency', {
        number: value,
        frequency: recurrence,
      });
    }

    switch (recurrence) {
      case RECURRENCE.MONTHLY:
        return this.intl.t('recurring-invoices.frequency.option.monthly');
      case RECURRENCE.WEEKLY:
        return this.intl.t('recurring-invoices.frequency.option.weekly');
      case RECURRENCE.QUARTERLY:
        return this.intl.t('recurring-invoices.frequency.option.quarterly');
      case RECURRENCE.YEARLY:
        return this.intl.t('recurring-invoices.frequency.option.yearly');
    }
  }
}
