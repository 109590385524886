export default {
  "guests-loading": "cIr",
  "header": "cIb",
  "body": "cIy",
  "members": "cIU",
  "title": "cIj caption-bold",
  "placeholder-container": "cIW",
  "icon": "cIu",
  "details": "ctS"
};
