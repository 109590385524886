export default {
  "wrapper": "cZW",
  "wrapper-revamp": "cZu",
  "container": "cRS",
  "container-revamp": "cRc",
  "warning-label": "cRq",
  "subtitle-container": "cRZ",
  "card": "cRR",
  "disclaimer": "cRQ",
  "list": "cRe",
  "list-item": "cRB body-2",
  "header-button": "cRE"
};
