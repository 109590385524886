/* import __COLOCATED_TEMPLATE__ from './reason-category.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { variation } from 'ember-launch-darkly';

import { shuffleArray } from 'qonto/utils/shuffle-array';

export default class SubscriptionCloseStepsReasonComponent extends Component {
  @service segment;

  reasonList = [];

  constructor() {
    super(...arguments);

    let { context } = this.args;
    // the wizard component now uses ensure-safe-component
    // which requires a check before calling next()
    // to avoid triggering an infinite loop which causes
    // tests to timeout
    if (
      context.closingReasonCategory !== null ||
      context.closingReasonSubCategory !== null ||
      context.closingReasonDetails !== null ||
      context.closingReasonDetailsOther !== null
    ) {
      // without next throws re-validation error
      next(() => {
        context.closingReasonCategory = null;
        context.closingReasonSubCategory = null;
        context.closingReasonDetails = null;
        context.closingReasonDetailsOther = null;
      });
    }

    if (variation('feature--account-closing-ab-test')) {
      this.reasonList = shuffleArray(context.reasonList);
      this.segment.track('account-closing_reasons-screen_opened', {
        reasons_list: 'randomised',
      });
    } else {
      this.reasonList = context.reasonList;
      this.segment.track('account-closing_reasons-screen_opened', {
        reasons_list: 'standard',
      });
    }
  }

  @action selectReasonCategory(item) {
    this.args.context.closingReasonCategory = item;
    this.segment.track('account_closing_category_selected', {
      category: item?.key,
    });
    this.args.transitionToNext();
  }
}
