export default {
  "row": "hV",
  "cell": "hL",
  "cell-content": "hT",
  "supplier": "hz",
  "next-installment": "ha",
  "installment-amount": "hH",
  "total-repayment": "hO",
  "cell-details": "hm",
  "align-right": "hp",
  "empty": "hx"
};
