/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsChargebackClaimUploadComponent extends Component {
  @service modals;
  @service intl;
  @service organizationManager;
  @service segment;

  get uploadOptions() {
    return {
      url: 'v1/claims/documents',
      payload: {
        headers: {
          'X-Qonto-Organization-Id': this.organizationManager.organization?.id,
        },
      },
    };
  }

  get fileExtensions() {
    return '.png,.pdf,.jpeg,.jpg';
  }

  @action
  addLocalFile(file) {
    this.args.files.push(file);
  }

  @action
  deleteLocalFile(file) {
    let { files } = this.args;
    file.deleteRecord();
    let index = files.indexOf(file);
    files.splice(index, 1);
  }

  @action
  handlePreviewFile(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      selectedFile: file,
      deletable: false,
    });
  }
}
