export default {
  "journey": "cqp",
  "content": "cqx",
  "distance": "cqw",
  "google-link-container": "cqN",
  "link-icon": "cqv",
  "continue-btn": "cqo",
  "origin": "cqn",
  "input-width": "cqi"
};
