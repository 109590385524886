export default {
  "container": "rq",
  "container-metal": "rZ",
  "content": "rR",
  "button": "rQ",
  "embossed-selector": "re",
  "carousel-container": "rB",
  "carousel": "rE",
  "asset": "rd",
  "poster": "rI",
  "video-embossed": "rt",
  "video": "rM",
  "active": "rP",
  "embossed": "rl",
  "safari": "rX",
  "poster-open": "rC"
};
