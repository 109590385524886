export default {
  "details": "cqd",
  "disclaimer": "cqI",
  "data": "cqt",
  "confirm": "cqM",
  "form": "cqP",
  "textarea": "cql",
  "preview": "cqX",
  "form-title": "cqC title1",
  "form-subtitle": "cqk title2",
  "divider": "cqJ",
  "submit": "cqs",
  "doc": "cqF",
  "amount-input-error": "cqg"
};
