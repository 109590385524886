/* import __COLOCATED_TEMPLATE__ from './french-invoice-fields.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FrenchInvoiceFieldsComponent extends Component {
  @service segment;

  @action
  updateDiscountConditions(text) {
    this.args.settings.discountConditions = text;
    this.segment.track('invoice-creation_additional-details_discount-conditions_edited');
  }

  @action
  updateLatePaymentPenalties(text) {
    this.args.settings.latePaymentPenalties = text;
    this.segment.track('invoice-creation_additional-details_late-payment-penalties_edited');
  }

  @action
  updateLegalFixedCompensation(text) {
    this.args.settings.legalFixedCompensation = text;
    this.segment.track('invoice-creation_additional-details_legal-fixed-compensation_edited');
  }
}
