/* import __COLOCATED_TEMPLATE__ from './contact.hbs'; */
import { ForbiddenError, InvalidError } from '@ember-data/adapter/error';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { task } from 'ember-concurrency';
import { alias } from 'macro-decorators';

import { CONCIERGE_CHANNELS } from 'qonto/constants/concierge';

export default class FlowsConciergeContact extends Component {
  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service userManager;
  @service sentry;

  CONCIERGE_CHANNELS = CONCIERGE_CHANNELS;

  @alias('userManager.currentUser.email') contactEmail;
  @alias('userManager.currentUser.phoneNumber') contactPhoneNumber;

  get hasTimeDisclaimer() {
    let hourInParis = dayjs().tz('Europe/Paris').hour();
    return hourInParis < 9 || hourInParis >= 21;
  }

  submitTask = task(async () => {
    try {
      let { conciergeRequest } = this.args.context;
      await conciergeRequest.send();
      this.segment.track('concierge_request_sent');
      this.args.transitionToNext();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (error instanceof ForbiddenError || error instanceof InvalidError) {
        this.sentry.captureException(error);
      }
    }
  });
}
