export default {
  "form": "AP",
  "fieldset": "Al",
  "form-body": "AX",
  "conditional-select": "AC",
  "conditional-text": "Ak",
  "group": "AJ",
  "add-filter-button": "As btn btn--tertiary",
  "footer": "AF",
  "btn-icon": "Ag",
  "add-icon": "AD",
  "cancel-button": "AV btn",
  "form-header": "AL",
  "align-start": "AT",
  "align-right": "Az",
  "button-wrapper": "Aa",
  "wrapper": "AH",
  "label": "AO body-2",
  "input-wrapper": "Am",
  "input": "Ap input-field body-2",
  "error": "Ax",
  "error-message": "Aw"
};
