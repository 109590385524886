/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';

import { CARD_LEVELS } from 'qonto/constants/cards';

export default class FlowsRequestsCardsSuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service organizationManager;
  @service intl;
  @service router;

  get displayKYCDisclaimer() {
    let isFlash = this.args.context.cardLevel === CARD_LEVELS.FLASH;
    let { kycRefused } = this.organizationManager.membership;
    return kycRefused && isFlash;
  }

  get isVirtualCard() {
    return this.args.context.cardLevel === CARD_LEVELS.VIRTUAL;
  }

  get isVirtualCardWithPendingKyc() {
    let { kycSubmitted, kycPending } = this.organizationManager.membership;

    return this.isVirtualCard && kycSubmitted && kycPending;
  }

  get title() {
    return this.isVirtualCardWithPendingKyc
      ? this.intl.t('kyc.on-demand.requests.success.title')
      : this.intl.t('requests.cards.steps.success.title');
  }

  get subtitle() {
    return this.isVirtualCardWithPendingKyc
      ? this.intl.t('kyc.on-demand.requests.success.text')
      : this.intl.t('requests.cards.steps.success.subtitle');
  }

  @action
  handleSuccessClick() {
    this.router.transitionTo('requests.landing', { queryParams: { origin: 'request_success' } });
  }
}
