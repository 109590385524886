/* import __COLOCATED_TEMPLATE__ from './modal-transition.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

import { Motion, rAF, Tween, wait } from 'ember-animated';
import { easeIn, easeOut } from 'ember-animated/easings/cosine';
import linear from 'ember-animated/easings/linear';
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { modifier } from 'ember-modifier';
import window from 'ember-window-mock';

class Freeze extends Motion {
  *animate() {
    let { duration } = this;
    this.tween = new Tween(0, 1, duration, linear);
    while (!this.tween.done) {
      yield rAF();
    }
  }
}

class LockScroll extends Motion {
  *animate() {
    let { duration } = this;
    this.tween = new Tween(0, 1, duration, linear);
    let initialOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    while (!this.tween.done) {
      if (window.scrollX || window.scrollY) {
        window.scrollTo(0, 0);
      }
      yield rAF();
    }
    document.body.style.overflow = initialOverflow;
  }
}

export default class ModalTransitionComponent extends Component {
  @service router;
  @service menu;

  lockScroll = modifier(element => {
    let scrollToTop = () => element.scrollTo(0, 0);
    element.addEventListener('scroll', scrollToTop);
    return () => element.removeEventListener('scroll', scrollToTop);
  });

  state = {
    id: this.args.id || this.router.currentRouteName,
    role: this.args.role,
  };

  @action
  *transition({ insertedSprites, removedSprites, duration }) {
    let insertedModals = insertedSprites.filter(sprite => sprite.owner.value.role === 'modal');
    let removedModals = removedSprites.filter(sprite => sprite.owner.value.role === 'modal');
    let removedBackdrops = removedSprites.filter(sprite => sprite.owner.value.role === 'backdrop');

    if (this.args.allowedDestinations?.includes(this.router.currentRouteName)) {
      removedBackdrops.forEach(sprite => {
        new Freeze(sprite).run();
      });
    }

    insertedModals.forEach(sprite => {
      sprite.startTranslatedBy(0, window.innerHeight);
      sprite.applyStyles({ 'z-index': 'var(--z-index-above)' });
      move(sprite, { easing: easeOut });
      fadeIn(sprite, { easing: easeOut });
      new LockScroll(sprite).run();
    });

    removedModals.forEach(sprite => {
      sprite.endTranslatedBy(0, window.innerHeight);
      sprite.applyStyles({ 'z-index': 'var(--z-index-above)' });
      move(sprite, { easing: easeIn });
      fadeOut(sprite, { easing: easeIn });
    });

    if (insertedModals.length) {
      yield wait(duration);
      if (this.menu.isVisible) {
        this.menu.hide();
      }
    } else if (removedModals.length) {
      if (!this.menu.isVisible) {
        this.menu.show();
      }
    }
  }
}
