export default {
  "fieldset": "cXc",
  "subscription-period": "cXq",
  "automatic-number-section": "cXZ",
  "automatic-number-label": "cXR",
  "icon-tooltip": "cXQ",
  "date-picker": "cXe",
  "label": "cXB",
  "header-text-field": "cXE",
  "header-text-field-container": "cXd",
  "header-text-field-add-button": "cXI btn btn--tertiary",
  "header-text-field-close-button": "cXt btn btn--icon-only btn--tertiary btn--small",
  "read-only-frequency": "cXM",
  "read-only": "cXP"
};
