export default {
  "step-container": "cQr",
  "content": "cQb",
  "beneficiary": "cQy",
  "beneficiary-name": "cQU",
  "trusted": "cQj",
  "amount-disclaimer": "cQW",
  "purpose-subtitle": "cQu",
  "purposes": "ceS",
  "purpose-error": "cec",
  "sticky-panel": "ceq"
};
