/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { SubscriptionSuccess } from '@repo/domain-kit';

export default class SubscriptionChangeSuccessComponent extends Component {
  subscriptionSuccess = SubscriptionSuccess;

  @action
  onClick() {
    this.args.transitionToNext();
  }
}
