/* import __COLOCATED_TEMPLATE__ from './role-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class RoleSelectionComponent extends Component {
  @service segment;
  @service memberManager;

  @action showComparison() {
    let { context, transitionToNext } = this.args;
    context.showComparison = true;
    if (context.isEditingMember) {
      this.segment.track('member_role_update_compare');
    } else {
      this.segment.track('team-invite_role-comparison_viewed');
    }
    transitionToNext();
  }
}
