export default {
  "container": "ru",
  "disabled-option": "bS",
  "content": "bc",
  "spinner": "bq",
  "subtitle": "bZ",
  "card-panel": "bR",
  "card-icon": "bQ",
  "card-description": "be",
  "details-table": "bB",
  "caption": "bE",
  "tooltip-icon": "bd",
  "tooltip-icon-right": "bI",
  "card-monthly-cost": "bt",
  "card-monthly-discounted-cost": "bM",
  "strikethrough": "bP",
  "purple-text": "bl",
  "budget-label": "bX",
  "card-cost-label": "bC",
  "card-billing-account": "bk"
};
