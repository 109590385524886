export default {
  "card": "ckc",
  "focus": "ckq",
  "title-format": "ckZ",
  "header": "ckR",
  "headings": "ckQ",
  "title": "cke title-4",
  "main": "ckB title-3",
  "footer": "ckE"
};
