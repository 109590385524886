/* import __COLOCATED_TEMPLATE__ from './selector.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';

export default class CardStepsChooseCardSelector extends Component {
  @service intl;
  @service abilities;

  get isMetalCard() {
    return this.args.cardLevel === CARD_LEVELS.METAL;
  }

  get isOneCard() {
    return this.args.cardLevel === CARD_LEVELS.STANDARD;
  }

  get isFreeOneCard() {
    return this.isOneCard && this.args.isOneCardIncluded;
  }

  get insuranceLinkLabel() {
    let { cardLevel } = this.args;

    switch (cardLevel) {
      case CARD_LEVELS.METAL:
        return this.intl.t('cards.steps.choose-card.features.insurance.metal');
      case CARD_LEVELS.PLUS:
        return this.intl.t('cards.steps.choose-card.features.insurance.plus');
      case CARD_LEVELS.STANDARD:
        return this.intl.t('cards.steps.choose-card.features.insurance.standard');
      default:
        return null;
    }
  }
}
