/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';

export default class FlowsCheckSuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;
  @service intl;

  @action restart() {
    this.segment.track('checks_deposit_new_check');
    this.args.restartFlow();
  }

  @action handleNext() {
    this.segment.track('checks_deposit_close');
    this.args.transitionToNext();
  }

  get shippingAddress() {
    return (
      this.args.context.check.shippingAddress ?? this.intl.t('checks.success.check_send_address')
    );
  }
}
