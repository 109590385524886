/* import __COLOCATED_TEMPLATE__ from './invoice-upload.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { getCurrentParisDateString } from 'qonto/utils/date';

export default class FlowsTransfersSepaNewInvoiceUploadComponent extends Component {
  @service errors;
  @service payByInvoiceUploadManager;
  @service segment;
  @service store;
  @service abilities;

  get isPayLaterLimitationsVisible() {
    let { isPayLaterContractSigned, isPayLaterEligible, isDedicatedFlow, origin } =
      this.args.context;
    let payLaterOrigins = [TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT, TRANSFER_FLOW_ORIGIN.OVERVIEW];

    return (
      this.abilities.can('create pay later transfer in financing') &&
      isPayLaterContractSigned &&
      isPayLaterEligible &&
      payLaterOrigins.includes(origin) &&
      !isDedicatedFlow
    );
  }

  @action
  onInvoiceSelected() {
    let { context, transitionToNext } = this.args;
    let { origin } = context;

    this.segment.track('transfer-sepa_invoice_confirmed', {
      ...(origin && { origin }),
    });

    transitionToNext();
  }

  @action
  onInvoiceUploaded(invoiceId) {
    let { context, transitionToNext } = this.args;
    let { invoice, origin, sepaTransfer, defaultOperationType } = context;

    if (invoice?.id !== invoiceId) {
      let inputProperties = { operationType: defaultOperationType };
      this._resetTransfer(sepaTransfer, inputProperties);
      this._attachInvoiceToTransfer(invoiceId);

      this.segment.track('transfer-sepa_invoice_uploaded', {
        ...(origin && { origin }),
      });

      transitionToNext();
    }
  }

  @action
  onUploadError() {
    let { origin } = this.args.context;
    this.segment.track('transfer-sepa_invoice_uploaded', {
      ...(origin && { origin }),
    });
  }

  deleteInvoiceTask = dropTask(async invoice => {
    try {
      await invoice.destroyRecord();
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  onRemoveFileTask = dropTask(async invoiceId => {
    let { context } = this.args;
    let { origin } = context;

    this.segment.track('transfer-sepa_invoice_deleted', {
      ...(origin && { origin }),
    });

    let invoice = this.store.peekRecord('supplier-invoice', invoiceId);
    if (invoice) {
      await this.deleteInvoiceTask.perform(invoice);
    }

    this.payByInvoiceUploadManager.resetState();

    context.invoice = null;
    context.sepaTransfer.attachments = [];
  });

  _attachInvoiceToTransfer(invoiceId) {
    let { context } = this.args;

    let invoice = this.store.peekRecord('supplier-invoice', invoiceId);

    context.invoice = invoice;
    context.sepaTransfer.attachments = [invoice.attachment];
  }

  _resetTransfer(transfer, inputProperties) {
    this._resetTransferDetails(transfer, inputProperties);
    this._resetTransferBeneficary(transfer);
  }

  _resetTransferDetails(transfer, inputProperties) {
    transfer.setProperties({
      activityTag: 'other_expense',
      amount: 0,
      attachments: [],
      email: null,
      notifyByEmail: false,
      reference: null,
      scheduledDate: getCurrentParisDateString(),
      standingEndingDate: null,
      standingOn: null,
      ...inputProperties,
    });
  }

  _resetTransferBeneficary(transfer) {
    transfer.beneficiary = null;
  }
}
