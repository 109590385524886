/* import __COLOCATED_TEMPLATE__ from './choose-card-onboarding.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { waitForProperty } from 'ember-concurrency';
import window from 'ember-window-mock';

import { CARD_LEVELS_TRACKING, CUSTOMIZABLE_CARDS_LEVELS } from 'qonto/constants/cards';

export default class FlowsCardsChooseCardOnboarding extends Component {
  @service abilities;
  @service organizationManager;
  @service payment;
  @service router;
  @service segment;
  @service theme;

  @tracked isVideoIntroFinished = false;

  INTRO_VIDEO_ID = `${guidFor(this)}-intro-video`;

  constructor() {
    super(...arguments);
    this.segment.track('cards_order_onboarding_landing');
    if (this.shouldPlayVideoIntro) {
      this.initVideoIntro();
    }
  }

  get shouldPlayVideoIntro() {
    return this.args.context.shouldAnimateCards && !this.isVideoIntroFinished;
  }

  async initVideoIntro() {
    await waitForProperty(this, 'INTRO_VIDEO_ID');

    if (macroCondition(isTesting())) {
      this.isVideoIntroFinished = true;
    }

    let hasReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    if (hasReducedMotion) {
      this.isVideoIntroFinished = true;
    }

    // This is to prevent video from not playing
    // because of browsers being overzealous with sound and autoplay
    document.getElementById(this.INTRO_VIDEO_ID).muted = true;
  }

  @action
  setVideoIntroAsFinished() {
    this.isVideoIntroFinished = true;
  }

  @action
  chooseCard(cardLevel) {
    let { context, transitionToNext } = this.args;
    let { card, cardsLimits, flowTrackingOrigin } = context;

    let { payment_monthly_limit_maximum, atm_monthly_limit_maximum } = cardsLimits[cardLevel];

    card.setProperties({
      atmMonthlyLimit: atm_monthly_limit_maximum,
      cardLevel,
      paymentMonthlyLimit: payment_monthly_limit_maximum,
    });

    this.segment.track('cards_order_physical_type_chosen', {
      origin: flowTrackingOrigin,
      card_type: CARD_LEVELS_TRACKING[cardLevel],
    });

    context.shouldAnimateCards = false;
    context.showCardCustomization = CUSTOMIZABLE_CARDS_LEVELS.includes(cardLevel);

    card.setProperties({
      cardDesign: null,
      typeOfPrint: null,
    });

    transitionToNext(context);
  }

  @action
  onSkipToTopupClick() {
    this.segment.track('onboarding_cards_skip_to_top_up');
    this.payment.onboardingCardSkipped = true;
    this.organizationManager.flagMembershipOnboarded();

    this.router.transitionTo(
      'onboarding.topup.amount',
      this.args.context.card.organization.get('id')
    );
  }
}
