export default {
  "summary-description": "cBr",
  "detail": "cBb",
  "detail-value": "cBy",
  "account-icon": "cBU",
  "color-secondary": "cBj",
  "beneficiary-info": "cBW",
  "currency": "cBu",
  "beneficiary-name": "cES",
  "separator": "cEc",
  "instant-subtitle": "cEq",
  "instant-wrapper": "cEZ",
  "instant-header": "cER",
  "instant-toggle": "cEQ",
  "instant--disabled": "cEe",
  "limit-exceeded-disclaimer": "cEB",
  "sticky-panel": "cEE",
  "total-interest": "cEd",
  "tooltip-icon": "cEI",
  "tooltip": "cEt",
  "payment-options": "cEM"
};
