import type { EmptyObject } from '@glimmer/component/-private/component';

export type EstimatedPrice = {
  product_id: string;
  product_kind: 'core' | 'addon';
  product_code: string;
  product_name: string;
  recurrence: 'monthly' | 'annual';
  next_billing_date: Date;
  next_recurrence_date: Date;
  target_subscription_prorated_amount: Amount;
};

export type Amount = {
  value: number;
  currency: string;
};

export type EstimationWarning = {
  code: string;
  detail: string;
  additional_data: {};
};

export type DataContext = {
  estimatedPrices: EstimatedPrice[];
  hasInsufficientFunds: boolean;
  warnings: EstimationWarning[];
};

export type ContextArg = {
  context: DataContext;
} & EmptyObject;

export const defaultContext: DataContext = {
  estimatedPrices: [
    {
      product_kind: 'addon',
      product_code: 'accounts_receivable',
      recurrence: 'monthly',
      product_name: 'Accounts Receivable',
      product_id: '',
      next_billing_date: new Date(),
      next_recurrence_date: new Date(),
      target_subscription_prorated_amount: {
        value: 49,
        currency: 'EUR',
      },
    },
    {
      product_kind: 'core',
      product_code: 'solo_basic',
      recurrence: 'monthly',
      product_name: 'Basic',
      product_id: '',
      next_billing_date: new Date(),
      next_recurrence_date: new Date(),
      target_subscription_prorated_amount: {
        value: 120,
        currency: 'EUR',
      },
    },
  ],
  hasInsufficientFunds: true,
  warnings: [
    {
      code: 'current_plan_disabled',
      detail: 'Current plan is disabled',
      additional_data: {},
    },
  ],
};
