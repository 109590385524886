export default {
  "wrapper": "ng",
  "legend": "nD",
  "input": "nV",
  "table": "nL",
  "footer": "nT",
  "footer-total-m": "nz",
  "footer-total-n": "na",
  "footer-balance": "nH"
};
