export default {
  "wrapper": "uh",
  "promotion-title": "uf",
  "comparison-table": "uK",
  "table-head": "uG",
  "title-wrapper": "ur",
  "illustration-wrapper": "ub",
  "illustration": "uy",
  "role-wrapper": "uU",
  "role-disabled": "uj",
  "role-name": "uW",
  "role-access": "uu",
  "disabled-caption": "cSS",
  "table-head-sticky": "cSc",
  "show-border": "cSq",
  "group-row": "cSZ",
  "table-body": "cSR",
  "group": "cSQ",
  "table-body-row": "cSe",
  "feature-description": "cSB body-2",
  "feature": "cSE body-2",
  "group-spacer": "cSd"
};
