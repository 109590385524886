export default {
  "cell": "Yr",
  "empty": "Yb",
  "supplier": "Yy",
  "supplier-content": "YU",
  "supplier-avatar": "Yj mr-16",
  "installments": "YW body-2",
  "overflow-hidden": "Yu",
  "ellipsis": "hS",
  "next-installment-date": "hc",
  "next-installment-amount": "hq body-2",
  "amount": "hZ",
  "disclaimer": "hR",
  "disclaimer-icon": "hQ",
  "total-repayment": "he body-1"
};
