export default {
  "item": "cJb",
  "selected": "cJy",
  "hover-revised": "cJU",
  "asset-container": "cJj",
  "asset": "cJW",
  "revoked-icon": "cJu",
  "pending-icon": "csS",
  "revoked": "csc",
  "revised-revoke": "csq",
  "pending": "csZ",
  "revised-pending": "csR",
  "invitable": "csQ",
  "info": "cse",
  "name": "csB",
  "email": "csE",
  "actions": "csd",
  "invitable-member-delete": "csI",
  "invitable-member-invite": "cst"
};
