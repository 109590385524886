export default {
  "container": "clQ",
  "form": "cle",
  "form-header": "clB",
  "form-subtitle": "clE",
  "form-input": "cld",
  "left-input": "clI",
  "right-input": "clt",
  "label-fix": "clM",
  "x-validated-input__header": "clP",
  "form-dropzone": "cll",
  "form-footer": "clX",
  "submit-cta": "clC",
  "checklist": "clk",
  "about-you-form": "clJ",
  "name-inputs": "cls",
  "birth-inputs": "clF"
};
