export default {
  "container": "cdH",
  "container--collapsed": "cdO",
  "spin": "cdm",
  "cta-tooltip": "cdp",
  "cta--collapsed": "cdx",
  "cta--expanded": "cdw",
  "collapsed-svg-icon": "cdN",
  "expanded-svg-icon": "cdv",
  "generate-logo-cta": "cdo",
  "badge": "cdn",
  "scaleDown": "cdi",
  "fadeIn": "cdA",
  "textRiseButton": "cdY",
  "cta-description": "cdh",
  "textRiseDescription": "cdf"
};
