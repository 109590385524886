export default {
  "container": "Km",
  "back-button": "Kp",
  "card-list": "Kx",
  "upsell-badge": "Kw",
  "pricing": "KN",
  "pricing-period": "Kv",
  "ad-modal-button": "Ko",
  "eligibility-disclaimer": "Kn",
  "feature": "Ki",
  "highlighted": "KA"
};
