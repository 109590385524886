export default {
  "color-option": "cdM",
  "color-dot": "cdP",
  "any": "cdl",
  "red": "cdX",
  "orange": "cdC",
  "yellow": "cdk",
  "green": "cdJ",
  "blue": "cds",
  "purple": "cdF",
  "pink": "cdg",
  "black": "cdD",
  "grey": "cdV",
  "brown": "cdL",
  "color-name": "cdT"
};
