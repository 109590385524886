/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';

export default class SuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service abilities;
  @service intl;
  @service segment;
  @service router;

  get isReviewer() {
    return this.abilities.can('review mileage request');
  }

  get titleLabel() {
    if (this.isReviewer) {
      return this.intl.t('requests.mileage.steps.success-admin.title');
    }

    return this.intl.t('requests.mileage.steps.success.title');
  }

  get subtitleLabel() {
    if (this.isReviewer) {
      return this.intl.t('requests.mileage.steps.success-admin.subtitle');
    }

    return this.intl.t('requests.mileage.steps.success.subtitle');
  }

  @action
  handleCloseClick() {
    let { transitionToNext } = this.args;

    transitionToNext();
  }

  @action
  handleSuccessClick() {
    this.router.transitionTo('requests.landing', {
      queryParams: { origin: 'mileage_request' },
    });
  }

  @action
  handleReviewRequest() {
    this.segment.track('request_review_clicked', { request_type: 'mileage' });

    this.router.transitionTo('reimbursements.pending', {
      queryParams: { highlight: this.args.context.requestMileage.id },
    });
  }
}
