export default {
  "card": "cIC",
  "text-container": "cIk",
  "text-title": "cIJ title-3",
  "text-subtitle": "cIs body-1",
  "text-cta": "cIF body-1",
  "text-cta-icon": "cIg",
  "image": "cID",
  "image-illustration": "cIV"
};
