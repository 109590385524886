/* import __COLOCATED_TEMPLATE__ from './success-reset-pin.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer } from 'design-system-kit';

export default class FlowsCardsSuccessResetPin extends Component {
  lottiePlayer = LottiePlayer;

  @tracked animationReady = false;

  @action displayLottie() {
    // see https://github.com/emberjs/ember.js/issues/17202#issuecomment-437927226
    this.animationReady = true;
  }
}
