export default {
  "container": "cPM",
  "logo": "cPP",
  "title": "cPl title-4",
  "description": "cPX body-2",
  "metadata": "cPC",
  "label": "cPk caption",
  "risks": "cPJ",
  "tag": "cPs tag"
};
