export default {
  "wrapper": "ne",
  "subtitle": "nB body-2",
  "legend": "nE",
  "table": "nd",
  "footer": "nI",
  "footer-total-c": "nt",
  "footer-total-d": "nM",
  "footer-balance": "nP"
};
