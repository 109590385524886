export default {
  "body-loading": "ckG",
  "body-members": "ckr",
  "body-details": "ckb",
  "body-details-title": "cky",
  "icon": "ckU",
  "body-details-header": "ckj",
  "body-details-ids-subtitle": "ckW",
  "body-details-overview-subtitle": "cku",
  "body-details-overview-content": "cJS",
  "body-details-ctas": "cJc",
  "body-details-ctas-left": "cJq",
  "body-details-ctas-right": "cJZ",
  "right-edit": "cJR"
};
