export default {
  "container": "rD",
  "centered": "rV",
  "content": "rL",
  "spinner": "rT",
  "holder-selector": "rz",
  "holder-selector-error": "ra",
  "info-text": "rH",
  "error-text": "rO",
  "lottie-illustration": "rm",
  "info-text-1": "rp",
  "info-text-2": "rx"
};
