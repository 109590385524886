/* import __COLOCATED_TEMPLATE__ from './settlement.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { all, dropTask, race, rawTimeout, task } from 'ember-concurrency';

import { TRANSFER_EVENTS } from 'qonto/constants/listeners';
import { STATUS as REQUEST_STATUS } from 'qonto/constants/requests';
import {
  DISCLAIMER_TYPES,
  INSTANT_DECLINED_REASONS_RETRY_STATUS,
  INSTANT_SETTLEMENT_MAXIMAL_WAITING_TIME_MS,
  INSTANT_SETTLEMENT_MINIMAL_WAITING_TIME_MS,
  SEPA_TRACKING_SETTLEMENT_CTA,
  SEPA_TRACKING_SETTLEMENT_EVENTS,
  SEPA_TRACKING_TRANSFER_TYPE,
  STATUS,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
import cloneProperties from 'qonto/utils/clone-properties';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { createSepaSettlementTrackingEventPayload } from 'qonto/utils/transfers';

export default class FlowsTransfersSepaNewSettlementComponent extends Component {
  @service intl;
  @service notifierManager;
  @service payByInvoiceUploadManager;
  @service router;
  @service segment;
  @service store;
  @service flow;
  @service organizationManager;

  @tracked status = this._getSynchronisedInitialStatus();
  @tracked declinedReason = this._getSynchronisedDeclinedReason();

  constructor() {
    super(...arguments);

    if (this.isInstantTransferProcessing) {
      this.handleInstantSettlementPhaseTask.perform().catch(ignoreCancelation);
    }
  }

  get isKycSubmittedButNotYetValidated() {
    let { kycPending, kycSubmitted } = this.organizationManager.membership;

    return kycPending && kycSubmitted;
  }

  get canInstantTransferBeRetried() {
    let { declinedReason } = this;
    let hasDefinedRetryStatus =
      INSTANT_DECLINED_REASONS_RETRY_STATUS.hasOwnProperty(declinedReason);

    return hasDefinedRetryStatus ? INSTANT_DECLINED_REASONS_RETRY_STATUS[declinedReason] : true;
  }

  get closeButtonClass() {
    let hasPrimaryButton =
      this.shouldDisplayMakeAnotherTransferButton ||
      this.shouldDisplayRetryTransferButton ||
      this.shouldDisplaySeeRequestDetailsButton;
    return hasPrimaryButton ? 'btn btn--tertiary' : 'btn btn--primary';
  }

  get hasQontoBeneficiaryWarning() {
    return this.args.context.confirmationResult?.warnings?.includes(
      DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT
    );
  }

  get isInstantTransferDeclined() {
    return this.sepaTransfer.instant && this.status === STATUS.DECLINED;
  }

  get isInstantTransferProcessing() {
    return this.sepaTransfer.instant && this.status === STATUS.PROCESSING;
  }

  get isInstantTransferSettled() {
    return this.sepaTransfer.instant && this.status === STATUS.COMPLETED;
  }

  get isTransferRequestSuccessful() {
    return this.transferRequest && this.status === REQUEST_STATUS.PENDING;
  }

  get isInstantTransferTimeout() {
    return this.sepaTransfer.instant && this.status === 'timeout';
  }

  get isTransferSuccessful() {
    return !this.transferRequest && this.status === STATUS.COMPLETED;
  }

  get sepaTransfer() {
    return this.args.context.sepaTransfer;
  }

  get shouldDisplaySeeTransferDetailsButton() {
    return this.isInstantTransferSettled;
  }

  get shouldDisplayCloseButton() {
    return !(this.isInstantTransferProcessing || this.shouldDisplaySeeTransferDetailsButton);
  }

  get shouldDisplayGoToPayLaterButton() {
    return this.sepaTransfer.isPayLater && this.isTransferSuccessful;
  }

  get shouldDisplayMakeAnotherTransferButton() {
    return (
      (this.isTransferSuccessful || this.isInstantTransferDeclined) &&
      !this.shouldDisplayRetryTransferButton
    );
  }

  get shouldDisplayRetryTransferButton() {
    return this.isInstantTransferDeclined && this.canInstantTransferBeRetried;
  }

  get shouldDisplaySeeRequestDetailsButton() {
    return this.isTransferRequestSuccessful;
  }

  get transferRequest() {
    return this.args.context.transferRequest;
  }

  get makeAnotherButtonText() {
    if (this.args.context.isDedicatedFlow) {
      return this.intl.t('transfers.modals.finance-another-btn');
    }

    return this.intl.t('transfers.modals.make-another-btn');
  }

  handleInstantSettlementPhaseTask = dropTask(async () => {
    let timeout = this.waitForInstantTimeoutTask.perform();
    let transferSettled = this.waitForDelayedSettlementTask.perform();

    let { event, payload } = await race([transferSettled, timeout]);

    if (event === TRANSFER_EVENTS.INSTANT_TIMEOUT) {
      await this.handleInstantTimeoutEventTask.perform(this.sepaTransfer.id);
    }

    if (event === TRANSFER_EVENTS.INSTANT_DECLINED) {
      this.status = STATUS.DECLINED;
      this.declinedReason = payload?.object?.declined_reason;
    }

    if (event === TRANSFER_EVENTS.INSTANT_SETTLED) {
      this.status = STATUS.COMPLETED;
    }

    // We sync the transfer settlement result with the flow context so that
    // the `onComplete` action knows about it
    this.args.context.settlementResult = {
      status: this.status,
      declinedReason: this.declinedReason,
    };
  });

  handleInstantTimeoutEventTask = dropTask(async transferId => {
    let transfer = await this.store.findRecord('transfer', transferId);

    if (transfer.isCompleted) {
      this.status = STATUS.COMPLETED;
    } else if (transfer.isDeclined) {
      this.status = STATUS.DECLINED;
      this.declinedReason = transfer.declinedReason;
    } else {
      this.status = 'timeout';
    }
  });

  handleRetryAsRegularTask = dropTask(async (confirmTransferTask, declinedInstantTransfer) => {
    let { backToStep, context } = this.args;
    let { origin } = context;

    // Replace the current transfer record by a new one on the data context
    // The new record is a clone of the previous one and will be used for the fallback creation
    context.sepaTransfer = this.store.createRecord('transfer');
    await cloneProperties(declinedInstantTransfer, this.sepaTransfer);

    let fallbackConfirmationResult = await confirmTransferTask.perform(this.sepaTransfer);

    if (fallbackConfirmationResult) {
      context.isInstantFallback = true;

      this.segment.track(SEPA_TRACKING_SETTLEMENT_EVENTS.REJECT_CLOSED, {
        transfer_type: SEPA_TRACKING_TRANSFER_TYPE.INSTANT,
        transfer_success_redirection: SEPA_TRACKING_SETTLEMENT_CTA.FALLBACK,
        ...(origin && { origin }),
      });

      backToStep('summary');
    }
  });

  waitForDelayedSettlementTask = task(async () => {
    let waitForSuccessEventTask = this.notifierManager.waitForEventTask.perform(
      TRANSFER_EVENTS.INSTANT_SETTLED
    );

    let waitForDeclinedEventTask = this.notifierManager.waitForEventTask.perform(
      TRANSFER_EVENTS.INSTANT_DECLINED
    );

    let isTransferSettledTask = race([waitForSuccessEventTask, waitForDeclinedEventTask]);

    let delay = rawTimeout(INSTANT_SETTLEMENT_MINIMAL_WAITING_TIME_MS);

    let [settlementEvent] = await all([isTransferSettledTask, delay]);

    return settlementEvent;
  });

  waitForInstantTimeoutTask = task(async () => {
    await rawTimeout(INSTANT_SETTLEMENT_MAXIMAL_WAITING_TIME_MS);
    return { event: TRANSFER_EVENTS.INSTANT_TIMEOUT };
  });

  @action
  handleClose() {
    let { invoice, origin, supplierInvoiceId, sepaTransfer } = this.args.context;

    let eventName;

    if (this.isInstantTransferDeclined) {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.REJECT_CLOSED;
    } else if (this.isInstantTransferTimeout) {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.TIMEOUT_CLOSED;
    } else {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.SUCCESS_CLOSED;
    }

    let payload = createSepaSettlementTrackingEventPayload({
      invoice,
      origin,
      sepaTransfer,
      settlementStatus: this.status,
      cta: SEPA_TRACKING_SETTLEMENT_CTA.CLOSE,
      isQontoBeneficiary: sepaTransfer.get('beneficiary.qontoBankAccount'),
      declinedReason: this.declinedReason,
      isKYCPending: this.organizationManager.membership.kycPending,
    });

    this.segment.track(eventName, payload);

    this.payByInvoiceUploadManager.resetState();

    if (origin === TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT) {
      return this.router.transitionTo(this.flow.refererPage);
    }

    if (
      this.isInstantTransferSettled ||
      this.isInstantTransferDeclined ||
      this.hasQontoBeneficiaryWarning
    ) {
      return this._redirect({
        url: 'transfers.past',
        origin,
        sepaTransfer,
        supplierInvoiceId,
      });
    }

    return this._redirect({
      url: 'transfers.pending',
      origin,
      sepaTransfer,
      supplierInvoiceId,
    });
  }

  @action
  handleMakeAnotherTransfer() {
    let { invoice, origin, sepaTransfer } = this.args.context;
    let eventName;

    if (this.isInstantTransferDeclined) {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.REJECT_CLOSED;
    } else {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.SUCCESS_CLOSED;
    }

    let payload = createSepaSettlementTrackingEventPayload({
      invoice,
      origin,
      sepaTransfer,
      settlementStatus: this.status,
      cta: SEPA_TRACKING_SETTLEMENT_CTA.NEW_TRANSFER,
      isQontoBeneficiary: sepaTransfer.get('beneficiary.qontoBankAccount'),
      declinedReason: this.declinedReason,
    });

    this.segment.track(eventName, payload);
    this.payByInvoiceUploadManager.resetState();

    if (this.args.context.isDedicatedFlow) {
      this.args.restartFlow();
    } else {
      this.router.transitionTo('transfers.landing');
    }
  }

  @action
  handleSeeRequestDetails() {
    let { invoice, origin, transferRequest, sepaTransfer } = this.args.context;

    let payload = createSepaSettlementTrackingEventPayload({
      invoice,
      origin,
      sepaTransfer,
      settlementStatus: this.status,
      cta: SEPA_TRACKING_SETTLEMENT_CTA.SEE_REQUEST,
      isQontoBeneficiary: sepaTransfer.get('beneficiary.qontoBankAccount'),
      declinedReason: this.declinedReason,
    });

    this.segment.track(SEPA_TRACKING_SETTLEMENT_EVENTS.SUCCESS_CLOSED, payload);
    this.router.transitionTo('transfers.requests', {
      queryParams: { highlight: transferRequest.id },
    });
  }

  @action
  handleSeeTransferDetails() {
    let { invoice, origin, sepaTransfer } = this.args.context;

    let payload = createSepaSettlementTrackingEventPayload({
      invoice,
      origin,
      sepaTransfer,
      settlementStatus: this.status,
      cta: SEPA_TRACKING_SETTLEMENT_CTA.TRANSFER_DETAILS,
    });

    this.segment.track(SEPA_TRACKING_SETTLEMENT_EVENTS.SUCCESS_CLOSED, payload);
    this.payByInvoiceUploadManager.resetState();

    this.router.transitionTo('transfers.past', {
      queryParams: {
        highlight: sepaTransfer.id,
      },
    });
  }

  _getSynchronisedDeclinedReason() {
    let declinedReason = this.sepaTransfer.declinedReason;

    // We sync the transfer settlement result with the flow context so that
    // the `onComplete` action knows about it
    this.args.context.settlementResult.declinedReason = declinedReason;

    return declinedReason;
  }

  _getSynchronisedInitialStatus() {
    let transferStatus;

    if (this.transferRequest) {
      transferStatus = REQUEST_STATUS.PENDING;
    } else {
      let { instant, status } = this.sepaTransfer;

      if (status === STATUS.COMPLETED) {
        transferStatus = STATUS.COMPLETED;
      } else {
        transferStatus =
          instant && status === STATUS.PROCESSING ? STATUS.PROCESSING : STATUS.COMPLETED;

        if (instant) {
          transferStatus = status === STATUS.DECLINED ? STATUS.DECLINED : STATUS.PROCESSING;
        }
      }
    }

    // We sync the transfer settlement result with the flow context so that
    // the `onComplete` action knows about it
    this.args.context.settlementResult.status = transferStatus;

    return transferStatus;
  }

  _redirect({ url, origin, sepaTransfer, supplierInvoiceId }) {
    if (origin && supplierInvoiceId) {
      return this.router.transitionTo(origin, {
        queryParams: {
          transferStatus: sepaTransfer.status,
          supplierInvoiceId,
        },
      });
    }

    return this.router.transitionTo(url, {
      queryParams: {
        ...(origin === TRANSFER_FLOW_ORIGIN.EDIT && { highlight: this.sepaTransfer.id }),
      },
    });
  }
}
