export default {
  "wrapper": "oi",
  "subtitle": "oA body-2",
  "legend": "oY",
  "table": "oh",
  "footer": "of",
  "footer-codice-1": "oK",
  "footer-codice-2": "oG",
  "footer-total-a": "or",
  "footer-total-b": "ob",
  "footer-balance": "oy",
  "errors": "oU"
};
