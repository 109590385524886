/* import __COLOCATED_TEMPLATE__ from './invitations-password-form.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { Spinner } from 'design-system-kit';
import { dropTask, waitForQueue } from 'ember-concurrency';

import UpdatePasswordComponent from 'qonto/components/update-password-form';
import { ROLES } from 'qonto/constants/membership';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class InvitationsPasswordForm extends UpdatePasswordComponent {
  spinner = Spinner;

  @tracked isNumberValid = false;
  @service segment;
  @service sessionManager;
  @service intl;
  @service zendeskLocalization;

  @tracked phoneNumber;

  ROLES = ROLES;

  phoneInputId = `phone-input-${guidFor(this)}`;

  constructor() {
    super(...arguments);

    if (this.args.isMobile) {
      return;
    }

    this.focusInput.perform().catch(ignoreCancelation);
  }

  submitPasswordTask = dropTask(
    waitFor(async user => {
      user.phoneNumber = this.phoneNumber;
      await this.args.submitPasswordWithUser(user);
      this.segment.track('join_team_phone_and_password_confirmed', {
        role: this.args.invite.role,
        invitation_id: this.args.invite.id,
      });
    })
  );

  focusInput = dropTask(async () => {
    try {
      await waitForQueue('afterRender');

      // The <input /> element is not created by XPhoneInput but by the intl-tel-input library
      // the library appends it to the DOM after the didInsertElement event of XPhoneInput.
      // By scheduling the focus to the next runloop, we make sure that the input element has
      // finished rendering before applying focus to it.
      next(() => document.getElementById(this.phoneInputId).querySelector('input').focus());
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  @action
  handlePhoneNumberUpdate(number, { isValidNumber }) {
    this.isNumberValid = isValidNumber;
    this.phoneNumber = number;
  }

  get subtitle() {
    let { invite } = this.args;

    if (invite.kycRequired) {
      return this.intl.t('join-team.welcome.fourthline.admin-or-employee.subtitle');
    } else if (invite.role === this.ROLES.ADMIN || invite.role === this.ROLES.MANAGER) {
      return this.intl.t('join-team.welcome.admin-or-employee.subtitle', {
        htmlSafe: true,
        faqUrl: this.zendeskLocalization.getLocalizedArticle(4359525),
      });
    } else {
      return this.intl.t('join-team.mobile.kyc.personal-info.subtitle');
    }
  }
}
