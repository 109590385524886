/* import __COLOCATED_TEMPLATE__ from './add-beneficiary.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import CURRENCIES from 'qonto/constants/currencies';

export default class FlowsTransfersFxBeneficiaryAddBeneficiaryComponent extends Component {
  @service store;
  @service organizationManager;

  @tracked beneficiary;

  constructor() {
    super(...arguments);
    this.beneficiary = this.store.createRecord('beneficiary', {
      country: 'US',
      currency: CURRENCIES.defaultFX,
      vatRate: null,
      organization: this.organizationManager.organization,
      activityTag: 'other_expense',
      fx: true,
    });
  }

  @action
  transitionToNext(newBeneficiary) {
    let { context, transitionToNext } = this.args;
    context.beneficiary = newBeneficiary;
    transitionToNext();
  }
}
