/* import __COLOCATED_TEMPLATE__ from './budget.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { CARDS_BOTTOM_LIMIT } from 'qonto/constants/requests';

export default class FlowsRequestsCardsBudgetComponent extends Component {
  @service intl;
  @service segment;
  @tracked errorMessage;

  constructor() {
    super(...arguments);
    this.sendTrackingEvent();
  }

  sendTrackingEvent() {
    this.segment.track('request_creation_budget_displayed', {
      request_type: `${this.args.context.cardLevel} card`,
    });
  }

  @action
  updateAmount(amount) {
    this.errorMessage = this.validateAmount(amount);

    this.amount = amount;
  }

  get amount() {
    let { cardLevel, entity } = this.args.context;
    let { paymentLifespanLimit, paymentMonthlyLimit } = entity;

    if (cardLevel === CARD_LEVELS.FLASH) {
      return paymentLifespanLimit;
    }

    return paymentMonthlyLimit;
  }

  set amount(value) {
    let { cardLevel, entity } = this.args.context;

    if (cardLevel === CARD_LEVELS.FLASH) {
      entity.paymentLifespanLimit = value;
    }

    entity.paymentMonthlyLimit = value;
  }

  validateAmount(amount) {
    let { cardLevel, cardMaxLimit } = this.args.context;

    if (!amount || !amount.trim()) {
      return this.intl.t('validations.errors.presence');
    }

    if (amount > cardMaxLimit) {
      let formattedAmount = this.formatTranslationAmount(cardMaxLimit);
      return cardLevel === CARD_LEVELS.FLASH
        ? this.intl.t('requests.creation.spending-limit.error.too-high', {
            amount: formattedAmount,
          })
        : this.intl.t('requests.creation.monthly-limit.error.too-high', {
            amount: formattedAmount,
          });
    }

    if (amount < CARDS_BOTTOM_LIMIT) {
      let formattedAmount = this.formatTranslationAmount(CARDS_BOTTOM_LIMIT);
      return cardLevel === CARD_LEVELS.FLASH
        ? this.intl.t('requests.creation.spending-limit.error.too-low', {
            amount: formattedAmount,
          })
        : this.intl.t('requests.creation.monthly-limit.error.too-low', {
            amount: formattedAmount,
          });
    }

    return null;
  }

  formatTranslationAmount(amount) {
    return this.intl.formatNumber(amount, {
      minimumFractionDigits: 0,
      currency: 'EUR',
      style: 'currency',
    });
  }

  get header() {
    let { cardLevel } = this.args.context;

    if (cardLevel === CARD_LEVELS.FLASH) {
      return this.intl.t('requests.cards.steps.budget.flash.title');
    }

    return this.intl.t('requests.cards.steps.budget.virtual.title');
  }

  get inputLabel() {
    let { cardLevel } = this.args.context;

    if (cardLevel === CARD_LEVELS.FLASH) {
      return this.intl.t('requests.cards.steps.budget.flash.label');
    }

    return this.intl.t('requests.cards.steps.budget.virtual.label');
  }

  @action
  continue(event) {
    event.preventDefault();

    this.errorMessage = this.validateAmount(this.amount);
    if (this.errorMessage) {
      return;
    }

    this.args.transitionToNext();
  }
}
