export default {
  "wrapper": "cdK",
  "close-button": "cdG",
  "content-wrapper": "cdr",
  "main": "cdb",
  "main-content": "cdy",
  "description": "cdU",
  "mt-26": "cdj",
  "mt-62": "cdW",
  "user-prompt": "cdu",
  "user-prompt-inappropriate-warning": "cIS",
  "logo-preview": "cIc",
  "static-loader": "cIq",
  "loading-state-illustration": "cIZ",
  "sticky-panel": "cIR"
};
