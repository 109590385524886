/* import __COLOCATED_TEMPLATE__ from './add-funds.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

export default class AddFunds extends Component {
  @service intl;
  @service organizationManager;

  @reads('organizationManager.organization') organization;
  @reads('organization.activeSortedAccounts') activeSortedAccounts;

  @tracked amountErrorMessage = null;

  @tracked accountToDebit = this.args.context.accountToDebit;
  @tracked amount = null;
  @tracked accountToCredit = this.args.context.accountToCredit;

  get accountList() {
    return this.activeSortedAccounts.filter(({ isRemunerated }) => !isRemunerated);
  }

  get selectedAccountBalance() {
    return this.accountToDebit.balance;
  }

  @action
  handleSetAccount(account) {
    this.accountToDebit = account;

    this.validateAmount();
  }

  @action
  handleUpdateAmount(value) {
    this.amount = value;
    this.validateAmount();
  }

  validateAmountExists() {
    if (!this.amount) {
      this.amountErrorMessage = this.intl.t(
        'accounts.funding.remunerated.account.error.amount-empty'
      );
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmountAuthorizedBalance() {
    if (Number(this.amount) > this.selectedAccountBalance) {
      this.amountErrorMessage = this.intl.t(
        'accounts.funding.remunerated.account.error.amount-above',
        { price: this._formatPrice(this.selectedAccountBalance) }
      );
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmountMinimum() {
    if (Number(this.amount) <= 0) {
      this.amountErrorMessage = this.intl.t('validations.errors.transfer.amount');
      return false;
    }
    this.amountErrorMessage = null;
    return true;
  }

  validateAmount() {
    return (
      this.validateAmountExists() &&
      this.validateAmountAuthorizedBalance() &&
      this.validateAmountMinimum()
    );
  }

  _formatPrice(price) {
    return this.intl.formatNumber(price, {
      minimumFractionDigits: 0,
      currency: 'EUR',
      style: 'currency',
    });
  }

  @action
  handleNext() {
    let validAmount = this.validateAmount();
    if (validAmount) {
      this.args.context.accountToDebit = this.accountToDebit;
      this.args.context.amount = this.amount;
      this.args.transitionToNext();
    }
  }
}
