export default {
  "container": "ceb",
  "content": "cey",
  "sticky": "ceU",
  "divider": "cej",
  "tooltip": "ceW",
  "detail": "ceu",
  "detail-value": "cBS",
  "color-secondary": "cBc",
  "beneficiary-information": "cBq",
  "beneficiary-source": "cBZ",
  "flag": "cBR",
  "currency": "cBQ",
  "break-word": "cBe",
  "disclaimer": "cBB",
  "sticky-panel": "cBE"
};
