/* import __COLOCATED_TEMPLATE__ from './side-drawer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import { BUCKET_SLUG } from 'qonto/constants/in-context-integrations';
import { Header } from 'qonto/react/components/in-context-integrations/side-drawer/header';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class InContextIntegrationsSideDrawer extends Component {
  Header = Header;

  @service connectManager;
  @service organizationManager;
  @service userManager;
  @service sentry;
  @service segment;

  @tracked buckets = [];
  @tracked selectedBucketSlug = window.sessionStorage.getItem(BUCKET_SLUG);
  @tracked isLoading = true;
  @tracked hasError = false;

  @reads('organizationManager.organization') organization;

  constructor() {
    super(...arguments);
    this.setData();
  }

  async setData() {
    try {
      let { results: buckets } = await this.connectManager.getIntegrationBuckets({
        location: this.args.sideDrawerId,
      });

      this.buckets = await Promise.all(
        buckets.map(async bucket => {
          let { results: integrations } = await this.connectManager.getIntegrations({
            bucketId: bucket.id,
            includeBeta: true,
          });

          return {
            ...bucket,
            items: integrations.map(integration => ({ prismicData: integration.data })),
          };
        })
      );

      if (this.buckets.length === 1) {
        this.selectedBucketSlug = this.buckets[0].slug;
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.hasError = true;
    }

    this.isLoading = false;
  }

  get displayIntegrations() {
    return this.buckets.length === 1 || this.selectedBucket;
  }

  get displayBackArrow() {
    return this.buckets.length > 1 && this.selectedBucket;
  }

  get selectedBucket() {
    return this.buckets.find(bucket => bucket.slug === this.selectedBucketSlug) || null;
  }

  @action
  back() {
    this.selectedBucketSlug = null;
    window.sessionStorage.removeItem(BUCKET_SLUG);
    this.segment.track('ICI_previous_clicked', {
      page: this.args.sideDrawerId,
    });
  }

  @action
  showIntegrations(bucket) {
    this.selectedBucketSlug = bucket.slug;
    window.sessionStorage.setItem(BUCKET_SLUG, bucket.slug);
    this.segment.track('ICI_bucket_clicked', {
      page: this.args.sideDrawerId,
      bucket: bucket.title,
    });
  }

  @action
  onClose() {
    if (this.buckets.length !== 1) {
      this.selectedBucketSlug = null;
    }
    window.sessionStorage.removeItem(BUCKET_SLUG);
    this.args.onClose();
  }
}
