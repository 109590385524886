/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';

export default class SuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;
  @service router;

  @action
  handleCloseClick() {
    let { transitionToNext } = this.args;

    this.segment.track('reimbursement_flow_complete_close');

    transitionToNext();
  }

  @action
  handleSuccessClick() {
    this.router.transitionTo('requests.landing', {
      queryParams: { origin: 'reimbursement_request' },
    });
  }
}
