/* import __COLOCATED_TEMPLATE__ from './compact-header.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { PROVIDERS_MAP } from 'qonto/constants/insurance-hub';

export default class InsuranceHubOfferCompactHeaderComponent extends Component {
  @service segment;
  @service organizationManager;

  @action
  onClick() {
    let { product, onClick } = this.args;
    let country = this.organizationManager.organization.legalCountry;

    this.segment.track('insurance-hub-offer-details_subscription-button_clicked', {
      country,
      product,
      provider: PROVIDERS_MAP[country],
    });

    onClick?.();
  }
}
