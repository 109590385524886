/* import __COLOCATED_TEMPLATE__ from './pricing.hbs'; */
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';

export default class CardStepsPricingOnboarding extends Component {
  get cardEstimates() {
    let { cardLevel, estimates } = this.args;
    return estimates?.[cardLevel];
  }

  get standardCardOptionPrice() {
    let { optionsPrices } = this.args;

    return optionsPrices?.[CARD_LEVELS.STANDARD]?.value;
  }

  get monthlyCost() {
    return this.cardEstimates.monthly_cost;
  }

  get discountedMonthlyCost() {
    return this.cardEstimates.discounted_monthly_cost;
  }

  get discountFinishDate() {
    return this.cardEstimates.discount_finish_date;
  }

  get hasDiscount() {
    return (
      this.cardEstimates.discount_finish_date && this.monthlyCost !== this.discountedMonthlyCost
    );
  }

  get isOneCardFree() {
    let { cardLevel, isOneCardIncluded } = this.args;
    return cardLevel === CARD_LEVELS.STANDARD && isOneCardIncluded;
  }
}
