export default {
  "page-wrapper": "jy",
  "container": "jU",
  "content": "jj",
  "showElement": "jW",
  "illustration": "ju",
  "fadeIn": "WS",
  "scaleDown": "Wc",
  "error-content": "Wq",
  "error-illustration": "WZ"
};
