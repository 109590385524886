export default {
  "container": "cev",
  "content": "ceo",
  "quote-information-item": "cen",
  "divider": "cei",
  "tooltip": "ceA",
  "amount": "ceY title-4",
  "align-left": "ceh",
  "sticky-panel": "cef"
};
