/* import __COLOCATED_TEMPLATE__ from './success-invitee-physical-one.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class FlowsCardsSuccessInviteePhysicalOne extends Component {
  @service sentry;
  @service segment;
  @service router;
  @service flow;
  @service flowLinkManager;

  cardImageProperties = {
    design: this.args.context.card.design,
    maskPan: '•'.repeat(16),
  };

  constructor() {
    super(...arguments);
    let { flowTrackingOrigin } = this.args.context;
    this.segment.track('cards_order_completed', {
      origin: flowTrackingOrigin,
    });
  }

  @action
  handleReviewClick() {
    let { card, flowTrackingOrigin } = this.args.context;
    this.segment.track('user-access_details_displayed', {
      origin: flowTrackingOrigin,
    });
    this.router.replaceWith('members.active.member', card.holder.id);
  }

  @action
  handleInviteNewClick() {
    let { flowTrackingOrigin } = this.args.context;
    this.segment.track('team_invite_selected', {
      origin: flowTrackingOrigin,
    });
    this.flow.reset();
    this.flowLinkManager.replaceWith({ name: 'member-invite' });
  }
}
