import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import ApprovalWorkflowForm from 'qonto/components/approval-workflow/form';
import { ABORT_DESTINATIONS } from 'qonto/routes/flows/setup/approval-workflows/approval-workflows';
import styles from './builder.strict-module.css';
export default class ApprovalWorkflowBuilder extends Component {
    @service
    intl;
    @service
    segment;
    get title() {
        if (this.args.context.workflow?.requestType === 'transfer') {
            return this.intl.t('approval-workflows.form.transfer-requests.title');
        } else {
            return this.intl.t('approval-workflows.form.reimbursement-requests.title');
        }
    }
    get shouldDisplayInviteMembersDisclaimer() {
        return this.args.context.approverOptions?.length === 1;
    }
    @action
    redirectToUserAccess() {
        let { context: context1, abort: abort1 } = this.args;
        context1.abortDestination = ABORT_DESTINATIONS.USER_ACCESS;
        abort1();
    }
    @action
    onSave() {
        let { transitionToNext: transitionToNext1 } = this.args;
        this.segment.track('approval-workflow-edit_save-button_clicked');
        transitionToNext1();
    }
    static{
        template(`
    <ApprovalWorkflowForm
      class={{styles.approval-workflow-form}}
      @approverOptions={{@context.approverOptions}}
      @title={{this.title}}
      @workflow={{@context.workflow}}
      @onSave={{this.onSave}}
      @context={{@context}}
      @redirectToUserAccess={{this.redirectToUserAccess}}
      @shouldDisplayInviteMembersDisclaimer={{this.shouldDisplayInviteMembersDisclaimer}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
