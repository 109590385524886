export default {
  "mandates": "va",
  "mandates-list": "vH",
  "left-section": "vO",
  "header-search": "vm",
  "header": "vp",
  "header-content": "vx",
  "search": "vw",
  "search-bar": "vN",
  "body": "vv",
  "list-title": "vo caption-bold",
  "details": "vn",
  "avatar": "vi mr-16",
  "header-beneficiary": "vA",
  "header-beneficiary-activity": "vY",
  "header-beneficiary-details": "vh",
  "header-beneficiary-details-recurring": "vf",
  "infos": "vK",
  "infos-title": "vG",
  "infos-empty": "vr",
  "infos-list": "vb",
  "infos-list-item": "vy",
  "infos-list-item-left": "vU",
  "infos-list-item-right": "vj",
  "infos-list-item-attachment": "vW"
};
