/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from 'design-system-kit';

import { INVOICE_SUBSCRIPTION_STATUS_COLORS, STATUS } from 'qonto/constants/invoice-subscriptions';

export default class InvoiceSubscriptionsTableRow extends Component {
  tag = Tag;

  @service intl;

  get #statusCopy() {
    return {
      [STATUS.SCHEDULED]: this.intl.t('recurring-invoices.statuses.scheduled'),
      [STATUS.ACTIVE]: this.intl.t('recurring-invoices.statuses.active'),
      [STATUS.FINISHED]: this.intl.t('recurring-invoices.statuses.completed'),
      [STATUS.CANCELED]: this.intl.t('recurring-invoices.statuses.canceled'),
      [STATUS.SUSPENDED]: this.intl.t('recurring-invoices.statuses.suspended'),
    };
  }

  get isGreyedOut() {
    return [STATUS.CANCELED, STATUS.SUSPENDED].includes(this.args.item.status);
  }

  get status() {
    let { status } = this.args.item;

    return {
      displayStatus: this.#statusCopy[status],
      color: INVOICE_SUBSCRIPTION_STATUS_COLORS[status],
    };
  }
}
