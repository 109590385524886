/* import __COLOCATED_TEMPLATE__ from './recommendation-cards.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { equal, reads } from 'macro-decorators';

import {
  CONTRACT_STATUS,
  PAY_LATER_ELIGIBILITY_STATUSES,
  PAY_LATER_THRESHOLD,
} from 'qonto/constants/financing';
import { GET_STARTED_ACTIONS_KEY } from 'qonto/constants/overview';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class GetStartedActionsRecommendationCardsComponent extends Component {
  @service abilities;
  @service intl;
  @service cardsManager;
  @service organizationManager;
  @service subscriptionManager;
  @service store;
  @service sentry;
  @service financing;

  @reads('organizationManager.organization.depositReleaseSent') depositReleaseSent;
  @equal('organizationManager.membership.topupsStatus', 'completed') topupsStatusCompleted;
  @equal('organizationManager.organization.balance', 0) balanceEqualToZero;
  @reads('cardsManager.counters') cardCounters;

  @tracked shouldDisplayPaymentActivationCard = false;

  @tracked hasReferrer;
  @tracked hasAlreadyCreatedInvoice = false;

  @tracked payLaterEligibilityResponse;

  START_USING_CARD = {
    title: this.intl.t('get-started-actions.recommendation-cards.start-using-card.title'),
    subtitle: this.intl.t('get-started-actions.recommendation-cards.start-using-card.subtitle'),
    cta: this.intl.t('get-started-actions.recommendation-cards.start-using-card.cta'),
    image: '/illustrations/get-started-actions/start-using-your-card.svg',
    model: this.organizationManager.organization,
    route: 'cards',
    trackEvent: 'get_started_action_use_card_clicked',
  };

  ORDER_CARD = {
    title: this.intl.t('get-started-actions.recommendation-cards.order-card.title'),
    subtitle: this.intl.t('get-started-actions.recommendation-cards.order-card.subtitle'),
    cta: this.intl.t('get-started-actions.recommendation-cards.order-card.cta'),
    image: '/illustrations/get-started-actions/order-your-card.svg',
    flowName: 'card-new-physical',
    flowStepId: 'choose-card',
    trackEvent: 'recommendation_order_card_clicked',
  };

  ORDER_CARD_REFEREE = {
    ...this.ORDER_CARD,
    subtitle: this.intl.t('get-started-actions.recommendation-cards.order-card.referral.subtitle'),
  };

  TOPUP_CARD = {
    title: this.intl.t('get-started-actions.recommendation-cards.top-up.title'),
    subtitle: this.intl.t('get-started-actions.recommendation-cards.top-up.subtitle'),
    cta: this.intl.t('get-started-actions.recommendation-cards.top-up.cta'),
    image: '/illustrations/get-started-actions/top-up-by-card.svg',
    route: 'onboarding.topup.amount',
    model: this.organizationManager.organization,
    trackEvent: 'recommendation_topup_clicked',
    origin: 'onboarding',
  };

  INVOICING_CARD = {
    title: this.intl.t('get-started-actions.recommendation-cards.invoicing.title'),
    subtitle: this.intl.t('get-started-actions.recommendation-cards.invoicing.subtitle'),
    cta: this.intl.t('get-started-actions.recommendation-cards.invoicing.cta'),
    image: '/illustrations/get-started-actions/invoice-card.svg',
    route: 'receivable-invoices',
    model: this.organizationManager.organization,
    trackEvent: 'recommendation_invoice_clicked',
    impressionEvent: 'recommendation_invoice_viewed',
    origin: 'onboarding',
  };

  INVITE_CARD = {
    title: this.intl.t('get-started-actions.recommendation-cards.invite-member.title'),
    subtitle: this.intl.t('get-started-actions.recommendation-cards.invite-member.subtitle'),
    cta: this.intl.t('get-started-actions.recommendation-cards.invite-member.cta'),
    image: '/illustrations/get-started-actions/invite-member-card.svg',
    flowName: 'member-invite',
    flowStepId: 'fm-discovery',
    query: { origin: 'gsa-invite-team-member' },
    model: this.organizationManager.organization,
    trackEvent: 'recommendation_team_invite_clicked',
    impressionEvent: 'recommendation_team_invite_loaded',
  };

  IBAN_CARD = {
    title: this.intl.t('get-started-actions.recommendation-cards.iban.title'),
    subtitle: this.intl.t('get-started-actions.recommendation-cards.iban.subtitle'),
    cta: this.intl.t('get-started-actions.recommendation-cards.iban.cta'),
    image: '/illustrations/get-started-actions/show-iban.svg',
    route: 'accounts.index',
    model: this.organizationManager.organization,
    trackEvent: 'recommendation_IBAN_clicked',
  };

  PAYMENT_ACTIVATION_TOPUP_CARD = {
    title: this.intl.t('get-started-actions.recommendation-cards.payment-activation.title'),
    subtitle: this.intl.t('get-started-actions.recommendation-cards.payment-activation.subtitle', {
      date: dateToken({
        locale: this.intl.locale,
        date: this.subscriptionManager.currentSubscription.activeTrial?.end_date,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      }),
    }),
    cta: this.intl.t('get-started-actions.recommendation-cards.payment-activation.cta'),
    image: '/illustrations/get-started-actions/payment-activation-top-up.svg',
    flowName: 'payment-activation',
    flowStepId: 'context',
    trackEvent: 'recommendation_topup_clicked',
    origin: 'payment_activation',
    impressionEvent: 'recommendation_topup_viewed',
  };

  constructor() {
    super(...arguments);

    this.fetchHasReferrer.perform().catch(error => {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });

    // Retrieve the current eligibility status to obtain the most up-to-date value required for displaying the top-up card
    if (this.abilities.can('access payment-activation')) {
      this.fetchPaymentActivationEligibility.perform().catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
    }

    if (this.abilities.can('create receivable-invoice')) {
      this.fetchInvoicesTask.perform().catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
    }

    if (
      this.abilities.can('access pay later in financing') &&
      this.organizationManager.organization.mainAccount.balance <=
        PAY_LATER_THRESHOLD.MAIN_ACCOUNT_LOW_BALANCE
    ) {
      this.fetchPayLaterEligibilityTask.perform().catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
    }
  }

  get recommendationCardsToDisplay() {
    let cards = [];

    if (this.fetchPaymentActivationEligibility.isRunning) {
      return [];
    }

    if (this.shouldDisplayPaymentActivationCard) {
      cards.push(this.PAYMENT_ACTIVATION_TOPUP_CARD);
    }

    // We don't want to show two Cards to Top Up the account
    if (this.shouldDisplayTopUpCard && !this.shouldDisplayPaymentActivationCard) {
      cards.push(this.TOPUP_CARD);
    }

    if (this.shouldDisplayOrderCard && !this.shouldDisplayStartUsingCard) {
      cards.push(this.hasReferrer ? this.ORDER_CARD_REFEREE : this.ORDER_CARD);
    }

    if (this.shouldDisplayInvoiceCard) {
      cards.push(this.INVOICING_CARD);
    }

    if (this.shouldDisplayStartUsingCard && !this.shouldDisplayOrderCard) {
      cards.push(this.START_USING_CARD);
    }

    if (this.shouldDisplayInviteCard) {
      cards.push(this.INVITE_CARD);
    }

    // We need to hide IBAN card when payment activation card is displayed
    if (this.shouldDisplayIBANCard && !this.shouldDisplayPaymentActivationCard) {
      cards.push(this.IBAN_CARD);
    }

    if (this.shouldDisplayPayLaterCard) {
      cards.push(this.payLaterCard);
    }

    return cards.slice(0, 2);
  }

  get hasRecommendationCardsToDisplay() {
    let hasCardsToDisplay =
      this.shouldDisplayStartUsingCard ||
      this.shouldDisplayOrderCard ||
      this.shouldDisplayTopUpCard ||
      this.shouldDisplayInviteCard ||
      this.shouldDisplayIBANCard ||
      this.shouldDisplayPaymentActivationCard ||
      this.shouldDisplayInvoiceCard ||
      this.shouldDisplayPayLaterCard;

    this.args.onCardsDisplay(hasCardsToDisplay);

    return hasCardsToDisplay;
  }

  get shouldDisplayStartUsingCard() {
    if (this.hasPassedTransactionThreshold) {
      return false;
    }

    return !this.hasCardTransactions && this.cardCounters?.physical_activated_for_organization > 0;
  }

  get shouldDisplayOrderCard() {
    if (this.hasPassedTransactionThreshold) {
      return false;
    }

    return (
      this.abilities.can('create card') &&
      this.cardCounters?.physical_created_for_organization === 0
    );
  }

  get shouldDisplayTopUpCard() {
    if (this.hasPassedTransactionThreshold) {
      return false;
    }

    return this.abilities.can('resume topup onboarding') && this.balanceEqualToZero;
  }

  get shouldDisplayInvoiceCard() {
    let { organization } = this.organizationManager;

    let contractSignedIsLessThanOneMonthOld = dayjs().isBetween(
      organization.contractSignedAt,
      dayjs(organization.contractSignedAt).add(1, 'month')
    );

    if (
      organization.underRegistration ||
      safeLocalStorage.getItem('receivable-invoices-page-seen') ||
      this.hasAlreadyCreatedInvoice ||
      !contractSignedIsLessThanOneMonthOld
    ) {
      return false;
    }

    return this.abilities.can('create receivable-invoice');
  }

  get shouldDisplayInviteCard() {
    let { organization } = this.organizationManager;

    // Absense of `contractSignedAt` would choke `isWithinInterval`
    let { contractSignedAt } = organization;
    let lessThanOneMonthOld =
      contractSignedAt &&
      dayjs().isBetween(contractSignedAt, dayjs(contractSignedAt).add(1, 'month'));

    let hasTeamManagement = this.subscriptionManager.hasFeature('team_management');
    return (
      hasTeamManagement &&
      organization.legalCountry === 'FR' &&
      organization.underRegistration === false &&
      this.abilities.can('create invite') &&
      lessThanOneMonthOld
    );
  }

  get shouldDisplayIBANCard() {
    if (this.hasPassedTransactionThreshold) {
      return false;
    }

    if (this.abilities.cannot('access bank-account')) {
      return false;
    }

    let transactionsCount = this.args.transactions?.length;
    return (
      transactionsCount === 0 ||
      (transactionsCount === 1 && (this.depositReleaseSent || this.topupsStatusCompleted)) ||
      (transactionsCount === 2 && this.depositReleaseSent && this.topupsStatusCompleted)
    );
  }

  get shouldDisplayPayLaterCard() {
    if (safeLocalStorage.getItem(GET_STARTED_ACTIONS_KEY)) {
      return false;
    }
    let response = this.payLaterEligibilityResponse;

    if (!response || response?.eligibility !== PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE) {
      return false;
    }

    if (response.contractStatus === CONTRACT_STATUS.SIGNED) {
      return (
        response.availableCreditAmount?.value >= PAY_LATER_THRESHOLD.MINIMUM_CREDIT_AMOUNT_VALUE
      );
    } else {
      return response.creditLimitAmount?.value >= PAY_LATER_THRESHOLD.MINIMUM_CREDIT_AMOUNT_VALUE;
    }
  }

  get payLaterCreditAmount() {
    let response = this.payLaterEligibilityResponse;

    if (response.contractStatus === CONTRACT_STATUS.SIGNED) {
      return response.availableCreditAmount?.value;
    } else {
      return response.creditLimitAmount?.value;
    }
  }

  get payLaterCard() {
    return {
      title: this.intl.t('get-started-actions.recommendation-cards.pay-later.title'),
      subtitle: this.intl.t('get-started-actions.recommendation-cards.pay-later.subtitle', {
        amount: this.intl.formatNumber(this.payLaterCreditAmount, {
          style: 'currency',
          currency: 'EUR',
        }),
      }),
      cta: this.intl.t('get-started-actions.recommendation-cards.pay-later.cta'),
      image: '/illustrations/financing/pay-later/money-bag.svg',
      flowName: 'pay-later-transfer',
      flowStepId: 'invoice-upload',
      query: { origin: TRANSFER_FLOW_ORIGIN.OVERVIEW },
      trackEvent: 'get-started_card_clicked',
      origin: 'pay_later',
    };
  }

  get hasCardTransactions() {
    return this.args.transactions?.some(t => t.isCardTransaction);
  }

  get hasPassedTransactionThreshold() {
    return this.args.transactions?.length >= 4;
  }

  fetchPaymentActivationEligibility = dropTask(async () => {
    let organizationAdapter = this.store.adapterFor('organization');

    let response = await organizationAdapter.getPaymentActivationEligibility();
    this.shouldDisplayPaymentActivationCard = response?.paymentActivationTopupEnabled;
  });

  fetchHasReferrer = dropTask(async () => {
    let organizationAdapter = this.store.adapterFor('organization');
    let response = await organizationAdapter.hasReferrer();

    this.hasReferrer = response?.hasReferrer;
  });

  fetchInvoicesTask = dropTask(async () => {
    let organization = this.organizationManager.organization;

    let receivableInvoice = await this.store.query('receivable-invoice', {
      organization_id: organization.id,
      page: { number: 1, size: 1 }, // Fetch first receivable invoice
    });

    this.hasAlreadyCreatedInvoice = receivableInvoice.length > 0;
  });

  fetchPayLaterEligibilityTask = dropTask(async () => {
    this.payLaterEligibilityResponse = await this.financing.checkPayLaterEligibility();
  });
}
