export default {
  "checks": "yv",
  "wrapper": "yo",
  "form": "yn",
  "title": "yi",
  "wrapper-body": "yA",
  "aside": "yY",
  "steps-container": "yh",
  "step": "yf",
  "container-dot": "yK",
  "text": "yG",
  "text-title": "yr",
  "text-desc": "yb",
  "start-button": "yy"
};
