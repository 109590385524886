/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';

export default class FlowsBudgetsCreateBudgetSuccess extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;

  @action
  next() {
    this.segment.track('budget-creation_success-close_clicked');
    this.args.transitionToNext();
  }
}
