/* import __COLOCATED_TEMPLATE__ from './edit-vehicle.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class RequestDetailsEditVehicleComponent extends Component {
  @service intl;

  get vehicleFiscalPower() {
    let { vehiclePower, isVehicleElectric } = this.args;
    if (vehiclePower) {
      return this.intl.t(
        isVehicleElectric
          ? 'requests.mileage.steps.request-details.fiscal-power-electric'
          : 'requests.mileage.steps.request-details.fiscal-power',
        {
          fiscal_power: vehiclePower,
        }
      );
    }
    return vehiclePower;
  }

  @action
  handleClick() {
    this.args.onClick?.();
  }
}
