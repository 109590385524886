/* import __COLOCATED_TEMPLATE__ from './forgot-password-form.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class ForgotPasswordForm extends Component {
  spinner = Spinner;

  @service store;
  @service intl;
  @service toastFlashMessages;
  @service sentry;

  @tracked requested = false;
  @tracked user = null;

  @reads('user.didValidate') isEmailValidationEnabled;

  constructor() {
    super(...arguments);

    this.user = this.store.createRecord('user');
  }

  willDestroy() {
    super.willDestroy(...arguments);

    // Ember Data 3.28 does not like calling `unloadRecord()` on a store
    // instance that is already in "destroying" mode. Since we can skip the
    // cleanup in this case anyway we just return early here.
    if (this.store.isDestroying || this.store.isDestroyed) return;

    this.user.unloadRecord();
  }

  forgotPasswordTask = dropTask(async email => {
    this.user.set('didValidate', true);

    if (!this.user.validations.attrs.email.isTruelyValid) {
      return;
    }

    try {
      await this.args.forgotPassword(email);
      this.requested = true;
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
