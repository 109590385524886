export default {
  "integration-item": "cMZ",
  "icon-container": "cMR",
  "content": "cMQ",
  "title-container": "cMe",
  "new-badge": "cMB",
  "title": "cME",
  "description": "cMd",
  "no-attributes": "cMI",
  "connect-cta": "cMt"
};
