/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class FlowsTransfersSepaNewDetailsComponent extends Component {
  @service segment;

  @tracked isValidationEnabled = false;

  get beneficiary() {
    return this.sepaTransfer.get('beneficiary');
  }

  get sepaTransfer() {
    return this.args.context.sepaTransfer;
  }

  onClickNextTask = dropTask(async sepaTransfer => {
    await sepaTransfer.validate();

    this.isValidationEnabled = true;

    let { reference, bankAccount, amount, scheduledDate } = sepaTransfer.validations.attrs;
    if (
      reference.isInvalid ||
      bankAccount.isInvalid ||
      amount.isInvalid ||
      scheduledDate.isInvalid
    ) {
      this._scrollToErrorField();
      return;
    }

    let { context, transitionToNext } = this.args;
    let { origin } = context;

    this.segment.track('transfer-sepa_amount_submitted', {
      ...(origin && { origin }),
    });

    transitionToNext();
  });

  _scrollToErrorField() {
    next(() => scrollIntoView('[data-has-error]'));
  }
}
