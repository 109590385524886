/* import __COLOCATED_TEMPLATE__ from './message.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class FlowsConciergeMessage extends Component {
  @service intl;

  @tracked errorMessage;

  @action
  handleOnInput(event) {
    let { value: message } = event.target;
    this.errorMessage = null;
    this.args.context.conciergeRequest.inquiry = message;
  }

  @action
  handleNext() {
    let { inquiry } = this.args.context.conciergeRequest;
    if (!inquiry || !inquiry.length) {
      this.errorMessage = this.intl.t('validations.errors.blank');
    } else {
      this.errorMessage = null;
      this.args.transitionToNext();
    }
  }
}
