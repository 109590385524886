/* import __COLOCATED_TEMPLATE__ from './available-credit.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from 'design-system-kit';

export default class FinancingPayLaterCockpitInsightsTilesAvailableCreditComponent extends Component {
  badgeStatus = BadgeStatus;

  @service intl;

  get creditLimitAmount() {
    let { creditLimitAmount } = this.args;

    return this.intl.formatNumber(creditLimitAmount.value, {
      style: 'currency',
      currency: creditLimitAmount.currency,
    });
  }

  get availableCreditAmount() {
    let { availableCreditAmount, isAvailableCreditAmountLessThanThreshold } = this.args;

    if (isAvailableCreditAmountLessThanThreshold) {
      return {
        value: '0.00',
        currency: availableCreditAmount.currency,
      };
    }

    return availableCreditAmount;
  }
}
