/* import __COLOCATED_TEMPLATE__ from './choose-card-physical.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task, waitForQueue } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  CARD_LEVELS,
  CARD_LEVELS_TRACKING,
  COMPARE_TAB_ID,
  CUSTOMIZABLE_CARDS_LEVELS,
} from 'qonto/constants/cards';

export default class FlowsCardsChooseCardPhysical extends Component {
  @service modals;
  @service segment;

  @reads('args.context.cardsLimits') cardsLimits;
  @reads('args.context.estimates') estimates;
  @reads('args.context.hasOneCardsLeft') hasOneCardsLeft;
  @reads('args.context.isEstimateLoaded') isEstimateLoaded;
  @reads('args.context.organization') organization;

  COMPARE_TAB_ID = COMPARE_TAB_ID;

  constructor() {
    super(...arguments);
    this.launchMetalCardDetailsModal();
  }

  get isOneCardIncluded() {
    return this.estimates && this.isEstimateLoaded && this.hasOneCardsLeft;
  }

  chooseCardTask = task(async cardLevel => {
    await this._proceedWithCard(cardLevel);
  });

  _proceedWithCard(cardLevel) {
    let {
      context: { card, cardsLimits, flowTrackingOrigin },
      transitionToNext,
    } = this.args;

    this.segment.track('cards_order_physical_type_chosen', {
      origin: flowTrackingOrigin,
      card_type: CARD_LEVELS_TRACKING[cardLevel],
    });

    let { payment_monthly_limit_maximum, atm_monthly_limit_maximum } = cardsLimits[cardLevel];

    card.setProperties({
      atmMonthlyLimit: atm_monthly_limit_maximum,
      cardLevel,
      paymentMonthlyLimit: payment_monthly_limit_maximum,
    });

    this.args.context.showCardCustomization = CUSTOMIZABLE_CARDS_LEVELS.includes(cardLevel);

    card.setProperties({
      cardDesign: null,
      typeOfPrint: null,
    });

    transitionToNext();
  }

  @action
  handleOpenCardComparisonModal(tabId) {
    this.modals.open('card/modals/comparison', {
      initialTab: tabId,
      isFullScreenModal: true,
      pricing: this.estimates,
      cardsMaxLimits: this.cardsLimits,
      isOneCardIncluded: this.isOneCardIncluded,
      handleChooseCardTask: this.chooseCardTask,
    });

    this.args.context.showMetalCardDetailsModal = false;

    let { flowTrackingOrigin } = this.args.context;

    let trackingEventOptions =
      tabId === CARD_LEVELS.METAL
        ? {
            type: CARD_LEVELS_TRACKING[CARD_LEVELS.METAL],
            origin: flowTrackingOrigin,
          }
        : {
            origin: flowTrackingOrigin,
          };
    this.segment.track('cards_view_details', trackingEventOptions);
  }

  async launchMetalCardDetailsModal() {
    await waitForQueue('afterRender');

    if (this.args.context.showMetalCardDetailsModal) {
      this.handleOpenCardComparisonModal(CARD_LEVELS.METAL);
    }
  }
}
