/* import __COLOCATED_TEMPLATE__ from './journey.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { restartableTask, task } from 'ember-concurrency';

import { apiBaseURL } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { generateGoogleMapsLink } from 'qonto/utils/generate-google-maps-link';

const ERROR_DISTANCE_TOO_SMALL = 'distance_too_small';
const ERROR_ZERO_RESULTS = 'ZERO_RESULTS';
const DETAILS_TYPE_FOR_TRAKING = {
  departure: 'starting_point',
  arrival: 'destination',
};

export default class JourneyComponent extends Component {
  @service toastFlashMessages;
  @service intl;
  @service networkManager;
  @service organizationManager;
  @service segment;
  @service sentry;

  @tracked arrival = this.args.context.requestMileage?.arrival;
  @tracked arrivalHasText = false;
  @tracked confirmButtonClicked = false;
  @tracked departure = this.args.context.requestMileage?.departure;
  @tracked departureHasText = false;
  @tracked distance = this.distanceInKm;
  @tracked inlineErrorMessageArrival;
  @tracked inlineErrorMessageDeparture;
  @tracked recentArrivals = this.fetchRecentAddressesTask.last?.value?.arrivals;
  @tracked recentDepartures = this.fetchRecentAddressesTask.last?.value?.departures;
  @tracked roundTrip = this.args.context.requestMileage?.roundTrip ?? false;
  @tracked showDistance = Boolean(this.args.context.requestMileage?.distanceMeters);

  ERROR_MESSAGES = {
    ERROR_DISTANCE_TOO_SMALL: this.intl.t('requests.mileage.steps.journey.errors.one-kilometer'),
    ERROR_MESSAGE_NO_ADDRESS_FOUND: this.intl.t(
      'requests.mileage.steps.journey.errors.no-address-found'
    ),
    ERROR_MESSAGE_CALCULATION_FAILED: this.intl.t(
      'requests.mileage.steps.journey.errors.cant-calculate-distance'
    ),
    ERROR_MESSAGE_REQUIRED: this.intl.t('validations.errors.blank'),
    ERROR_MESSAGE_NO_ADDRESS_SELECTED: this.intl.t(
      'requests.mileage.steps.journey.errors.no-address-selected'
    ),
    ERROR_UNKNOWN: this.intl.t('requests.mileage.steps.journey.errors.something-wrong'),
  };

  constructor() {
    super(...arguments);
    // We disable the eslint rule because errors are handled in the inner tasks
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.fetchRecentAddressesTask.perform();
  }

  get distanceInKm() {
    return (this.args.context.requestMileage?.distanceMeters / 1000).toFixed(2);
  }

  get generateGoogleMapsLink() {
    let { departure, arrival } = this.args.context.requestMileage;

    return generateGoogleMapsLink(departure, arrival, this.roundTrip);
  }

  @action
  onInput(field, value) {
    this.confirmButtonClicked = false;
    this.showDistance = false;
    this[`${field}Edited`] = true;
    this[`inlineErrorMessage${capitalize(field)}`] = null;

    if (value === '') {
      this.resetField(field, false);
    } else {
      this[`${field}HasText`] = true;
    }
  }

  @action
  handleNext() {
    this.confirmButtonClicked = true;

    if (this.validate()) {
      this.segment.track('request_trip_selected', {
        origin: 'mileage_request',
      });
      this.args.transitionToNext();
    }
  }

  updateDetailsTask = restartableTask(async (detailType, detailValue) => {
    await this.updateJourneyDetails(detailType, detailValue);
  });

  toggleRoundTripTask = restartableTask(async () => {
    this.segment.track('request_round_trip_clicked', {
      origin: 'mileage_request',
      activated: !this.roundTrip,
    });
    await this.updateJourneyDetails('roundTrip', !this.roundTrip);
  });

  fetchRecentAddressesTask = task(async () => {
    let { organization } = this.organizationManager;

    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/v3/requests/mileages/recent_addresses`,
        { data: { organization_id: organization.id } }
      );

      return {
        departures: this.formatRecentAddresses(response.departures),
        arrivals: this.formatRecentAddresses(response.arrivals),
      };
    } catch (error) {
      // We don't want to handle this specific error
      // so we just log the error to sentry
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  calculateJourneyDistanceTask = restartableTask(async () => {
    this.showDistance = false;
    if (!this.departure || !this.arrival) {
      return;
    }

    let { organization } = this.organizationManager;

    try {
      let distance = await this.networkManager.request(
        `${apiBaseURL}/v3/requests/mileages/calculate_distance`,
        {
          method: 'POST',
          data: {
            organization_id: organization.id,
            departure: {
              google_place_id: this.departure.googlePlaceId,
            },
            arrival: {
              google_place_id: this.arrival.googlePlaceId,
            },
            round_trip: this.roundTrip,
          },
        }
      );

      this.args.context.requestMileage.distanceMeters = distance.distance_meters;
      this.inlineErrorMessageArrival = null;
      this.inlineErrorMessageDeparture = null;
      this.distance = this.distanceInKm;
      this.showDistance = true;
    } catch (error) {
      let errorDistanceTooSmall = error?.errors?.find(
        ({ code }) => code === ERROR_DISTANCE_TOO_SMALL
      );

      if (ErrorInfo.for(error).shouldSendToSentry && !errorDistanceTooSmall) {
        this.sentry.captureException(error);
      }

      if (errorDistanceTooSmall) {
        this.args.context.requestMileage.distanceMeters =
          errorDistanceTooSmall.meta.distance_meters;
        this.distance = this.distanceInKm;
        this.showDistance = true;
        return { errorMessage: this.ERROR_MESSAGES.ERROR_DISTANCE_TOO_SMALL };
      }

      return { errorMessage: this.ERROR_MESSAGES.ERROR_MESSAGE_CALCULATION_FAILED };
    }
  });

  updateJourneyDetails = async (detailType, detailValue) => {
    // Reset any inline error message
    this[`inlineErrorMessage${capitalize(detailType)}`] = null;

    if (this.isRecentAddress(detailValue)) {
      this.segment.track('request_saved_address_selected', {
        location: DETAILS_TYPE_FOR_TRAKING[detailType],
        origin: 'mileage_request',
      });
    }
    this[detailType] = detailValue;
    this.args.context.requestMileage[detailType] = detailValue;
    await this.calculateJourneyDistanceTask.perform();
  };

  handleInlineError = (field, error) => {
    let errors = {
      [ERROR_ZERO_RESULTS]: this.ERROR_MESSAGES.ERROR_MESSAGE_NO_ADDRESS_FOUND,
      default: this.ERROR_MESSAGES.ERROR_UNKNOWN,
    };
    this[`inlineErrorMessage${capitalize(field)}`] = errors[error] || errors.default;
  };

  validate() {
    let isDepartureValid = this.checkFieldErrors('departure');
    let isArrivalValid = this.checkFieldErrors('arrival');

    if (!isDepartureValid || !isArrivalValid) {
      return false;
    }

    let journeyDistanceError = this.calculateJourneyDistanceTask.last?.value?.errorMessage;

    if (journeyDistanceError) {
      this.toastFlashMessages.toastError(journeyDistanceError);
      return false;
    }

    return true;
  }

  checkFieldErrors(field) {
    let unknownErrorMessage = this[`inlineErrorMessage${capitalize(field)}`];
    if (unknownErrorMessage && this[`${field}Edited`]) {
      this.showDistance = false;
      return false;
    }

    let details = this[field];
    let hasText = field === 'departure' ? this.departureHasText : this.arrivalHasText;

    return this.setErrorMessage(field, hasText, details);
  }

  setErrorMessage(field, hasText, details) {
    if (hasText && !details) {
      this[`inlineErrorMessage${capitalize(field)}`] =
        this.ERROR_MESSAGES.ERROR_MESSAGE_NO_ADDRESS_SELECTED;
      return false;
    }

    if (!details) {
      this[`inlineErrorMessage${capitalize(field)}`] = this.ERROR_MESSAGES.ERROR_MESSAGE_REQUIRED;
      this.showDistance = false;
      return false;
    }

    return true;
  }

  resetField(field, hasText) {
    this[field] = null;
    this[`${field}HasText`] = hasText;
  }

  formatRecentAddresses(arr) {
    return arr.map(item => ({
      formattedAddress: item.formatted_address,
      googlePlaceId: item.google_place_id,
    }));
  }

  isRecentAddress = address => {
    return this.recentDepartures.includes(address) || this.recentArrivals.includes(address);
  };
}
