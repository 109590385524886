/* import __COLOCATED_TEMPLATE__ from './disclaimer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { SubscriptionCloseStepsClosingDate } from 'qonto/react/components/subscription/close/closing-date';

export default class SubscriptionCloseStepsDisclaimerComponent extends Component {
  @tracked isConfirmationChecked = false;
  @tracked showCheckboxError = false;
  SubscriptionCloseStepsClosingDate = SubscriptionCloseStepsClosingDate;

  @service intl;
  @service segment;
  @service subscriptionManager;
  @service organizationManager;
  @service toastFlashMessages;

  get hasError() {
    return !this.isConfirmationChecked;
  }

  get planName() {
    return this.subscriptionManager.currentPricePlan.localName;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription.recurrence;
  }

  @action
  abort() {
    this.args.abort();
    this.segment.track('account_closing_consequences_keep_account_clicked');
  }

  @action
  toggleConfirmationChecked() {
    this.isConfirmationChecked = !this.isConfirmationChecked;
    if (this.isConfirmationChecked) {
      this.showCheckboxError = false;
    }
  }
  createDeactivationTask = dropTask(async () => {
    try {
      let {
        closingReasonCategory,
        closingReasonSubCategory,
        closingReasonDetails,
        closingReasonDetailsOther,
      } = this.args.context;

      if (!this.isConfirmationChecked) {
        this.showCheckboxError = true;
        return;
      }

      let { organization } = this.organizationManager;
      let { due_date } = await organization.createDeactivation({
        closing_reason_category: closingReasonCategory?.key,
        closing_reason_sub_category: closingReasonSubCategory?.key || '',
        closing_reason_details: closingReasonDetails?.key || '',
        closing_reason_details_other: closingReasonDetailsOther || '',
      });

      this.args.context.deactivationDueDate = dayjs(due_date).toDate();
      this.segment.track('account_closing_requirements_clicked');
      this.args.transitionToNext();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      let message = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(message);
    }
  });
}
