/* import __COLOCATED_TEMPLATE__ from './addons.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LoaderWithButton, SubscriptionError, SubscriptionHeader } from '@repo/domain-kit';
import { Disclaimer, ToggleButton } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { AddonCards } from 'qonto/components/flows/addon-change/addon-cards';
import { pricingCatalogBaseURL, pricingCatalogNamespace } from 'qonto/constants/hosts';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

import lottie from '/lotties/error-404.json';

export default class FlowsAddonChangeAddonsComponent extends Component {
  @service intl;
  @service organizationManager;
  @service subscriptionManager;
  @service networkManager;
  @service sentry;

  toggleButton = ToggleButton;
  loaderWithButton = LoaderWithButton;
  disclaimer = Disclaimer.Block;
  subscriptionHeader = SubscriptionHeader;
  subscriptionError = SubscriptionError;
  addonCards = AddonCards;
  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;
  lottie = lottie;

  @tracked addons = [];
  @tracked traits = [];

  constructor() {
    super(...arguments);
    this.initTask.perform().catch(ignoreCancelation);
  }

  get currentSubscriptionRecurrence() {
    return this.subscriptionManager.currentSubscription.recurrence;
  }

  get shouldShowRecurrenceDisclaimer() {
    return (
      this.currentSubscriptionRecurrence === SUBSCRIPTION_RECURRENCES.MONTHLY &&
      this.args.context.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL
    );
  }

  get showDisclaimerForItalianOrganizations() {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get italianDisclaimerText() {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-addons-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  @action
  selectRecurrence(recurrence) {
    this.args.context.recurrence = recurrence;
  }

  @action
  onChange() {
    // TODO update onChange and call the confirm endpoint or the inactivate endpoint
  }

  @action
  reloadAddons() {
    this.initTask.perform().catch(ignoreCancelation);
  }

  initTask = dropTask(async () => {
    try {
      let response = await this.fetchAddonsTask.perform();
      this.addons = response.products;
      this.traits = response.traits;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  fetchAddonsTask = dropTask(async () => {
    return await this.networkManager.request(
      `${pricingCatalogBaseURL}/${pricingCatalogNamespace}/products?type=addons`
    );
  });
}
