export default {
  "header": "cXY",
  "center": "cXh",
  "header-placeholder": "cXf",
  "placeholder": "cXK",
  "name-block": "cXG",
  "block": "cXr",
  "detail-block": "cXb",
  "border-top": "cXy"
};
