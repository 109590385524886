/* import __COLOCATED_TEMPLATE__ from './add-supplier.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from 'design-system-kit';
import { dropTask } from 'ember-concurrency';

import cloneProperties from 'qonto/utils/clone-properties';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
} from 'qonto/utils/transfers';

export default class FlowsTransfersSepaNewAddSupplierComponent extends Component {
  spinner = Spinner;

  @service abilities;
  @service beneficiariesManager;
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service supplierInvoicesManager;

  @tracked supplier = null;

  constructor() {
    super(...arguments);
    this.prefillSupplierTask.perform().catch(ignoreCancelation);
  }

  @action
  transitionToNext(supplier) {
    let { transitionToNext } = this.args;
    this._setTransferBeneficiary(supplier);
    this.toastFlashMessages.toastSuccess(this.intl.t('transfers.beneficiaries.add-modal.success'));
    transitionToNext();
  }

  prefillSupplierTask = dropTask(async () => {
    let supplierInvoice = await this.supplierInvoicesManager.findSupplierInvoice(
      this.args.context.supplierInvoiceId
    );

    let { iban, supplierName } = supplierInvoice;

    let matchingBeneficiary = await this.beneficiariesManager.getSEPABeneficiaryByIban(
      this.organizationManager.organization.id,
      iban
    );

    let supplierRecord;

    if (matchingBeneficiary) {
      supplierRecord = this._createBeneficiaryRecord();
      cloneProperties(matchingBeneficiary, supplierRecord);
      supplierRecord.name = supplierName;
    } else {
      supplierRecord = this._createBeneficiaryRecord({
        ...(iban && { iban }),
        ...(supplierName && { name: supplierName }),
      });
    }

    this.supplier = supplierRecord;
  });

  _createBeneficiaryRecord(prefilledOptions) {
    return this.beneficiariesManager.createSepaBeneficiary(
      this.organizationManager.organization,
      prefilledOptions
    );
  }

  _setTransferBeneficiary(supplier) {
    let { sepaTransfer } = this.args.context;

    sepaTransfer.set('beneficiary', supplier);

    copyBeneficiaryIntoTransfer(sepaTransfer, supplier, { forceCopy: true });

    copyBeneficiaryLabelsIntoTransfer(sepaTransfer, supplier);

    if (this.abilities.can('view vat bookkeeping')) {
      copyBeneficiaryVatIntoTransfer(sepaTransfer, supplier);
    }
  }
}
