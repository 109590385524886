/* import __COLOCATED_TEMPLATE__ from './desktop-view.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';

export default class InvitationsNewUserDesktopViewComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service router;

  animationReady = false;

  @action
  handleInviteDetailsSubmitted(invite, user) {
    return user.save().then(() => this.router.transitionTo('invitations.show.member.address'));
  }
  @action
  handleGoBack() {
    return this.router.transitionTo('invitations.show.credentials');
  }
}
