export default {
  "dates-filter": "AS",
  "dates-wrapper": "Ac",
  "date-interval": "Aq",
  "date-field-wrapper": "AZ",
  "dates-conditional": "AR",
  "placeholder": "AQ",
  "error": "Ae",
  "error-message": "AB body-2"
};
