/* import __COLOCATED_TEMPLATE__ from './invite-card.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_FLOWS_TRACKING_ORIGINS } from 'qonto/constants/cards';

export default class FlowsInviteCardSuccessInviteComponent extends Component {
  @service flowLinkManager;
  @service segment;
  @service flow;

  constructor() {
    super(...arguments);

    this.segment.track('invitation-success_displayed', {
      origin: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION,
    });
  }

  @action primaryAction() {
    this.segment.track('cards_order_clicked', {
      origin: 'invitation-success_user-access',
    });

    let { invitedMember } = this.args.context;
    this.flow.reset();
    this.flowLinkManager.transitionTo({
      name: 'card-new-invitee',
      queryParams: { inviteeMembershipId: invitedMember.recipientId },
    });
  }

  @action secondaryAction() {
    this.segment.track('team_invite_selected', {
      origin: 'invitation-success_user-access',
    });

    this.args.restartFlow();
  }
}
