/* import __COLOCATED_TEMPLATE__ from './review-upsell.hbs'; */
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { CARD_LEVELS, CARD_LEVELS_TRACKING, FORMAT_PRICE_OPTIONS } from 'qonto/constants/cards';
import {
  getMonthlyCost,
  getUpsellFreeUntil,
  getUpsellNextBillingRecurrence,
} from 'qonto/utils/cards/card-costs';
import { displayCardName } from 'qonto/utils/cards/display-card-name';

export default class FlowsCardsReview extends Component {
  @service intl;
  @service segment;

  get cardPanelInfo() {
    let { context } = this.args;

    return {
      icon: `card-${context.cardUpsellDesign || context.cardUpsellLevel}`,
      displayNameKey: context.cardUpsellDesign || context.cardUpsellLevel,
      displayTypeKey: context.cardUpsellLevel,
      displayTypeOfPrint: context.cardUpsellTypeOfPrint,
    };
  }

  get upsellMonthlyCost() {
    return this.intl.formatNumber(
      getMonthlyCost(this.args.context.cardUpsellLevel, this.args.context.estimates),
      FORMAT_PRICE_OPTIONS
    );
  }

  get upsellFreeUntilDate() {
    return getUpsellFreeUntil(this.args.context.cardUpsellLevel, this.args.context.estimates);
  }

  get payingInfo() {
    // Variables to pass down to translations
    let { card, cardUpsellLevel, estimates } = this.args.context;
    let oldCardName = displayCardName(card.cardLevel, this.intl);
    let newCardName = displayCardName(cardUpsellLevel, this.intl);
    let willStartPayingAt = dateToken({
      date: getUpsellNextBillingRecurrence(cardUpsellLevel, estimates),
      locale: this.intl.locale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });

    // Translation key will be different for a free One card
    let isCurrentCardStandard = card.cardLevel === CARD_LEVELS.STANDARD;

    return isCurrentCardStandard
      ? this.intl.t('cards.steps.review.upsell.details.paying-infos.one-upsold-card', {
          oldCardName,
          newCardName,
          date: willStartPayingAt,
        })
      : this.intl.t('cards.steps.review.upsell.details.paying-infos.charged-upsold-card', {
          oldCardName,
          newCardName,
          date: willStartPayingAt,
        });
  }

  @action
  confirmUpsell(cardUpsellId) {
    let { card, cardUpsellLevel } = this.args.context;
    this.segment.track('cards_upsell_confirmed', {
      card_level_origin: CARD_LEVELS_TRACKING[card.cardLevel],
      card_level_upsell: CARD_LEVELS_TRACKING[cardUpsellLevel],
    });

    set(this.args.context, 'upsellCard.id', cardUpsellId);
    this.args.transitionToNext();
  }
}
