export default {
  "header-cell": "cCl",
  "empty": "cCX",
  "header-content": "cCC caption-bold",
  "active": "cCk",
  "align-right": "cCJ",
  "first-col": "cCs",
  "mid-col": "cCF",
  "status-col": "cCg"
};
