export default {
  "header": "YR",
  "header-top": "YQ",
  "header-inner": "Ye",
  "disclaimer": "YB mb-16",
  "header-main-wrapper": "YE",
  "header-main": "Yd",
  "logo": "YI mr-16",
  "header-right": "Yt",
  "partner-description": "YM body-2"
};
