/* import __COLOCATED_TEMPLATE__ from './type-selection.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES } from 'qonto/constants/direct-debit-subscriptions';
import { TypeCard } from 'qonto/react/components/direct-debit-collections/type-card';

export default class FlowsDirectDebitCollectionsNewTypeSelectionComponent extends Component {
  @service segment;

  TypeCard = TypeCard;

  @action
  onTypeSelect(type) {
    this.args.context.directDebitSubscription.scheduleType =
      type === 'one-off'
        ? DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF
        : DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY;

    this.segment.track('incoming-direct-debit-flow_collection-type_selected', {
      collection_type: type,
    });

    this.args.transitionToNext();
  }
}
