/* import __COLOCATED_TEMPLATE__ from './introduction.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';

export default class SubscriptionCloseStepsIntroductinComponent extends Component {
  @service intl;
  @service organizationManager;
  @service subscriptionManager;

  get organizationName() {
    return this.organizationManager.organization.name;
  }

  get planName() {
    return this.subscriptionManager.currentPricePlan.localName;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription.recurrence;
  }

  get closingDate() {
    let endOfMonth = dayjs().endOf('month').toDate();

    if (endOfMonth.getDate() === dayjs().toDate().getDate()) {
      let endOfNextMonth = dayjs().add(1, 'month').endOf('month').toDate();
      return this.intl.formatDate(endOfNextMonth, { format: 'long' });
    }

    return this.intl.formatDate(endOfMonth, { format: 'long' });
  }

  @action onSubmit(e) {
    e.preventDefault();
    this.args.transitionToNext();
  }
}
