/* import __COLOCATED_TEMPLATE__ from './invoice-attachment.hbs'; */
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { bool } from 'macro-decorators';

export default class InvoiceAttachmentComponent extends Component {
  @bool('args.localFiles.0') hasFiles;

  get invoiceAttachmentDate() {
    return dayjs(this.args.feeCreatedDate).endOf('month').add(1, 'day');
  }
}
