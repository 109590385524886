/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';

import { KYC_STATUS } from 'qonto/constants/membership';

export default class FlowsTransfersFxNewSuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service router;
  @service segment;
  @service organizationManager;
  @service intl;

  get fxTransfer() {
    return this.args.context.fxTransfer;
  }

  get isKycPending() {
    let { kycStatus, kycSubmitted } = this.organizationManager.membership;

    return kycSubmitted && kycStatus !== KYC_STATUS.ACCEPTED;
  }

  get lottiePath() {
    if (this.isKycPending) {
      return '/lotties/transfers/timeout.json';
    }

    return '/lotties/transfers/fx-success.json';
  }

  get translationStrings() {
    if (this.isKycPending) {
      return {
        title: this.intl.t('transfers.modals.success.kyc-pending.title'),
        description: this.intl.t('transfers.modals.success.kyc-pending.description'),
        mainCTA: this.intl.t('transfers.modals.make-another-btn'),
        secondaryCTA: this.intl.t('btn.close'),
      };
    }

    return {
      title: this.intl.t('transfers.fx.new.success.title'),
      description: this.intl.t('transfers.fx.new.success.subtitle'),
      mainCTA: this.intl.t('transfers.modals.make-another-btn'),
      secondaryCTA: this.intl.t('transfers.fx.new.success.cta'),
    };
  }

  @action
  makeAnotherTransfer() {
    let { origin } = this.args.context;
    this.segment.track('transfer-swift_success_closed', {
      transfer_success_redirection: 'new_transfer',
      ...(origin && { origin }),
    });

    this.router.transitionTo('transfers.landing');
  }

  @action
  viewTransfer() {
    let { origin } = this.args.context;
    this.segment.track('transfer-swift_success_closed', {
      transfer_success_redirection: 'transfer_details',
      ...(origin && { origin }),
    });

    this.router.transitionTo('transfers.pending', {
      queryParams: { highlight: this.fxTransfer.id },
    });
  }
}
