export default {
  "cell": "YL",
  "empty": "YT",
  "supplier": "Yz",
  "supplier-content": "Ya",
  "supplier-avatar": "YH mr-16",
  "installments": "YO body-2",
  "overflow-hidden": "Ym",
  "ellipsis": "Yp",
  "completed-on": "Yx",
  "financed-amount": "Yw body-2",
  "amount": "YN",
  "total-repayment": "Yv body-1"
};
