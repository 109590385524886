/* import __COLOCATED_TEMPLATE__ from './asset.hbs'; */
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, waitForQueue } from 'ember-concurrency';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { importAsset } from 'qonto/helpers/import-asset';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const IMAGE_URLS = {
  [CARD_LEVELS.ADVERTISING]: '/illustrations/cards/illu-card-advertising-default.png',
  [CARD_LEVELS.FLASH]: '/illustrations/cards/illu-card-flash-default.png',
  [CARD_LEVELS.VIRTUAL]: '/illustrations/cards/illu-card-virtual-default.png',
  [CARD_LEVELS.STANDARD]: '/illustrations/cards/card-one.png',
  [CARD_LEVELS.PLUS]: '/illustrations/cards/card-plus.png',
  [CARD_LEVELS.METAL]: '/illustrations/cards/card-x.png',
};

const FLASH_CARD_FRENCH_IMAGE_URL = '/illustrations/cards/illu-card-flash-french.png';

const VIDEO_URLS = {
  [CARD_LEVELS.STANDARD]: '/videos/cards/card-one.webm',
  [CARD_LEVELS.PLUS]: '/videos/cards/card-plus.webm',
  [CARD_LEVELS.METAL]: '/videos/cards/card-x.webm',
};

export default class FlowsCardsChooseCardAsset extends Component {
  @service deviceManager;
  @service sentry;
  @service intl;

  constructor() {
    super(...arguments);

    if (
      !(
        this.deviceManager.isSafariDesktop ||
        this.args.isVideoPlayingOnError ||
        this.args.isImageOnly
      )
    ) {
      this.initVideoAndPosterTask.perform().catch(ignoreCancelation);
    }
  }

  initVideoAndPosterTask = dropTask(async () => {
    try {
      await waitForQueue('afterRender');

      this.initVideo();
      this.initPoster();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  @action
  initVideo() {
    let video = document.getElementById(this.args.id);
    // the video requires to be muted via js to be then be played via js
    video.muted = true;
  }

  @action
  initPoster() {
    let poster = document.getElementById(this.posterId);

    poster?.addEventListener('load', this._setIsLoaded, true);

    registerDestructor(this, () => {
      poster?.removeEventListener('load', this._setIsLoaded, true);
    });
  }

  @action
  _setIsLoaded() {
    let { cardLevel, setIsLoaded } = this.args;
    setIsLoaded(cardLevel);
  }

  get imageUrl() {
    if (this.primaryLocale === 'fr' && this.args.cardLevel === CARD_LEVELS.FLASH) {
      /* if user local is french we want to show the asset for the instant card with french writings on it */
      return importAsset([FLASH_CARD_FRENCH_IMAGE_URL]);
    }
    return importAsset([IMAGE_URLS[this.args.cardLevel]]);
  }

  get videoUrl() {
    return importAsset([VIDEO_URLS[this.args.cardLevel]]);
  }

  get posterId() {
    let { cardLevel, id } = this.args;
    return `${id}-poster-${cardLevel}`;
  }

  get primaryLocale() {
    return this.intl.locale[0];
  }
}
