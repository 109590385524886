export default {
  "wrapper": "vd",
  "content": "vI",
  "compact": "vt",
  "full-width": "vM",
  "left": "vP",
  "right-image": "vl",
  "right-lottie": "vX",
  "badge": "vC",
  "badge-icon": "vk",
  "animation": "vJ",
  "hidden": "vs"
};
