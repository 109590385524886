export default {
  "selector": "Gh",
  "metal-design": "Gf",
  "content": "GK",
  "header": "GG",
  "big": "Gr",
  "header-name": "Gb",
  "subtitle": "Gy",
  "button": "GU",
  "features": "Gj",
  "feature": "GW",
  "highlighted": "Gu"
};
