export default {
  "invite-review": "cSA",
  "invite-review-info": "cSY",
  "invite-review-info--invitee": "cSh",
  "disclaimer-button": "cSf",
  "info-block": "cSK",
  "ev": "cSG",
  "tooltip": "cSr",
  "description": "cSb"
};
