/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { ObfuscatedIban } from 'qonto/react/components/obfuscated-iban';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class FlowsDirectDebitCollectionsSubscriptionSummaryComponent extends Component {
  @service intl;
  @service sentry;
  @service router;
  @service segment;
  @service toastFlashMessages;
  @service subscriptionManager;

  get subscription() {
    return this.args.context.directDebitSubscription;
  }

  get client() {
    return this.subscription.client;
  }

  get mandate() {
    return this.subscription.mandate;
  }

  get holdAmount() {
    return (
      Number(this.subscription.amount.value) * Number(this.args.context.guardingDepositPercentage)
    );
  }

  get guardingPeriodPercentage() {
    return this.intl.formatNumber(this.args.context.guardingDepositPercentage, {
      style: 'percent',
    });
  }

  onSaveSubscriptionTask = dropTask(async subscription => {
    let withExistingMandate = Boolean(subscription.mandate?.id);

    try {
      await subscription.save();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      return;
    }

    if (withExistingMandate) {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('sdd-collections.toasts.success.autocharge')
      );

      this.router.transitionTo('direct-debit-collections.processing');
    } else {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('sdd-collections.toasts.success.request-created')
      );

      this.router.transitionTo('direct-debit-collections.processing.payment-link', subscription.id);
    }

    let {
      notifyClient,
      isOneOff,
      isRecurring,
      scheduleType,
      client,
      attachment,
      scheduleTotalCollectionCount,
    } = subscription;

    this.segment.track('incoming-direct-debit-flow_summary_confirmed', {
      notify_client: notifyClient,
      collection_type: isOneOff ? 'one_off' : 'recurring',
      ...(isRecurring && { frequency: scheduleType }),
      ...(isRecurring && {
        duration: scheduleTotalCollectionCount ? 'number_payments' : 'no_limit',
      }),
      client_address: Boolean(client.address),
      attachment: Boolean(attachment),
      guarding_percentage: this.args.context.guardingDepositPercentage,
    });
  });

  ObfuscatedIban = ObfuscatedIban;
}
