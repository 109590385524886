export default {
  "mandate-new-no-mandate": "Wh",
  "left-side": "Wf",
  "left-side__wrapper": "WK",
  "left-side__wrapper-title": "WG",
  "left-side__wrapper-details": "Wr",
  "left-side__wrapper-details-item": "Wb",
  "right-side": "Wy",
  "multi-accounts": "WU",
  "right-side__wrapper": "Wj",
  "right-side__wrapper-name": "WW",
  "right-side__wrapper-address": "Wu",
  "right-side__wrapper-iban-dashed": "uS",
  "right-side__wrapper-address-label": "uc",
  "right-side__wrapper-iban-label": "uq",
  "right-side__wrapper-bic-label": "uZ",
  "right-side__wrapper-address-dashed": "uR",
  "right-side__wrapper-bic-dashed": "uQ",
  "dropdown-icon": "ue",
  "dropdown": "uB",
  "multi-accounts-illustration": "uE"
};
