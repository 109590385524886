export default {
  "checks": "yJ",
  "content": "ys",
  "form": "yF",
  "title": "yg",
  "description": "yD",
  "wrapper": "yV",
  "aside": "yL",
  "amount-field": "yT"
};
