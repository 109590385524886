/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SubscriptionCloseStepsClosingDate } from 'qonto/react/components/subscription/close/closing-date';

export default class SubscriptionCloseStepsReasonSuccessComponent extends Component {
  @service segment;
  @tracked animationReady = false;
  SubscriptionCloseStepsClosingDate = SubscriptionCloseStepsClosingDate;

  constructor() {
    super(...arguments);
    this.segment.track('account_closing_confirmation_displayed');
  }

  @action displayLottie() {
    // see https://github.com/emberjs/ember.js/issues/17202#issuecomment-437927226
    this.animationReady = true;
  }
}
