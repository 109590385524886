/* import __COLOCATED_TEMPLATE__ from './signature.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

import {
  PAY_LATER_APPLICATION_ORIGIN,
  PAY_LATER_SIGNATURE_EVENT,
  PAY_LATER_SIGNATURE_STATUS,
} from 'qonto/constants/financing';

export default class FlowsPayLaterApplicationSignature extends Component {
  @service segment;
  @service zendeskWidget;

  signatureUrl = `${this.args.context.signature.url}&disable_domain_validation=true`;

  constructor() {
    super(...arguments);
    this.handleEvents = this.handleEvents.bind(this);
    window.addEventListener('message', this.handleEvents);
    this.zendeskWidget.hide();
  }

  handleEvents(e) {
    let status;

    if (
      e.origin === PAY_LATER_SIGNATURE_EVENT.ORIGIN &&
      e.data?.type === PAY_LATER_SIGNATURE_EVENT.TYPE
    ) {
      if (e.data.event === 'success') {
        let { origin, sepaTransfer } = this.args.context;
        let applicationFlowOrigins = Object.values(PAY_LATER_APPLICATION_ORIGIN);
        let trackingPayload = {};

        status = PAY_LATER_SIGNATURE_STATUS.SUCCESS;

        if (sepaTransfer) {
          origin = PAY_LATER_APPLICATION_ORIGIN.TRANSFER;
        }

        if (applicationFlowOrigins.includes(origin)) {
          trackingPayload = {
            origin,
          };
        }

        this.segment.track('pay-later_signed_displayed', trackingPayload);
      }

      if (e.data.event === 'error') {
        status = PAY_LATER_SIGNATURE_STATUS.ERROR;
      }
    }

    if (status) {
      this.args.context.signature.status = status;
      this.args.transitionToNext();
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.zendeskWidget.show();
    window.removeEventListener('message', this.handleEvents);
  }
}
