export default {
  "wrapper": "oT",
  "legend": "oz",
  "grid": "oa",
  "subtitle": "oH body-2",
  "fiscal-code": "oO",
  "personal-data-name": "om",
  "personal-data-birth": "op",
  "personal-data": "ox",
  "tax-residence": "ow",
  "payer-agent": "oN",
  "errors": "ov",
  "business": "oo",
  "address": "on"
};
