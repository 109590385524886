export default {
  "container": "fV",
  "container-content": "fL",
  "content": "fT",
  "underline": "fz",
  "inline-content": "fa",
  "payment-rate": "fH",
  "payment-subtitle": "fO",
  "grey-text": "fm",
  "earnings-header": "fp",
  "earnings-tooltip": "fx"
};
