export default {
  "mandate-list-item": "ckw",
  "selected": "ckN",
  "status-avatar": "ckv",
  "infos": "cko",
  "info-title": "ckn body-1",
  "info-title-suspended": "cki",
  "info-title-status-suspended": "ckA",
  "info-mandate-count": "ckY body-2"
};
