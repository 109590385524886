export default {
  "wrapper": "cPi",
  "mobile-mode": "cPA",
  "logo": "cPY",
  "address-form": "cPh",
  "button-container": "cPf",
  "address-form-header": "cPK",
  "address-form-content": "cPG",
  "address-form-address-search": "cPr",
  "ember-basic-dropdown": "cPb",
  "address-form-footer": "cPy",
  "submit-cta": "cPU",
  "form": "cPj",
  "city-section": "cPW",
  "title": "cPu",
  "mobile-title": "clS"
};
