/* import __COLOCATED_TEMPLATE__ from './choose-card-invitee.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_FLOWS_TRACKING_ORIGINS, CARD_LEVELS, ORIGIN } from 'qonto/constants/cards';

export default class FlowsCardsChooseCardInvitee extends Component {
  @service segment;
  @service organizationManager;
  @service store;
  @service router;

  showExcludingVatLabel = Boolean(this.physicalOneCardPrice || this.virtualCardPrice);

  get physicalOneCardPrice() {
    return this.args.context.estimates.standard.monthly_cost;
  }

  get virtualCardPrice() {
    return this.args.context.estimates.virtual.monthly_cost;
  }

  @action
  handlePhysicalOneCardClick() {
    this.segment.track('cards_order_physical_type_chosen', {
      origin: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION_CARD_SELECTION,
    });

    this.args.context.card = this._createCardRecord(CARD_LEVELS.STANDARD);

    let { hasMultipleActiveAccounts } = this.args.context;

    this.args.pushForwardFlow(
      'card-new-invitee-physical-one',
      hasMultipleActiveAccounts ? 'bank-account' : 'payment-limits'
    );
  }

  @action
  handleVirtualCardClick() {
    this.segment.track('cards_order_virtual_type_chosen', {
      origin: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION_CARD_SELECTION,
    });

    this.args.context.card = this._createCardRecord(CARD_LEVELS.VIRTUAL);

    let { hasMultipleActiveAccounts } = this.args.context;

    this.args.pushForwardFlow(
      'card-new-invitee-virtual',
      hasMultipleActiveAccounts ? 'bank-account' : 'payment-limits'
    );
  }

  @action handleOtherCardsClick() {
    this.segment.track('cards_order_clicked', {
      origin: CARD_FLOWS_TRACKING_ORIGINS.USER_INVITATION_CARD_SELECTION,
    });

    let { cardHolderId } = this.args.context;

    return this.router.transitionTo('cards.physical-or-virtual', {
      queryParams: { origin: ORIGIN.MEMBER_INVITATION, inviteeMembershipId: cardHolderId },
    });
  }

  _createCardRecord(cardLevel) {
    let { mainAccount, cardHolder, cardsLimits } = this.args.context;

    let cardLevelOptions = {
      [CARD_LEVELS.STANDARD]: {
        nfcOption: false,
        atmOption: false,
      },
    };

    let card = this.store.createRecord('card', {
      ...cardLevelOptions[cardLevel],
      bankAccount: mainAccount,
      cardLevel,
      organization: this.organizationManager.organization,
      holder: cardHolder,
      paymentMonthlyLimit: cardsLimits[cardLevel].payment_monthly_limit_maximum,
      atmMonthlyLimit: cardsLimits[cardLevel].atm_monthly_limit_maximum,
    });

    card.addIdempotencyKey();

    return card;
  }
}
