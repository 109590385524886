export default {
  "sidebar": "cXU",
  "top-section": "cXj",
  "title": "cXW",
  "primary-action": "cXu",
  "edit-icon": "cCS",
  "close-icon": "cCc",
  "box-wrapper": "cCq",
  "bottom-action": "cCZ",
  "related-invoices": "cCR",
  "related-invoices-title": "cCQ mb-16 caption-bold",
  "box": "cCe",
  "box-element": "cCB",
  "cancel-text": "cCE",
  "box-header": "cCd",
  "row": "cCI",
  "greyed-out": "cCt",
  "struck-through": "cCM",
  "share-mandate-link": "cCP"
};
