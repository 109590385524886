export default {
  "member-details": "cJe",
  "header": "cJB",
  "no-email": "cJE",
  "header-img": "cJd",
  "invited-persona": "cJI",
  "revoked-persona": "cJt",
  "invited-icon": "cJM",
  "revoked-icon": "cJP",
  "revised": "cJl",
  "invitable-icon": "cJX",
  "dropdown": "cJC",
  "header-body": "cJk",
  "status": "cJJ",
  "name": "cJs title-3",
  "email": "cJF caption-bold",
  "actions": "cJg body-2",
  "actions-cards": "cJD",
  "actions-transactions": "cJV",
  "body": "cJL",
  "expense-permissions-section": "cJT",
  "body-title": "cJz title-4",
  "body-attr": "cJa",
  "body-label": "cJH",
  "attr-buttons": "cJO",
  "body-role": "cJm small",
  "details-actions": "cJp",
  "actions-item": "cJx",
  "disclaimer": "cJw",
  "permissions": "cJN",
  "permission": "cJv",
  "permission-label": "cJo",
  "permission-check": "cJn",
  "permission-missing": "cJi",
  "editable-section": "cJA",
  "hris-placeholder": "cJY",
  "imported-from": "cJh",
  "integration-logo": "cJf",
  "permission-value": "cJK",
  "tooltip": "cJG",
  "check-icon": "cJr"
};
