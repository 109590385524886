import type { ReactElement } from 'react';
import { AddOnDetail } from '@repo/domain-kit';
import { Button } from 'design-system-kit';

export interface AddonCardsProps {
  addons: Addon[];
  recurrence: SubscriptionRecurrences;
  onChange: (addon: Addon) => void;
  onToggleChange: () => void;
  traits: Trait[];
}
type Trait = {
  title: string;
  name: string;
  recurrence: SubscriptionRecurrences;
};

enum SubscriptionRecurrences {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

type Price = {
  value: string;
  currency: string;
};

type Addon = {
  name: string;
  code: string;
  type: string;
  brand_name: string;
  description: string;
  description_has_decoration: boolean;
  sub_description: string | null;
  monthly_price: {
    prefix: string | null;
    per_month_amount: Price;
    per_year_amount: Price;
    period: string;
    billing_recurrence: string;
    annual_benefit: string;
  };
  annual_price: {
    prefix: string | null;
    per_month_amount: Price;
    per_year_amount: Price;
    period: string;
    billing_recurrence: string;
    annual_benefit: string;
  };
  benefit_groups: [
    {
      title: string;
      badge: string | null;
      benefits: [
        {
          description: string;
          description_has_decoration: boolean;
          is_included: boolean;
        },
        {
          description: string;
          description_has_decoration: boolean;
          is_included: boolean;
        },
      ];
    },
  ];
};

export function AddonCards({ addons, recurrence, onToggleChange }: AddonCardsProps): ReactElement {
  const addonsInfo = addons.map(
    ({ brand_name, code, sub_description, benefit_groups, monthly_price, annual_price }) => {
      const benefitsInfo = benefit_groups[0];
      const priceObjectByRecurrence =
        recurrence === SubscriptionRecurrences.MONTHLY ? monthly_price : annual_price;

      const priceMonthly = `${priceObjectByRecurrence.per_month_amount.currency}${priceObjectByRecurrence.per_month_amount.value}`;
      const priceObject =
        priceObjectByRecurrence[
          recurrence === SubscriptionRecurrences.MONTHLY ? 'per_month_amount' : 'per_year_amount'
        ];
      // TODO implement correct price calculation with format money & prefix
      const price = `${priceObject.currency}${priceObject.value}`;

      return {
        name: brand_name,
        code,
        recurrence,
        price,
        priceMonthly,
        pricePeriod: priceObjectByRecurrence.period,
        billingRecurrence: annual_price.annual_benefit,
        features: benefitsInfo.benefits.map(benefit => benefit.description),
        featuresTitle: benefitsInfo.title,
        // TODO calculate traits
        traits: [],
        subtitle: sub_description || undefined,
      };
    }
  );

  return (
    <>
      {addonsInfo.map(addon => (
        <AddOnDetail
          name={addon.name}
          annualSwitch={
            recurrence === SubscriptionRecurrences.MONTHLY ? (
              <Button
                className="caption"
                onPress={onToggleChange}
                variant="tertiary"
                data-test-addon-detail-card-billing-recurrence-button
              >
                {addon.billingRecurrence}
              </Button>
            ) : null
          }
          key={addon.code}
          recurrence={recurrence}
          subtitle={addon.subtitle}
          price={addon.price}
          priceMonthly={addon.priceMonthly}
          billingRecurrence={addon.billingRecurrence}
          pricePeriod={addon.pricePeriod}
          featuresTitle={addon.featuresTitle}
          features={addon.features}
          traits={addon.traits}
        />
      ))}
    </>
  );
}
