export default {
  "row": "id",
  "row-active": "iI",
  "cell": "it body-2",
  "terminated": "iM",
  "amount": "iP body-1",
  "item-name-cell": "il",
  "status-icon": "iX",
  "item-name-container": "iC",
  "item-info": "ik",
  "name": "iJ body-1",
  "status-displayed": "is caption"
};
