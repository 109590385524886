/* import __COLOCATED_TEMPLATE__ from './notice-info.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

const FIELD_ERRORS = ['invalid_notice_number', 'invalid_fiscal_code'];
const UNREACHABLE_COUNTERPARTY = 'unreachable_counterparty';
const NOTICE_ALREADY_PAID = 'notice_already_paid';

export default class NoticeInfoComponent extends Component {
  @service intl;
  @service toastFlashMessages;
  @service segment;

  @tracked focusedField = null;

  @reads('args.context.payment') payment;

  getToastErrorMessage(errorObject) {
    try {
      let error = errorObject.errors[0];
      if (error.code === UNREACHABLE_COUNTERPARTY) {
        return this.intl.t('pagopa.payment-creation.notice-info.errors.unreachable-counterparty');
      }

      if (error.code === NOTICE_ALREADY_PAID) {
        let detail = error.detail;
        let date = dayjs(detail);

        if (date.isValid()) {
          let paymentDate = dateToken({
            date: detail,
            locale: this.intl.locale,
            token: DATE_FORMAT_TOKENS.DATE_TIME_S,
          });

          return this.intl.t('pagopa.payment-creation.notice-info.errors.notice-already-paid', {
            paymentDate,
          });
        }
      }

      return this.intl.t('pagopa.payment-creation.notice-info.generic-error-toast-message');
    } catch {
      return this.intl.t('pagopa.payment-creation.notice-info.generic-error-toast-message');
    }
  }

  handleSubmitTask = dropTask(async () => {
    this.segment.track('pagopa_payment-flow_notice_confirm-button_clicked');

    try {
      await this.payment.verify();
    } catch (error) {
      let showToast =
        error.errors && error.errors.filter(e => !FIELD_ERRORS.includes(e.code)).length > 0;

      if (showToast) {
        this.toastFlashMessages.toastError(this.getToastErrorMessage(error));
      }

      return;
    }

    this.args.transitionToNext();
  });

  @action
  handleFieldUpdate(attribute, value) {
    if (this.paymentHasError(attribute)) {
      this.payment.errors.remove(attribute);
    }
    this.payment[attribute] = value;
  }

  @action
  handleOnFocus(attribute, isFocused) {
    this.focusedField = isFocused ? attribute : null;
  }

  paymentHasError(attribute) {
    return this.payment?.errors.has(attribute);
  }

  get noticeNumberMessageOpt() {
    if (this.paymentHasError('noticeNumber')) {
      return {
        message: this.intl.t(
          'pagopa.payment-creation.notice-info.notice-number-field.format-error',
          { htmlSafe: true }
        ),
        messageLevel: 'error',
      };
    } else if (this.focusedField === 'noticeNumber') {
      return {
        message: this.intl.t('pagopa.payment-creation.notice-info.notice-number-field.hint'),
        messageLevel: 'hint',
      };
    }
  }

  get fiscalCodeMessageOpt() {
    if (this.paymentHasError('fiscalCode')) {
      return {
        message: this.intl.t(
          'pagopa.payment-creation.notice-info.creditor-code-field.format-error',
          { htmlSafe: true }
        ),
        messageLevel: 'error',
      };
    } else if (this.focusedField === 'fiscalCode') {
      return {
        message: this.intl.t('pagopa.payment-creation.notice-info.creditor-code-field.hint'),
        messageLevel: 'hint',
      };
    }
  }
}
