/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { get } from '@ember/object';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

import { LIMIT_TYPES, WARNINGS } from 'qonto/constants/international-out/confirmation';
import { PAY_OUT, RATE_TYPE } from 'qonto/constants/international-out/quote';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import { ERROR_CODE } from 'qonto/constants/international-out/transfer';
import { ROLES } from 'qonto/constants/membership';
import { formatAmountToNumber } from 'qonto/utils/amount';
import { getAllowedDecimalPlaces } from 'qonto/utils/currency';
import { ErrorInfo } from 'qonto/utils/error-info';
import { formatAccountInformation, formatExchangeRate } from 'qonto/utils/international-out/format';

const DISCLAIMER_LEVELS = {
  ERROR: 'error',
  INFORMATION: 'info',
  WARNING: 'warning',
};

export default class FlowsTransfersInternationalOutNewSummaryComponent extends Component {
  @service internationalOutManager;
  @service intl;
  @service organizationManager;
  @service segment;
  @service sentry;
  @service toastFlashMessages;

  constructor() {
    super(...arguments);
    this.segment.track(EVENTS.TRANSFER_SUMMARY_ACCESSED, {
      pay_out: this.args.context.quote?.payOut,
    });
  }

  get disclaimers() {
    let { confirmationResult, quote } = this.args.context;

    let disclaimers = [];

    if (confirmationResult?.warnings.includes(WARNINGS.INSUFFICIENT_FUNDS)) {
      return [this.insufficientFundsDisclaimer];
    }

    if (confirmationResult?.isAboveLimit) {
      return [this.aboveLimitDisclaimer];
    }

    if (quote?.rateType === RATE_TYPE.FLOATING) {
      disclaimers.push(this.volatilityDisclaimer);
    }

    if (confirmationResult?.warnings.includes(WARNINGS.ALREADY_ISSUED)) {
      disclaimers.push(this.alreadyIssuedDisclaimer);
    }

    if (quote?.payOut === PAY_OUT.SHA) {
      disclaimers.push(this.shaDisclaimer);
    }

    return disclaimers;
  }

  get aboveLimitDisclaimer() {
    let { limitType, spendings } = this.args.context.confirmationResult;

    return {
      level: DISCLAIMER_LEVELS.ERROR,
      message:
        limitType === LIMIT_TYPES.MONTHLY
          ? this.intl.t('international-out.summary.errors.monthly-limit', {
              balanceMonthlyTransferLimit: spendings.monthlyLimit - spendings.currentMonth,
            })
          : this.intl.t('international-out.summary.errors.per-transfer-limit', {
              perTransferLimit: spendings.perTransferLimit,
            }),
    };
  }

  get alreadyIssuedDisclaimer() {
    return {
      level: DISCLAIMER_LEVELS.WARNING,
      message: this.intl.t('international-out.summary.warnings.already-sent'),
    };
  }

  get insufficientFundsDisclaimer() {
    return {
      level: DISCLAIMER_LEVELS.ERROR,
      message:
        this.organizationManager.membership.role === ROLES.MANAGER
          ? this.intl.t('international-out.summary.errors.non-admin-insufficient-funds')
          : this.intl.t('international-out.summary.errors.insufficient-funds'),
    };
  }

  get shaDisclaimer() {
    return {
      level: DISCLAIMER_LEVELS.INFORMATION,
      message: this.intl.t('international-out.summary.information.sha'),
    };
  }

  get volatilityDisclaimer() {
    return {
      level: DISCLAIMER_LEVELS.WARNING,
      message: this.intl.t('international-out.summary.warnings.volatile', {
        currency: this.args.context.quote.targetCurrency,
      }),
    };
  }

  get formattedAccountInformation() {
    let { accountIdentifier, bankIdentifier } = this.args.context.beneficiary;
    return formatAccountInformation({ accountIdentifier, bankIdentifier });
  }

  get formattedExchangeRate() {
    let value = formatExchangeRate(this.args.context.quote?.rate);
    let [, decimalPart] = value.split('.');
    return {
      value,
      decimalPlacesCount: decimalPart ? decimalPart.length : 0,
    };
  }

  get formattedArrivalTime() {
    return capitalize(this.args.context.quote.formattedEstimatedDelivery);
  }

  get feeTooltipMessage() {
    let { fees } = this.args.context;

    if (this.useSwiftNetwork) {
      return this.intl.t('international-out.summary.tooltips.fees.generic', {
        pricingFee: fees.variable,
      });
    }

    return this.intl.t('international-out.summary.tooltips.fees.local', {
      pricingFee: fees.variable,
      minimumFee: fees.minimum,
    });
  }

  get swiftFeeTooltipMessage() {
    if (this.args.context.quote?.payOut === PAY_OUT.OUR) {
      return this.intl.t('international-out.summary.tooltips.fees.our');
    }

    return this.intl.t('international-out.summary.tooltips.fees.sha');
  }

  get useSwiftNetwork() {
    return [PAY_OUT.OUR, PAY_OUT.SHA].includes(this.args.context.quote?.payOut);
  }

  get totalAmount() {
    let { quote, fees } = this.args.context;
    return formatAmountToNumber({
      amount: quote.sourceAmount + fees.total,
      precision: getAllowedDecimalPlaces(quote.sourceCurrency),
    });
  }

  get totalFeeAmount() {
    let { total, fix } = this.args.context.fees;

    let totalFeeAmount = total || 0;
    let fixedFeeAmount = fix || 0;

    if (this.useSwiftNetwork) {
      return totalFeeAmount - fixedFeeAmount;
    }

    return totalFeeAmount;
  }

  createTransferTask = dropTask(async () => {
    let { context, transitionToNext } = this.args;
    let { attachments, bankAccount, beneficiary, quote, settings, targetAccount, transferDetails } =
      context;
    let isAttachmentRequired = this.totalAmount >= settings.maximumAmountWithoutAttachment;

    this.internationalOutManager.addIdempotencyHeader();

    try {
      context.transfer = await this.internationalOutManager.createTransfer({
        bankAccountId: bankAccount.id,
        beneficiaryId: beneficiary.id,
        quoteId: quote.id,
        targetAccountId: targetAccount.id,
        sourceAmount: {
          currency: quote.sourceCurrency,
          value: this.totalAmount,
        },
        targetAmount: {
          currency: quote.targetCurrency,
          value: quote.targetAmount,
        },
        details: transferDetails,
        ...(isAttachmentRequired && { attachmentIds: attachments.map(({ id }) => id) }),
      });

      attachments
        .filter(attachment => get(attachment, 'isNew'))
        .forEach(attachment => attachment.unloadRecord());

      this.segment.track(EVENTS.TRANSFER_SUBMITTED);

      transitionToNext();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (error?.errors?.some(({ code }) => code === ERROR_CODE.QUOTE_EXPIRED)) {
        this.segment.track(EVENTS.QUOTE_EXPIRED);
      }
    } finally {
      this.internationalOutManager.removeIdempotencyHeader();
    }
  });
}
