/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { alias } from 'macro-decorators';

import ChecksDetailsValidations from 'qonto/validations/forms/checks-details';

export default class FlowsCheckDetailsComponent extends Component.extend(ChecksDetailsValidations) {
  @service segment;
  @service intl;
  @service sentry;
  @service zendeskLocalization;

  @alias('context.check') check;

  @tracked showAmountErrorMessage = false;
  @tracked showCheckErrorMessage = false;

  tagName = '';
  highlightAmount = false;
  highlightCheckId = false;

  get hasExceedingAmount() {
    return this.get('check.amount') > this.context.maxCheckDepositAmount;
  }

  /*
    This is a workaround to a known issue with ember-intl/cp-validations, where the addon tries to spread `htmlSafe: true` to
    intl.t(), which then treats it like a variable to replace in the translation key and then fails miserably.
    Link to issue :  https://github.com/ember-intl/cp-validations/issues/49
   */
  get invalidAmountErrorMessage() {
    if (this.hasExceedingAmount) {
      let options = {
        minimumFractionDigits: 0,
        currency: 'EUR',
        style: 'currency',
      };
      return this.intl.t('validations.errors.checks.amountCents', {
        checkAmount: this.intl.formatNumber(this.get('check.amount'), options),
        htmlSafe: true,
        faqUrl: this.zendeskLocalization.getLocalizedArticle(5259491),
      });
    }
    return null;
  }

  get amountErrorMessage() {
    return this.showAmountErrorMessage
      ? this.invalidAmountErrorMessage || this.validations.attrs.check.amount.message
      : null;
  }

  get cmc7ErrorMessage() {
    return this.showCheckErrorMessage ? this.validations.attrs.check.cmc7.message : null;
  }

  get rlmcErrorMessage() {
    return this.showCheckErrorMessage ? this.validations.attrs.check.rlmc.message : null;
  }

  confirmFlightTask = dropTask(async () => {
    let { check } = this;
    let { validations } = await check.validate();

    this.showAmountErrorMessage = true;
    this.showCheckErrorMessage = true;

    if (!validations.get('isValid')) {
      return;
    }

    this.segment.track('checks_deposit_amount_and_numb_submitted');

    try {
      await check.confirm();
    } catch (error) {
      let { errors = [] } = error;

      let hasAmountCentsError = errors.some(({ source }) =>
        source?.pointer?.includes('amount_cents')
      );

      this.segment.track('checks_deposit_amount_validation_failure', {
        error: hasAmountCentsError ? 'amount' : 'other',
      });

      if (error.status !== 422) {
        this.sentry.captureException(error);
      }

      return;
    }

    this.segment.track('checks_deposit_amount_validation_success');
    await this.transitionToNext();
  });

  @action
  handleAmountChange({ target: { valueAsNumber } }) {
    this.segment.track('checks_deposit_amount_entered', {
      amount_entered: valueAsNumber,
      exceeding_amount: this.hasExceedingAmount,
    });
  }

  @action
  handleUpdateCmc7(value) {
    this.showCheckErrorMessage = false;
    this.set('check.cmc7', value);
  }

  @action
  handleUpdateRlmc(value) {
    this.showCheckErrorMessage = false;
    this.set('check.rlmc', value);
  }

  @action
  handleHighlightCheckId() {
    this.setProperties({
      highlightAmount: false,
      highlightCheckId: true,
    });
  }

  @action
  handleUnHighlightCheckId() {
    this.set('highlightCheckId', false);
  }

  @action
  handleUpdateAmount(value) {
    this.check.amount = value;
    this.showAmountErrorMessage = false;
  }
}
