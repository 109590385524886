export default {
  "filter": "iY",
  "expression": "ih",
  "conditional-wrapper": "if mr-8",
  "conditional-text": "iK",
  "property-select": "iG",
  "operator-select": "ir",
  "error-message": "ib",
  "values-field": "iy",
  "input-placeholder": "iU",
  "filter-actions": "ij",
  "label-color": "iW",
  "button-container": "iu"
};
