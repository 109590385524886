export default {
  "bucket-item": "ctY",
  "button": "cth",
  "content": "ctf",
  "bucket-icon": "ctK",
  "bucket-title-container": "ctG",
  "bucket-new-badge": "ctr",
  "bucket-title": "ctb",
  "arrow-icon": "cty"
};
