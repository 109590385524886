export default {
  "step-container": "ceI",
  "budget-tooltip": "cet",
  "content": "ceM",
  "summary-description": "ceP",
  "detail": "cel",
  "separator": "ceX",
  "detail-value": "ceC",
  "color-secondary": "cek",
  "beneficiary-info": "ceJ",
  "flag": "ces",
  "currency": "ceF",
  "fees-tooltip": "ceg"
};
