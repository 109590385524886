export default {
  "container": "cZL",
  "form-core": "cZT",
  "label": "cZz",
  "request-description": "cZa",
  "inline-inputs": "cZH",
  "placeholder-label": "cZO",
  "placeholder-input": "cZm",
  "placeholder-checkbox": "cZp"
};
