/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class InContextIntegrationsSideDrawerIntegrationListComponent extends Component {
  @service segment;

  @tracked searchedValue = '';
  @tracked scrolledTop = true;

  get filteredItems() {
    return this.args.items.filter(item =>
      item.prismicData.name.toLowerCase().includes(this.searchedValue.toLocaleLowerCase())
    );
  }

  get displaySearchbar() {
    return this.args.items && this.args.items.length > 1;
  }

  @action
  updateIntegrationList(value) {
    this.searchedValue = value;
    this.segment.track('ICI_search_clicked', { page: this.args.page });
  }

  @action
  updateScrolledTop(value) {
    this.scrolledTop = value;
  }
}
