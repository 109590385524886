/* import __COLOCATED_TEMPLATE__ from './market-closed.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { CLOSED_FX_MARKET_REASONS } from 'qonto/constants/transfers';

export default class FlowsTransfersFxNewMarketClosedComponent extends Component {
  @service intl;
  @service zendeskLocalization;

  get content() {
    switch (this.args.context.closedMarketReason) {
      case CLOSED_FX_MARKET_REASONS.EU_HOLIDAY:
        return {
          title: this.intl.t('transfers.fx.market-unavailable-modale.eu-holiday.title'),
          description: this.intl.t('transfers.fx.market-unavailable-modale.eu-holiday.description'),
          link: htmlSafe(
            this.intl.t('transfers.fx.market-unavailable-modale.eu-holiday.link', {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(4359566),
            })
          ),
        };
      case CLOSED_FX_MARKET_REASONS.US_HOLIDAY:
        return {
          title: this.intl.t('transfers.fx.market-unavailable-modale.us-holiday.title'),
          description: this.intl.t('transfers.fx.market-unavailable-modale.us-holiday.description'),
          link: htmlSafe(
            this.intl.t('transfers.fx.market-unavailable-modale.us-holiday.link', {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(4359566),
            })
          ),
        };
      case CLOSED_FX_MARKET_REASONS.WEEKEND:
        return {
          title: this.intl.t('transfers.fx.market-unavailable-modale.weekend.title'),
          description: this.intl.t('transfers.fx.market-unavailable-modale.weekend.description'),
          link: htmlSafe(
            this.intl.t('transfers.fx.market-unavailable-modale.weekend.link', {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(4359566),
            })
          ),
        };
      default:
        return {
          title: this.intl.t('transfers.fx.market-unavailable-modale.no-liquidity.title'),
          description: this.intl.t(
            'transfers.fx.market-unavailable-modale.no-liquidity.description'
          ),
          link: htmlSafe(
            this.intl.t('transfers.fx.market-unavailable-modale.no-liquidity.link', {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(4359566),
            })
          ),
        };
    }
  }

  @action
  closeStep() {
    this.args.transitionToNext();
  }
}
