export default {
  "label-wrapper": "yq body-1",
  "avatar": "yZ",
  "container": "yR",
  "transaction-info": "yQ",
  "secondary": "ye",
  "amount-container": "yB",
  "amount": "yE",
  "disabled": "yd",
  "checkbox": "yI"
};
