export default {
  "wrapper": "cEb input-field mb-4",
  "error": "cEy",
  "inactive": "cEU",
  "hidden-value": "cEj",
  "percentage-sign": "cEW",
  "black": "cEu",
  "input-field": "cdS",
  "disabled": "cdc"
};
