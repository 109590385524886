/* import __COLOCATED_TEMPLATE__ from './no-mandate.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

export default class NoMandatesComponent extends Component {
  @service toastFlashMessages;
  @service intl;
  @service organizationManager;

  @reads('organizationManager.organization') organization;
  @reads('organizationManager.membership') membership;
  @reads('organizationManager.currentAccount') bankAccount;

  get hasMultiAccounts() {
    return this.organization.hasMultipleActiveAccounts;
  }

  @action
  handleDownloadRIB(bankAccount) {
    window.open(bankAccount.ibanPdfUrl);
  }

  @action
  handleErrorCopy() {
    let message = this.intl.t('mandates.copy-clipboard.fail');
    this.toastFlashMessages.toastError(message);
  }
}
