/* import __COLOCATED_TEMPLATE__ from './voucher-information-modal.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { BadgeHighlight } from 'design-system-kit';
import { task } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class VoucherInformationModalComponent extends Component {
  badgeHighlight = BadgeHighlight;

  @service intl;
  @service store;

  @tracked pricePlanCondition;

  constructor() {
    super(...arguments);
    if (this.args.data.voucherPricePlanIds?.length) {
      this.setPricePlanConditionTask.perform().catch(ignoreCancelation);
    }
  }

  get conditions() {
    let { conditions = [] } = this.args.data;
    return [...conditions, this.minimumNumberCondition, this.pricePlanCondition].filter(Boolean);
  }

  get minimumNumberCondition() {
    if (this.args.data.minimumRequiredActiveUsersCount) {
      return this.intl.t('invitation-flow.recap.modal.eligibility-criteria.item-1', {
        minimumActiveUsers: this.args.data.minimumRequiredActiveUsersCount,
      });
    }
  }

  setPricePlanConditionTask = task(async () => {
    let { voucherPricePlanIds } = this.args.data;
    if (voucherPricePlanIds.length === 1) {
      let { localName: planName } = await this.store.findRecord(
        'price-plan',
        voucherPricePlanIds[0]
      );
      this.pricePlanCondition = this.intl.t(
        'invitation-flow.recap.modal.eligibility-criteria.item-2-single',
        { planName }
      );
    } else {
      let allPricePlans = await this.store.findAll('price-plan');
      let planNames = voucherPricePlanIds
        .map(pricePlanId => allPricePlans.find(({ id }) => id === pricePlanId)?.localName)
        .filter(Boolean)
        .join(', ');

      this.pricePlanCondition = this.intl.t(
        'invitation-flow.recap.modal.eligibility-criteria.item-2-multiple',
        { planNames }
      );
    }
  });
}
