/* import __COLOCATED_TEMPLATE__ from './item-selector.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { modifier } from 'ember-modifier';

import { TRANSITION_SIDEBAR_TIME } from 'qonto/constants/sidebar';

export default class ItemSelectorComponent extends Component {
  @tracked _selectedItemIds = [];
  @tracked _highlightedItemId = null;
  autoHighlightIds = [];

  get selectedItemIds() {
    return this.args.selectedItemIds ?? this._selectedItemIds;
  }

  get selectedItems() {
    return this.args.items?.filter(item => this.selectedItemIds.includes(item.id));
  }

  get allSelected() {
    return this.selectedItems.length === this.args.items?.length && this.args.items.length > 0;
  }

  get someSelected() {
    return this.selectedItems.length > 0 && !this.allSelected;
  }

  get hasFeaturedItems() {
    return this.someSelected || this.allSelected || Boolean(this.highlightedItem);
  }

  get highlightedItem() {
    let { highlightedItemId, items } = this.args;

    if (highlightedItemId) {
      return items?.find(item => item.id === highlightedItemId);
    }
    return items?.find(item => item.id === this._highlightedItemId);
  }

  @action
  toggleSelectedItem(itemId) {
    this.clearHighlight();

    let newItems = [];
    let isItemSelected = false;
    if (this.selectedItemIds.includes(itemId)) {
      newItems = this.selectedItemIds.filter(id => itemId !== id);
    } else {
      isItemSelected = true;
      newItems = [...this.selectedItemIds, itemId];
    }

    if (this.args.didSelectItems) {
      this.args.didSelectItems(newItems, { isOneItemSelected: isItemSelected });
    } else {
      this._selectedItemIds = newItems;
    }
  }

  @action
  toggleAllSelected() {
    this.clearHighlight();

    let itemIds = this.args.items.map(item => item.id);
    let newItems = [];
    if (this.allSelected || this.someSelected) {
      newItems = [];
    } else {
      newItems = itemIds;
    }

    if (this.args.didSelectItems) {
      this.args.didSelectItems(newItems, { isAllItemsSelected: newItems.length ? true : false });
    } else {
      this._selectedItemIds = newItems;
    }
  }

  @action
  highlightItem(itemId) {
    let { didHighlightItem, afterHighlight } = this.args;
    if (this.someSelected || this.allSelected) {
      this.toggleSelectedItem(itemId);
      this._highlightedItemId = null;
    } else {
      if (didHighlightItem) {
        didHighlightItem(itemId);
      } else {
        this._highlightedItemId = itemId;
      }

      afterHighlight?.(this.highlightedItem);
    }
  }

  @action
  clearHighlight() {
    if (this.args.clearHighlight) {
      this.args.clearHighlight();
    } else if (this.args.didHighlightItem) {
      this.args.didHighlightItem(null);
    } else {
      this._highlightedItemId = null;
    }
  }

  @action
  clearSelected() {
    if (this.args.didSelectItems) {
      this.args.didSelectItems(null);
    } else {
      this._selectedItemIds = [];
    }
  }

  @action
  clearFeaturedItems() {
    this.clearHighlight();
    this.clearSelected();
  }

  @action
  focusSidebar(idToFocus) {
    // Waiting for the animation to finish to focus the close button
    setTimeout(
      () => {
        document.getElementById(idToFocus)?.focus();
      },
      this.highlightedItem ? 0 : TRANSITION_SIDEBAR_TIME
    );
  }

  onItemKeydown = modifier((element, [itemId], { autoHighlight = true }) => {
    assert('itemId argument is required in ItemSelector.onItemKeydown modifier', itemId);
    element.dataset.itemId = itemId;
    if (autoHighlight) {
      this.autoHighlightIds.push(itemId);
    }
    element.addEventListener('keydown', this.highlightWithKeyboard);
    return () => {
      this.autoHighlightIds = this.autoHighlightIds.filter(id => id !== itemId);
      element.removeEventListener('keydown', this.highlightWithKeyboard);
    };
  });

  @action
  highlightWithKeyboard(event) {
    let itemId = event.currentTarget.dataset?.itemId;

    if (!itemId) {
      return this.highlightWithKeyboardLegacy(event);
    }

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      if (this.args.sidebarIdToFocus) {
        this.focusSidebar(this.args.sidebarIdToFocus);
      }
      this.highlightItem(itemId);
      return;
    }

    if (this.selectedItemIds.length || !this.highlightedItem) return;

    let { items } = this.args;
    let item = items.find(item => String(item.id) === itemId);
    let index = items.indexOf(item);
    let newIndex;

    if (event.key === 'ArrowUp' || event.key === 'k') {
      let isFirst = index === 0;
      if (isFirst) {
        return;
      }
      newIndex = index - 1;
    } else if (event.key === 'ArrowDown' || event.key === 'j') {
      let isLast = index === items.length - 1;
      if (isLast) {
        return;
      }
      newIndex = index + 1;
    } else if (event.key === 'Escape') {
      this.clearHighlight();
      return;
    } else {
      return;
    }

    let newItem = items[newIndex];
    event.currentTarget.parentNode.querySelector(`[data-item-id="${newItem.id}"]`)?.focus();

    if (this.autoHighlightIds.includes(newItem.id)) {
      this.highlightItem(newItem.id);
    }
  }
}
