/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from 'design-system-kit';
import window from 'ember-window-mock';

export default class SuccessComponent extends Component {
  lottiePlayer = LottiePlayer;

  @service segment;

  constructor(...args) {
    super(...args);

    this.segment.track('onboarding_top_up_success');
    window.sessionStorage.removeItem('payment_activation_context');
  }
}
