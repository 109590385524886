/* import __COLOCATED_TEMPLATE__ from './beneficiaries.hbs'; */
import { action, setProperties } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { restartableTask, task } from 'ember-concurrency';

import { EVENTS } from 'qonto/constants/international-out/tracking';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsTransfersInternationalOutNewBeneficiariesComponent extends Component {
  @service internationalOutManager;
  @service intl;
  @service modals;
  @service segment;
  @service sentry;
  @service toastFlashMessages;

  @tracked beneficiaries = [];
  @tracked isError = false;

  constructor() {
    super(...arguments);
    this.loadBeneficiariesTask.perform().catch(ignoreCancelation);
  }

  @action
  deleteBeneficiary(beneficiary) {
    this.#cancelTasks([this.selectBeneficiaryTask]);
    this.modals.open('popup/destructive', {
      title: this.intl.t('international-out.beneficiaries.delete.title'),
      description: this.intl.t('international-out.beneficiaries.delete.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('international-out.beneficiaries.delete.confirm'),
      confirmTask: this.deleteBeneficiaryTask,
      beneficiary,
    });
  }

  @action
  transitionToCreationFlow() {
    this.segment.track(EVENTS.NEW_BENEFICIARY);
    this.args.context.additionalRequirements = null;
    this.args.pushForwardFlow('international-out-beneficiary', 'create');
  }

  @action
  viewBeneficiary(beneficiary) {
    this.#cancelTasks([this.selectBeneficiaryTask]);
    this.segment.track(EVENTS.VIEW_BENEFICIARY);
    this.modals.open('transfers/international-out/modals/beneficiary', {
      beneficiary,
    });
  }

  deleteBeneficiaryTask = task(async (close, { beneficiary }) => {
    try {
      await this.internationalOutManager.deleteBeneficiary(beneficiary);
      this.beneficiaries = this.beneficiaries.filter(({ id }) => id !== beneficiary.id);
      this.toastFlashMessages.toastInfo(
        this.intl.t('international-out.beneficiaries.delete.success')
      );
      this.segment.track(EVENTS.DELETE_BENEFICIARY);
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } finally {
      close();
    }
  });

  loadBeneficiariesTask = task(async () => {
    this.isError = false;

    try {
      this.beneficiaries = (
        await this.internationalOutManager.getBeneficiaries(this.args.context.quote.targetCurrency)
      ).sort(this.#sortByName);
    } catch {
      this.isError = true;
    }
  });

  selectBeneficiaryTask = restartableTask(async beneficiary => {
    this.#cancelTasks([this.deleteBeneficiaryTask]);

    let { context, transitionToNext } = this.args;
    let { quote } = context;

    this.segment.track(EVENTS.SELECT_BENEFICIARY);

    context.additionalRequirements = null;

    try {
      let additionalRequirements =
        await this.internationalOutManager.getAdditionalBeneficiaryRequirements({
          quoteId: quote.id,
          beneficiary,
        });

      context.additionalRequirements = additionalRequirements.length
        ? additionalRequirements
        : null;

      if (!context.additionalRequirements) {
        let {
          fees,
          quote: patchedQuote,
          targetAccountId,
        } = await this.internationalOutManager.updateBeneficiary({
          quoteId: quote.id,
          beneficiaryId: beneficiary.id,
          currency: beneficiary.currency,
          type: beneficiary.paymentType,
        });

        setProperties(context, {
          fees,
          quote: patchedQuote,
          targetAccount: { id: targetAccountId },
        });
      }

      context.beneficiary = beneficiary;

      transitionToNext();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  #cancelTasks = taskObjects => {
    for (let task of taskObjects) {
      if (!task.isRunning) {
        continue;
      }

      task.cancelAll();
    }
  };

  #sortByName = (firstBeneficiary, secondBeneficiary) =>
    firstBeneficiary.name
      .trim()
      .toUpperCase()
      .localeCompare(secondBeneficiary.name.trim().toUpperCase());
}
