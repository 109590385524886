/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { SUBSCRIPTION_RECURRENCES, TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

export default class FlowsSubscriptionChangeConfirmComponent extends Component {
  @service intl;
  @service segment;
  @service subscriptionManager;
  @service organizationManager;

  constructor() {
    super(...arguments);
    this.sendTrackingEventOnLoad();
  }

  sendTrackingEventOnLoad() {
    let { context } = this.args;

    this.segment.track('plans_confirmation_displayed', {
      insufficient_funds: context.hasInsufficientFunds,
      target_plan: context.subscription.pricePlan.get('code'),
      recurrence: context.recurrence,
    });
  }

  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;

  get currency() {
    return this.args.context.estimatedPrice.currency;
  }

  get selectedPricePlan() {
    return this.args.context.subscription.pricePlan;
  }

  get currentPricePlan() {
    return this.subscriptionManager.currentPricePlan;
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get activeTrialMonthDuration() {
    return this.subscriptionManager.currentSubscription?.activeTrial.month_duration;
  }

  get intialTrialCallouts() {
    return [
      this.intl.t('subscription.change.confirmation.trial.callout:bullet-1', {
        newPlanName: this.selectedPricePlan.get('localName'),
        monthDuration: this.activeTrialMonthDuration,
      }),
      this.intl.t('subscription.change.confirmation.trial.callout:bullet-2', {
        previousPlanName: this.currentPricePlan.localName,
      }),
      this.intl.t('subscription.change.confirmation.trial.callout:bullet-3'),
    ];
  }

  get isDownsize() {
    return (
      this.args.context.subscription.recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY &&
      this.subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL
    );
  }

  get isUpsize() {
    return (
      this.args.context.subscription.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL &&
      this.subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.MONTHLY
    );
  }

  get showDisclaimerLegacyPricePlan() {
    return this.currentPricePlan.disabled;
  }

  get nextBillingDateFormatted() {
    return dateToken({
      date: this.estimatedPrice?.next_billing_date,
      locale: this.intl.locale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });
  }

  get showDisclaimerForItalianOrganizations() {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get italianDisclaimerText() {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-price-plans-confirm-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  get estimatedPrice() {
    return this.args.context.estimatedPrice;
  }
  get warnings() {
    let filteredWarnings = this.args.context.warnings.filter(
      warning =>
        !['active_discount', 'discounts_not_applicable', 'legacy_plan_disabled'].includes(
          warning.code
        )
    );

    let { warningTranslations } = this;
    return filteredWarnings
      .filter(warning => warningTranslations[warning.code])
      .map(warning => warningTranslations[warning.code]);
  }

  get warningTranslations() {
    let oldPlan = this.subscriptionManager.currentPricePlan;
    let newPlan = this.selectedPricePlan;
    let sepaPrice = newPlan.get('optionsObject').sepaOutboundTransfer?.value;

    return {
      advertising_cards_disallowed: this.intl.t(
        'subscription.change.confirmation.warnings.advertising'
      ),
      feature_bookkeeping_disabled: this.intl.t(
        'subscription.change.confirmation.warnings.bookkeeping'
      ),
      flash_cards_disallowed: this.intl.t('subscription.change.confirmation.warnings.flash'),
      manager_role_disallowed: this.intl.t('subscription.change.confirmation.warnings.manager'),
      included_transfers_decrease: this.intl.t(
        'subscription.change.confirmation.warnings.transfers-decrease',
        {
          count1: oldPlan.sepaOutLimit,
          count2: newPlan.get('sepaOutLimit'),
          transferPrice: this.intl.formatNumber(sepaPrice, {
            displayUndefined: true,
            currency: this.currency,
            style: 'currency',
          }),
        }
      ),
      included_checks_decrease: this.intl.t(
        'subscription.change.confirmation.warnings.checks-decrease',
        { count1: oldPlan.checkLimit, count2: newPlan.get('checkLimit') }
      ),
      legacy_plan_disabled: this.intl.t('subscription.change.confirmation.warnings.legacy-plan'),
      feature_requests_disabled: this.intl.t(
        'subscription.change.confirmation.warnings.pending-requests'
      ),
      feature_supplier_invoices_pending_disabled: this.intl.t(
        'subscription.change.confirmation.warnings.supplier-invoices-pending-requests'
      ),
      connect_ebics_integrations_disabled: this.intl.t(
        'subscription.change.confirmation.warnings.connect.ebics'
      ),
      connect_plus_integrations_disabled: this.intl.t(
        'subscription.change.confirmation.warnings.connect.api-integrations',
        { htmlSafe: true }
      ),
    };
  }

  get activeDiscountWarning() {
    return this.args.context.warnings.find(({ code }) => code === 'active_discount');
  }

  @action
  onKeepCurrentPlan() {
    this.segment.track('plans_change_confirmation_rejected', {
      current_plan: this.currentPricePlan.get('code'),
      target_plan: this.selectedPricePlan.get('code'),
      recurrence: this.args.context.recurrence,
    });

    this.args.transitionToPrevious();
  }

  savePlanTask = dropTask(async () => {
    this.segment.track('plans_change_confirmation_clicked', {
      current_plan: this.currentPricePlan.get('code'),
      target_plan: this.selectedPricePlan.get('code'),
      trial_state: this.hasInitialTrial
        ? TRACKING_TRIAL_STATE.INITIAL_TRIAL
        : TRACKING_TRIAL_STATE.NONE,
      end_date: this.subscriptionManager.currentSubscription.activeTrial?.end_date,
      recurrence: this.args.context.recurrence,
    });

    try {
      await this.args.context.subscription.save();
      await this.subscriptionManager.refresh();
      this.args.context.hasUpgradedPlan = true;
      this.args.transitionToNext();
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      return error;
    }
  });
}
