/* import __COLOCATED_TEMPLATE__ from './transfer-requirements.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { EVENTS } from 'qonto/constants/international-out/tracking';
import { formatAmountToNumber } from 'qonto/utils/amount';
import { getAllowedDecimalPlaces } from 'qonto/utils/currency';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsTransfersInternationalOutNewTransferRequirements extends Component {
  @service internationalOutManager;
  @service intl;
  @service segment;
  @service sentry;
  @service toastFlashMessages;

  @tracked requirements = [];
  @tracked isError = false;

  constructor() {
    super(...arguments);
    this.loadInitialRequirementsTask.perform().catch(ignoreCancelation);
  }

  get hasRequiredFields() {
    return Boolean(
      this.requirements[0]?.fields
        .map(({ group }) => group)
        .flat()
        .some(({ required }) => required)
    );
  }

  get subtitle() {
    if (this.hasRequiredFields) {
      return this.intl.t('international-out.transfer-requirements.subtitle.required');
    }

    return this.intl.t('international-out.transfer-requirements.subtitle.optional');
  }

  loadInitialRequirementsTask = dropTask(async () => {
    this.isError = false;

    try {
      this.requirements = await this.internationalOutManager.getTransferRequirements({
        quoteId: this.args.context.quote.id,
        targetAccountId: this.args.context.targetAccount.id,
      });
    } catch {
      this.isError = true;
    }
  });

  onSubmitTask = dropTask(async (confirmTask, { data: transferDetails }) => {
    let { context, transitionToNext } = this.args;
    let { fees, quote, settings } = context;

    context.transferDetails = transferDetails;

    let totalAmount = formatAmountToNumber({
      amount: quote.sourceAmount + fees.total,
      precision: getAllowedDecimalPlaces(quote.sourceCurrency),
    });

    let isAttachmentRequired = totalAmount >= settings.maximumAmountWithoutAttachment;

    if (isAttachmentRequired || (await confirmTask.perform())) {
      this.segment.track(EVENTS.TRANSFER_REQUIREMENTS_SUBMITTED);
      transitionToNext();
    }
  });

  refreshRequirementsTask = dropTask(async ({ data: transferDetails }) => {
    try {
      this.requirements = await this.internationalOutManager.getTransferRequirements({
        quoteId: this.args.context.quote.id,
        targetAccountId: this.args.context.targetAccount.id,
        details: transferDetails,
      });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}
