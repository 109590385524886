/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';
import { DISCLAIMER_TYPES, SPEND_LIMIT_TYPES } from 'qonto/constants/transfers';
import { prepareLimitsData } from 'qonto/utils/transfers';

export default class FlowsFxTransferSummaryComponent extends Component {
  @service intl;
  @service organizationManager;
  @service segment;
  @service store;
  @service abilities;

  constructor() {
    super(...arguments);

    let { fx_estimate, estimated_price, warnings } = this.args.context.confirmationResult;
    let estimatedPrice = this.intl.formatMoney(estimated_price.amount, {
      currency: estimated_price.amount_currency,
    });
    let rate = this.intl.formatMoney(fx_estimate.rate, {
      currency: this.fxTransfer.get('amountCurrency'),
      minimumFractionDigits: 4,
    });
    let localRate = this.intl.formatMoney(1, {
      currency: fx_estimate.local_amount_currency,
    });

    this.segment.track('transfer-swift_summary_displayed', {
      'swift-warnings': warnings,
    });

    this.fxTransfer.setProperties({
      estimatedPrice,
      rate,
      localRate,
      amount: fx_estimate.amount,
      amountCurrency: fx_estimate.amount_currency,
    });
  }

  get fxTransfer() {
    return this.args.context.fxTransfer;
  }

  get organization() {
    return this.fxTransfer.get('organization');
  }

  get beneficiary() {
    return this.fxTransfer.get('beneficiary');
  }

  get disclaimers() {
    return this._getConfirmDisclaimers({
      warnings: this.args.context.confirmationResult.warnings,
      role: this.organizationManager.membership.role,
    });
  }

  get canSeeBalance() {
    return this.abilities.can('see balance bankAccount');
  }

  get limitsData() {
    let { warnings, spendLimits } = this.args.context.confirmationResult;
    return prepareLimitsData(warnings, spendLimits);
  }

  get insufficientFundsDisclaimer() {
    return this.disclaimers?.find(({ type }) => type === DISCLAIMER_TYPES.INSUFFICIENT_FUNDS);
  }

  get otherDisclaimers() {
    return this.disclaimers?.filter(({ type }) => type !== DISCLAIMER_TYPES.INSUFFICIENT_FUNDS);
  }

  get aboveLimitsDisclaimer() {
    let { aboveLimitType, monthly, monthSpendings, perTransfer } = this.limitsData;

    return aboveLimitType === SPEND_LIMIT_TYPES.MONTHLY
      ? this.intl.t('transfers.swift.disclaimers.monthly-limit', {
          balance_monthly_transfer_limit: monthly - monthSpendings,
        })
      : this.intl.t('transfers.swift.disclaimers.per-transfer-limit', {
          per_transfer_limit: perTransfer,
        });
  }

  get shoudDisplayKycDisclaimer() {
    let { kycAccepted } = this.organizationManager.membership;

    return !kycAccepted;
  }

  get kycPendingDisclaimer() {
    return {
      level: 'warning',
      message: this.intl.t('transfers.warnings.fx-kyc-waiting'),
    };
  }

  saveTransferTask = dropTask(async fxTransfer => {
    fxTransfer.addIdempotencyHeader();

    let response = await fxTransfer.saveFx();

    this.store.pushPayload(response);
    this.args.context.fxTransfer = this.store.peekRecord('transfer', response.transfer.id);

    // destroy attachments,
    // indeed we send the attachments within the transfer
    // and get back attachmentsIds.
    let savedAttachements = fxTransfer.get('attachments').filter(item => get(item, 'isNew'));
    savedAttachements.forEach(a => a.unloadRecord());

    fxTransfer.removeIdempotencyHeader();

    let hasLabels = Boolean(fxTransfer.labels.length);
    let hasNote = Boolean(fxTransfer.note);
    let hasEmail = Boolean(fxTransfer.email);
    let { origin } = this.args.context;

    this.segment.track('transfer-swift_summary_confirmed', {
      ...(hasLabels && { labels: hasLabels }),
      ...(hasNote && { note: hasNote }),
      ...(hasEmail && { email: hasEmail }),
      ...(origin && { origin }),
    });

    this.args.transitionToNext();
  });

  _getConfirmDisclaimers({ role, warnings }) {
    let disclaimers = this._getConfirmDisclaimersList(role);
    let selectDisclaimer = disclaimer => warnings.find(warning => warning === disclaimer.type);
    return disclaimers.filter(selectDisclaimer);
  }

  _getConfirmDisclaimersList(role) {
    return [
      {
        type: DISCLAIMER_TYPES.INSUFFICIENT_FUNDS,
        level: 'error',
        message:
          role === ROLES.MANAGER
            ? this.intl.t('transfers.warnings.manager.insufficient_funds')
            : this.intl.t('validations.errors.fx_insufficient_funds'),
      },
      {
        type: DISCLAIMER_TYPES.ALREADY_ISSUED,
        level: 'warning',
        message: this.intl.t('transfers.warnings.already_issued'),
      },
      {
        type: DISCLAIMER_TYPES.KYB_WAITING,
        level: 'warning',
        message: this.intl.t('transfers.warnings.fx-kyb-waiting'),
      },
    ];
  }
}
