/* import __COLOCATED_TEMPLATE__ from './bank-details.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import LOCALE_ARRAY from 'qonto/constants/locales';

export default class BankDetailsComponent extends Component {
  @service organizationManager;
  @service userManager;

  get recommendedPrice() {
    return this.args.context.recommendedPrice;
  }

  get bankAccount() {
    return this.organizationManager.organization.mainAccount;
  }

  get currentUserLocale() {
    let locale = LOCALE_ARRAY.find(
      item => item.code === this.userManager.currentUser.localeObject.code
    );
    return locale ? locale.code : 'en';
  }
}
