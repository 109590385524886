export default {
  "details": "csL",
  "details--ff": "csT",
  "header-container": "csz",
  "icon": "csa",
  "line-container": "csH",
  "info-line": "csO",
  "body-details": "csm",
  "body-details-header": "csp",
  "body-details-1": "csx",
  "body-details-2": "csw",
  "body-details-3": "csN",
  "body-details-4": "csv"
};
