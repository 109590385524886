export default {
  "wrapper": "cRd",
  "confirm": "cRI",
  "plan-info": "cRt",
  "plan-info-left": "cRM",
  "info-title": "cRP body-1",
  "initial-trial-content": "cRl",
  "extra-fees-item": "cRX body-2",
  "extra-fees-container": "cRC",
  "bottom-wrapper": "cRk",
  "total-price-container": "cRJ",
  "extra-fees-element": "cRs body-1",
  "border": "cRF",
  "subtitle": "cRg body-2",
  "group-item": "cRD",
  "amount": "cRV",
  "subscription-billing-summary": "cRL",
  "summary": "cRT",
  "disclaimer": "cRz",
  "disclaimer-revamp": "cRa",
  "error": "cRH",
  "error-link": "cRO",
  "warning-wrapper": "cRm",
  "warning-label": "cRp body-1"
};
