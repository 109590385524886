export default {
  "container": "Af",
  "header-wrapper": "AK mb-16",
  "header": "AG",
  "logo": "Ar mr-16",
  "title": "Ab title-4",
  "description": "Ay body-2",
  "conditions": "AU",
  "label": "Aj caption mb-4",
  "tags": "AW"
};
