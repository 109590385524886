export default {
  "animated": "Ky",
  "container": "KU",
  "cards-selectors": "Kj",
  "card-selector": "KW",
  "selector": "Ku",
  "header": "GS",
  "footer": "Gc",
  "skip-cta": "Gq",
  "pricing-caption": "GZ",
  "show": "GR",
  "selectorFadeIn": "GQ",
  "fadeIn": "Ge",
  "asset-one": "GB",
  "onePlusAssetFadeIn": "GE",
  "assetMoveUp": "Gd",
  "asset-plus": "GI",
  "asset-x": "Gt",
  "xAssetFadein": "GM",
  "feature": "GP",
  "highlighted": "Gl"
};
