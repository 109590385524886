export default {
  "step-container": "jm",
  "content": "jp",
  "section": "jx",
  "section-content": "jw",
  "section-content-text": "jN",
  "section-illustration": "jv",
  "close-form-cta": "jo",
  "bank-account-select": "jn"
};
