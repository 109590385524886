/* import __COLOCATED_TEMPLATE__ from './plans.hbs'; */
import { action, set, setProperties } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { decamelize } from '@ember/string';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer, Spinner, ToggleButton } from 'design-system-kit';
import { all, dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';
import { PRICE_PLAN_FEATURES, PRICE_PLANT_TRAITS } from 'qonto/constants/price-plan';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FlowsSubscriptionChangePlansComponent extends Component {
  lottiePlayer = LottiePlayer;

  spinner = Spinner;

  toggleButton = ToggleButton;

  @service intl;
  @service store;
  @service router;
  @service modals;
  @service errors;
  @service segment;
  @service toastFlashMessages;
  @service subscriptionManager;
  @service organizationManager;
  @service networkManager;
  @service sentry;

  @tracked pricePlans = [];
  @tracked overviewPricePlans = [];
  @tracked selectedPricePlanCode = null;
  @tracked recommendedPricePlan = null;
  @tracked shouldSkipPlansStep = true; // Set true as default to prevent show plan step when skipping to confirm step
  @tracked isRedirectionPending = false;

  fetchPlansPromise = null;

  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;

  constructor() {
    super(...arguments);
    next(() => {
      this.initTask.perform().catch(ignoreCancelation);
    });
  }

  initTask = dropTask(async () => {
    try {
      let {
        plan: selectedPricePlanCode,
        recurrence: selectedPricePlanRecurrence = SUBSCRIPTION_RECURRENCES.ANNUAL,
      } = this.router.currentRoute.queryParams;

      this.fetchPlansPromise = this.store.findAll('price-plan');

      if (selectedPricePlanCode) {
        this.isRedirectionPending = true;
        try {
          this.pricePlans = await this.fetchPlansPromise;
          await this.confirmPlanTask.perform(selectedPricePlanCode, selectedPricePlanRecurrence);
        } catch (error) {
          this.errors.handleError(error);
        }
      }
      this.isRedirectionPending = false;
      this.shouldSkipPlansStep = false;
      await this.fetchPricePlansTask.perform();
    } catch {
      // ignore errors
    }
  });

  lineups = [
    {
      value: 'solo',
      label: this.intl.t('subscription.change.plans.solo.lineup-toggle'),
    },
    {
      value: 'teams',
      label: this.intl.t('subscription.change.plans.teams.lineup-toggle'),
    },
  ];

  get currentPricePlanCode() {
    return this.subscriptionManager.currentPricePlan.code;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription.recurrence;
  }

  get overviewPricePlansByLineup() {
    return this.overviewPricePlans.filter(({ lineup }) => lineup === this.args.context.lineup);
  }

  get activeTrial() {
    if (!this.subscriptionManager.currentSubscription?.activeTrial?.previous_plan_id) {
      return false;
    }
    return this.subscriptionManager.currentSubscription?.activeTrial;
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription.hasInitialTrial;
  }

  get initialTrialEndDate() {
    return this.subscriptionManager.currentSubscription.activeTrial?.end_date;
  }

  get showDisclaimerForItalianOrganizations() {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }
  get italianDisclaimerText() {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-price-plans-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  @action
  selectRecurrence(recurrence) {
    this.args.context.recurrence = recurrence;
    this.segment.track('recurrence_toggle_clicked', { recurrence });
  }

  @action
  selectLineup(lineup) {
    this.args.context.lineup = lineup;
    this.segment.track('plans_toggle_clicked', {
      current_plan: this.currentPricePlanCode,
      lineup,
    });
  }

  @action
  isFreeTrialPlan(pricePlanCode, rec, traits = []) {
    let traitsByRecurrence = this.#getTraitByRecurrence(traits, rec);
    return traitsByRecurrence[0]?.name === PRICE_PLANT_TRAITS.free_trial;
  }

  #getTraitByRecurrence(traits, recurrence) {
    return traits.filter(trait => trait.recurrence === recurrence);
  }

  @action
  isActiveTrialPlan(pricePlanCode, recurrence) {
    return this.activeTrial ? this.isActivePlan(pricePlanCode, recurrence) : false;
  }

  isSamePlan(pricePlanCode) {
    let { pricePlan } = this.subscriptionManager.currentSubscription;
    // The 2023 price plans don't have the same code as the previous plans (e.g. "solo_basic" vs "solo_basic_2023")
    // However we want to match them with their alternative legacy plans so we remove manually the suffix
    // All customers should have been migrated on new "2023" plans by the end of the year this should be removed in 2024
    return pricePlanCode.replace('_2023', '') === pricePlan.get('groupCode');
  }

  @action
  isActivePlan(pricePlanCode, rec) {
    return this.isSamePlan(pricePlanCode) && this.currentPricePlanRecurrence === rec;
  }

  @action
  isOnDifferentRecurrence(pricePlanCode, rec) {
    let { recurrence } = this.subscriptionManager.currentSubscription;
    return this.isSamePlan(pricePlanCode) && recurrence !== rec;
  }

  getRecommendedPricePlan(pricePlans) {
    let pricePlan = pricePlans.find(pricePlan => {
      return pricePlan.traits.find(item => item.name === PRICE_PLANT_TRAITS.recommended);
    });
    return pricePlan;
  }

  fetchPricePlansTask = dropTask(async () => {
    this.args.context.lineup = 'solo';

    let currentPricePlanId = this.subscriptionManager.currentPricePlan.id;
    let legalCountry = this.organizationManager.organization.legalCountry;
    let url = `${apiBaseURL}/${apiNamespace}/price_plan_offers/${currentPricePlanId}/plan_change?legal_country=${legalCountry}&current_recurrence=${this.currentPricePlanRecurrence}`;

    let { upsellTrigger } = this.args.context;

    if (upsellTrigger) {
      url = `${url}&reason_to_upgrade=${decamelize(upsellTrigger)}`;

      // Add a sentry call to understand what flow uses a wrong upsell trigger value
      if (!PRICE_PLAN_FEATURES.includes(decamelize(upsellTrigger))) {
        this.sentry.captureMessage(
          `called price plan endpoint with wrong reason_to_upgade value: ${upsellTrigger}`,
          'info'
        );
      }
    }
    let [response, pricePlans] = await all([
      this.networkManager.request(url),
      this.fetchPlansPromise,
    ]);

    let recommendedPricePlan = this.getRecommendedPricePlan(response.price_plans);
    this.setActiveLineup(recommendedPricePlan);

    this.overviewPricePlans = response.price_plans;
    this.pricePlans = pricePlans;
  });

  setActiveLineup(recommendedPricePlan) {
    let { lineup } = this.router.currentRoute.queryParams;
    if (lineup) {
      this.args.context.lineup = lineup;
    } else if (recommendedPricePlan) {
      this.args.context.lineup = recommendedPricePlan.lineup;
    } else {
      let { lineup } = this.subscriptionManager.currentPricePlan;
      this.args.context.lineup = lineup;
    }
  }

  confirmPlanTask = dropTask(async (code, recurrence, traits) => {
    this.selectedPricePlanCode = code;

    let pricePlan = this.pricePlans.find(item => item.code === code);
    let subscription = this.store.createRecord('organization-subscription', {
      organization: this.organizationManager.organization,
      pricePlan,
    });

    subscription.recurrence = recurrence;
    set(this.args.context, 'subscription', subscription);
    set(this.args.context, 'isFreeTrial', this.isFreeTrialPlan(code, recurrence, traits));
    let { previous_plan_id, previous_recurrence } = this.activeTrial || {};
    let isActiveTrial =
      pricePlan.get('id') === previous_plan_id && previous_recurrence === recurrence;

    set(this.args.context, 'isActiveTrial', isActiveTrial);
    set(this.args.context, 'currentPricePlanCode', this.currentPricePlanCode);

    try {
      let { warnings, estimated_price } = await subscription.confirmFlight();
      this._handleNextStep({
        warnings,
        estimatedPrice: estimated_price,
        subscription,
      });
    } catch ({ payload, status }) {
      if (status === 422) {
        let { errors, warnings, estimated_price } = payload;
        let hasInsufficientFunds = errors.some(it => it.code === 'balance_insufficient_funds');
        let blockerErrors = errors.filter(it => it.code !== 'balance_insufficient_funds');

        this._handleNextStep({
          warnings,
          estimatedPrice: estimated_price,
          hasInsufficientFunds,
          errors: blockerErrors,
          subscription,
        });
      } else {
        let errorMessage = this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(errorMessage);
      }
    }
  });

  _handleNextStep({ warnings, estimatedPrice, hasInsufficientFunds, subscription, errors }) {
    setProperties(this.args.context, {
      warnings,
      estimatedPrice,
      hasInsufficientFunds,
      subscription,
      errors,
    });
    this.args.transitionToNext();
  }
}
