export default {
  "container": "cRx",
  "wrapper": "cRw",
  "error-wrapper": "cRN",
  "bottom-btns": "cRv",
  "bottom-wrapper": "cRo",
  "tab-nav": "cRn",
  "tab-nav-small": "cRi",
  "error-illustration": "cRA",
  "error-title": "cRY title-1",
  "disclaimer-wrapper": "cRh",
  "disclaimer": "cRf",
  "error-description": "cRK body-2",
  "table-wrapper": "cRG",
  "title": "cRr title-1",
  "lineup-toggle": "cRb",
  "description": "cRy body-2",
  "loading-wrapper": "cRU",
  "cards-wrapper": "cRj",
  "card": "cRW",
  "plan": "cRu",
  "placeholder-header": "cQS",
  "disclaimer-info": "cQc",
  "spinner": "cQq"
};
