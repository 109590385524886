/* import __COLOCATED_TEMPLATE__ from './reason.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class SubscriptionCloseReasonComponent extends Component {
  @tracked showError = false;
  @service intl;
  @service segment;

  get hasError() {
    let { hasReasonError, subReasonError } = this;
    return hasReasonError || subReasonError;
  }

  get hasReasonError() {
    let { closingReasonSubCategory } = this.args.context;
    return !closingReasonSubCategory;
  }

  get subReasonError() {
    let { closingReasonDetails, closingReasonSubCategory } = this.args.context;
    return closingReasonSubCategory?.items?.length && !closingReasonDetails
      ? this.intl.t('subscription.account-closing.reason.select-reason')
      : null;
  }

  @action selectReason(item) {
    let { context } = this.args;
    this.showError = false;
    context.closingReasonSubCategory = item;
    context.closingReasonDetails = null;
    context.closingReasonDetailsOther = null;
  }

  @action selectSubReason(item) {
    let { context } = this.args;
    this.showError = false;
    context.closingReasonDetails = item;
    context.closingReasonDetailsOther = null;
  }

  @action onSubmit(e) {
    e.preventDefault();
    if (this.hasError) {
      this.showError = true;
      return;
    }

    let { closingReasonDetails, closingReasonDetailsOther } = this.args.context;
    this.segment.track('account_closing_additional_details_completed', {
      reason_details: closingReasonDetails?.key,
      reason_details_other: closingReasonDetailsOther,
    });
    this.args.transitionToNext();
  }
}
