/* import __COLOCATED_TEMPLATE__ from './note.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { CARD_LEVELS } from 'qonto/constants/cards';

export default class FlowsRequestCardsNoteComponent extends Component {
  @service intl;
  @service toastFlashMessages;
  @service segment;

  @tracked note = '';
  @tracked errorMessage = null;

  constructor() {
    super(...arguments);
    this.sendTrackingEvent();
  }

  sendTrackingEvent() {
    this.segment.track('request_creation_note_displayed', {
      request_type: `${this.args.context.cardLevel} card`,
    });
  }

  continueTask = dropTask(async () => {
    if (!this.note || !this.note.trim()) {
      this.errorMessage = this.intl.t('validations.errors.presence');
      return;
    }

    this.args.context.entity.note = this.note;

    try {
      await this._saveRequestTask.perform(this.args.context.entity);

      this.args.transitionToNext();
    } catch {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);
    }
  });

  _saveRequestTask = dropTask(async entity => {
    let card = await entity.save();
    this.segment.track('request_created', {
      request_id: card.id,
      request_type: card.requestType.replace('_', ' '),
    });
  });

  get inputPlaceholder() {
    if (this.args.context.cardLevel === CARD_LEVELS.FLASH) {
      return this.intl.t('requests.cards.steps.note.placeholder.flash');
    }
    return this.intl.t('requests.cards.steps.note.placeholder.virtual');
  }

  get buttonLabel() {
    if (this.args.context.cardLevel === CARD_LEVELS.FLASH) {
      return this.intl.t('requests.cards.steps.note.button-label.flash');
    }
    return this.intl.t('requests.cards.steps.note.button-label.virtual');
  }
}
