/* import __COLOCATED_TEMPLATE__ from './schedule.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { task } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class F24ManualDeclarationScheduleComponent extends Component {
  @service sentry;
  @service intl;
  @service errors;
  @service segment;
  @service toastFlashMessages;

  @tracked selectedDate = dayjs().format(DATE_PICKER_FIELD_FORMAT);
  @tracked errorMessage = false;

  constructor(...args) {
    super(...args);

    this.getAvailableDates();
  }

  async getAvailableDates() {
    let { context } = this.args;
    let { f24Order } = context;

    try {
      let result = await f24Order.fetchAvailableDates();

      this.disabledDates = result.dates.filter(data => !data.is_enabled).map(data => data.date);
      this.minDate = result.start_date;
      this.maxDate = result.end_date;

      let firstEnabled = result.dates.find(data => data.is_enabled);
      this.setDate(firstEnabled?.date ?? this.minDate);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  @action
  setDate(newDate) {
    this.args.context.f24Order.expirationDate = newDate ? dayjs(newDate).toDate() : null;
    this.selectedDate = newDate;
  }

  validateOrderTask = task(async () => {
    let { context, transitionToNext } = this.args;
    let { f24Order } = context;

    try {
      await f24Order.validate();
      this.segment.track('f24_create-flow_step2-date-picked');
      transitionToNext();
    } catch (error) {
      let isServiceUnavailableError = error.status === 503;
      if (isServiceUnavailableError) {
        this.toastFlashMessages.toastError(
          this.intl.t('toasts.errors.server_error', { htmlSafe: true })
        );
      } else if (!this.errors.handleError(error)) {
        this.errorMessage = this.intl.t('f24.manual-declaration.date-selection.error');
      }
    }
  });
}
