export default {
  "header-cell": "ic caption-bold",
  "sortable": "iq",
  "animated": "iZ",
  "fadein-item": "iR",
  "animated-cell": "iQ",
  "col1": "ie",
  "header-content": "iB caption-bold",
  "active": "iE"
};
