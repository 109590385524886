/* import __COLOCATED_TEMPLATE__ from './additional-settings.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class FlowsDirectDebitCollectionsSubscriptionAdditionalSettingsComponent extends Component {
  @service attachmentsManager;
  @service modals;
  @service segment;

  @tracked isAttachmentActive = Boolean(this.subscription.attachments?.length);

  get subscription() {
    return this.args.context.directDebitSubscription;
  }

  get isActive() {
    return this.isAttachmentActive || this.subscription.savedAttachments.length;
  }

  @action
  toggleAttachment() {
    this.isAttachmentActive = !this.isAttachmentActive;

    if (!this.isAttachmentActive && this.subscription.attachments.content) {
      this.subscription.attachments.content = [];
    }
  }

  @action addAttachment(file) {
    this.attachmentsManager.addAttachment(false, this.subscription, file);
  }

  @action removeAttachment(file) {
    this.attachmentsManager.removeAttachment(false, this.subscription, file);
  }

  @action cancelUploadAttachment(file) {
    this.attachmentsManager.cancelUploadAttachment(this.subscription, file);
  }

  @action showAttachment(selectedFile) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile,
      model: this.subscription,
      download: this.attachmentsManager.downloadAttachment,
    });
  }

  @action
  onCTAClick() {
    this.args.transitionToNext();

    let { notifyClient, isOneOff, attachment } = this.subscription;

    this.segment.track('incoming-direct-debit-flow_additional-options_submitted', {
      notify_client: notifyClient,
      collection_type: isOneOff ? 'one_off' : 'recurring',
      attachment: Boolean(attachment),
    });
  }
}
